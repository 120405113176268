import React from 'react'
import { string } from 'prop-types'
import withStyles from 'isomorphic-style-loader/withStyles'
import { A } from '~elements'
import { LINKID_WORD_COMPARISON } from '~utils/analytics/linkIds'
import styles from './BackToWordLink.module.scss'

const BackToWordLink = ({ prefix, text, url }) => {
  const linkText = prefix ? (
    <>
      {prefix} <em>{text}</em>
    </>
  ) : (
    text
  )

  return (
    <A
      className={styles.root}
      data-type="back-to-word-link"
      href={url}
      linkId={LINKID_WORD_COMPARISON.backButton}
    >
      {linkText}
    </A>
  )
}

BackToWordLink.propTypes = {
  prefix: string,
  text: string.isRequired,
  url: string.isRequired
}

BackToWordLink.defaultProps = {
  prefix: null
}

export default withStyles(styles)(BackToWordLink)
