import React from 'react'
import { object } from 'prop-types'
import withStyles from 'isomorphic-style-loader/withStyles'
import { refreshAd } from '~composites/Ad/Ad'
import { A } from '~elements'
import { LINKID_QUIZ } from '~utils/analytics/linkIds'
import styles from './MoreQuizzesCard.module.scss'

const QUIZZES_BASE_URL = '/games/quizzes/'

const MoreQuizzesCard = ({ quiz }) => {
  const { emoji, name, slug } = quiz
  const handleRedirect = () => {
    refreshAd()
  }

  return (
    <A
      className={styles.root}
      data-linkid={LINKID_QUIZ.LANDING_PAGE_RECIRCULATED}
      href={`${QUIZZES_BASE_URL}${slug}`}
      onClick={handleRedirect}
    >
      <p className={styles['quiz-name']}>{name}</p>
      <p className={styles.emoji}>{emoji}</p>
    </A>
  )
}

MoreQuizzesCard.propTypes = {
  quiz: object.isRequired
}

export default withStyles(styles)(MoreQuizzesCard)
