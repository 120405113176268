import React, { useEffect, useState } from 'react'
import { array, func } from 'prop-types'
import withStyles from 'isomorphic-style-loader/withStyles'
import { useSelector } from 'react-redux'
import { NUMBER_OF_WORDS_TO_WIN } from '~client/constants'
import { WordPuzzleLetterButton, WordPuzzleNotification } from '~composites'
import { Button } from '~elements'
import { selectIsDesktop } from '~store/userContext/selectors'
import isArrayWithLength from '~utils/isArrayWithLength'
import randomizeArray from '~utils/randomizeArray'
import styles from './WordPuzzleGameBoard.module.scss'

const WordPuzzleGameBoard = ({
  allWords,
  completeGame,
  foundWords,
  letters,
  setFoundWords,
  setLetters
}) => {
  const isDesktop = useSelector(selectIsDesktop)
  const [currentWord, setCurrentWord] = useState('')
  const [selectedLetters, setSelectedLetters] = useState([])
  const [submissionType, setSubmissionType] = useState('')
  const selectedIndices = selectedLetters.map(({ index }) => index)

  const letterBoard = letters.map((letter, index) => (
    <WordPuzzleLetterButton
      isSelected={selectedIndices.includes(index)}
      key={`${letter}-${index + 1}`}
      onClick={() => selectLetter(letter, index)}
    >
      {letter}
    </WordPuzzleLetterButton>
  ))

  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp)

    if (isArrayWithLength(selectedLetters)) {
      setCurrentWord(
        selectedLetters.reduce((word, { letter }) => word + letter, '')
      )
    }

    if (submissionType) {
      setTimeout(() => {
        setSubmissionType('')
      }, 500)
    }

    return () => {
      window.removeEventListener('keyup', handleKeyUp)
    }
  }, [currentWord, letters, selectedLetters, submissionType])

  useEffect(() => {
    if (foundWords.length === NUMBER_OF_WORDS_TO_WIN) {
      completeGame()
    }
  }, [foundWords.length])

  const selectLetter = (letter, index) => {
    const selectedIndices = selectedLetters.map(
      ({ index: currentIndex }) => currentIndex
    )

    if (selectedIndices.includes(index)) {
      const selectedLettersToUpdate = [...selectedLetters]
      const indexToDelete = selectedLetters
        .map(({ index: selectedIndex }) => selectedIndex)
        .indexOf(index)
      selectedLettersToUpdate.splice(indexToDelete, 1)
      setSelectedLetters(selectedLettersToUpdate)
    } else {
      setSelectedLetters([...selectedLetters, { index, letter }])
    }

    setCurrentWord(
      selectedLetters.reduce((word, { letter }) => word + letter, '')
    )
  }

  const handleKeyUp = ({ key }) => {
    const keyInput = key.toUpperCase()

    if (letters.includes(keyInput)) {
      const allSelectedLetters = selectedLetters.map(({ letter }) => letter)
      const allSelectedIndices = selectedLetters.map(({ index }) => index)
      const keyHasDuplicate =
        letters.filter(letter => letter === keyInput).length > 1
      const numberOfTimesKeySelected = allSelectedLetters.filter(
        letter => letter === keyInput
      ).length

      let selectedLettersToUpdate = []

      if (allSelectedLetters.includes(keyInput) && keyHasDuplicate) {
        const firstIndex = letters.indexOf(keyInput)
        const secondIndex = letters.lastIndexOf(keyInput)
        const index = allSelectedIndices.includes(firstIndex)
          ? secondIndex
          : firstIndex
        if (numberOfTimesKeySelected === 2) {
          const indexToRemove = allSelectedLetters.lastIndexOf(keyInput)
          selectedLettersToUpdate = [...selectedLetters]
          selectedLettersToUpdate.splice(indexToRemove, 1)
          setSelectedLetters(selectedLettersToUpdate)
        } else {
          selectedLettersToUpdate = [
            ...selectedLetters,
            { index, letter: letters[index] }
          ]
          setSelectedLetters(selectedLettersToUpdate)
        }
      } else if (allSelectedLetters.includes(keyInput) && !keyHasDuplicate) {
        const index = allSelectedLetters.indexOf(keyInput)
        selectedLettersToUpdate = [...selectedLetters]
        selectedLettersToUpdate.splice(index, 1)
        setSelectedLetters(selectedLettersToUpdate)
      } else {
        const index = letters.indexOf(keyInput)
        selectedLettersToUpdate = [
          ...selectedLetters,
          { index, letter: letters[index] }
        ]
        setSelectedLetters(selectedLettersToUpdate)
      }

      setCurrentWord(
        selectedLetters.reduce((word, { letter }) => word + letter, '')
      )
    }

    if (key === 'Backspace' && selectedLetters.length) {
      setSelectedLetters(prevSelectedLetters =>
        prevSelectedLetters.slice(0, prevSelectedLetters.length - 1)
      )
    }

    if (
      key === 'Enter' &&
      selectedLetters.length &&
      document.activeElement === document.querySelector('body')
    ) {
      setCurrentWord(
        selectedLetters.reduce((word, { letter }) => word + letter, '')
      )
      submitWord()
    }
  }

  const shuffleLetters = () => {
    const shuffledLetters = randomizeArray(letters)

    const updatedSelectedLetters = selectedLetters.map(({ letter }) => ({
      index: shuffledLetters.indexOf(letter),
      letter
    }))

    setLetters(shuffledLetters)
    setSelectedLetters(updatedSelectedLetters)
  }

  const submitWord = () => {
    if (currentWord === '') return

    if (foundWords.includes(currentWord)) {
      setSubmissionType('found')
    } else if (
      !foundWords.includes(currentWord) &&
      allWords.includes(currentWord.toLowerCase())
    ) {
      setFoundWords(prevWordsGuessed => [...prevWordsGuessed, currentWord])
      setSubmissionType('valid')
    } else {
      setSubmissionType('invalid')
    }
    setCurrentWord('')
    setSelectedLetters([])
  }

  const deleteLetter = () => {
    if (!isArrayWithLength(selectedLetters)) return
    const updatedLetters = selectedLetters
    updatedLetters.pop()
    setSelectedLetters(updatedLetters)
    setCurrentWord(
      selectedLetters.reduce((word, { letter }) => word + letter, '')
    )
  }

  const wordInput =
    isArrayWithLength(selectedLetters) && !!currentWord ? (
      <p className={styles['word-input']}>{currentWord}</p>
    ) : (
      <p>{isDesktop ? 'CLICK' : 'TAP'} LETTER TO SELECT</p>
    )

  return (
    <div className={styles.root}>
      {!!submissionType && (
        <WordPuzzleNotification submissionType={submissionType} />
      )}
      <div className={styles['game-input']}>{wordInput}</div>
      <div className={styles['letter-board']}>{letterBoard}</div>
      <div className={styles['game-buttons']}>
        <Button className={styles.shuffle} onClick={shuffleLetters} />
        <Button className={styles.submit} onClick={submitWord}>
          Submit
        </Button>
        <Button className={styles.delete} onClick={deleteLetter} />
      </div>
    </div>
  )
}

WordPuzzleGameBoard.propTypes = {
  allWords: array.isRequired,
  completeGame: func.isRequired,
  foundWords: array.isRequired,
  letters: array.isRequired,
  setFoundWords: func.isRequired,
  setLetters: func.isRequired
}

export default withStyles(styles)(WordPuzzleGameBoard)
