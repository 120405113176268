import React, { useState } from 'react'
import { array, bool, number, string } from 'prop-types'
import { useDialogState } from 'ariakit/dialog'
import cx from 'classnames'
import withStyles from 'isomorphic-style-loader/withStyles'
import { SYNONYM_ANTONYM_WORD_STRENGTHS } from '~client/constants'
import { CompareSynonymsModal } from '~composites'
import { A } from '~elements'
import useThesaurusUrl from '~hooks/useThesaurusUrl'
import isArrayWithLength from '~utils/isArrayWithLength'
import pluralize from '~utils/pluralize'
import { toKebabCase } from '~utils/string'
import styles from './SynonymAndAntonymCard.module.scss'

const SynonymAndAntonymCard = ({
  antonyms,
  definition,
  displayForm,
  isSeoTest,
  ordinal,
  pos,
  slug,
  synonyms
}) => {
  const modalState = useDialogState({ animated: 150 })
  const { browseUrl } = useThesaurusUrl()
  const [isSynonymSelected, setIsSynonymSelected] = useState(true)

  const tabs = [
    { name: 'Synonyms', shouldShowTab: isArrayWithLength(synonyms) },
    { name: 'Antonyms', shouldShowTab: isArrayWithLength(antonyms) }
  ]

  const tabWords = isSynonymSelected ? synonyms : antonyms

  const wordStrengthMap = tabWords.reduce((wordStrength, word) => {
    const key = String(word.similarity).replace(/-/, '')
    if (!wordStrength[key]) {
      wordStrength[key] = []
    }
    wordStrength[key].push(word)
    return wordStrength
  }, {})

  const handleTabClick = () => {
    setIsSynonymSelected(!isSynonymSelected)
  }

  const handleKeyboardTabSelection = ({ key, target }) => {
    if (key === 'Enter') {
      target.blur()
      handleTabClick()
    }
  }

  const handleCompareSynonymsClick = () => modalState.toggle()

  const modal = definition && (
    <CompareSynonymsModal
      definition={definition}
      modalState={modalState}
      ordinal={ordinal}
      pos={pos}
      rootDisplayForm={displayForm}
      synonyms={synonyms}
    />
  )

  const tabContent = wordGroups => {
    return wordGroups.map(({ label, value }) => {
      const words = wordStrengthMap[value]

      if (!isArrayWithLength(words)) return null

      const title = `${label} ${
        isSynonymSelected
          ? pluralize(words.length, 'match', 'matches')
          : pluralize(words.length, 'opposite')
      }`

      const content = isSeoTest ? (
        <p className={styles['word-group-seo-test']}>
          {groupContentSeoTest(words)}
        </p>
      ) : (
        <ul className={styles['word-group']}>{groupContent(words)}</ul>
      )

      return (
        <div className={styles['word-group-container']} key={label}>
          <p className={styles['word-strength-title']}>{title}</p>
          {content}
        </div>
      )
    })
  }

  const groupContent = words =>
    words.map(({ similarity, targetSlug, targetWord }) => {
      const targetClassNames = cx(
        styles[`s${String(similarity).replace(/-/, '')}`],
        {
          [styles.antonym]: !isSynonymSelected
        }
      )

      return (
        <li key={targetWord}>
          {targetSlug ? (
            <A className={targetClassNames} href={`${browseUrl}/${targetSlug}`}>
              {targetWord}
            </A>
          ) : (
            <span className={targetClassNames}>{targetWord}</span>
          )}
        </li>
      )
    })

  const groupContentSeoTest = words =>
    words.map(({ similarity, targetSlug, targetWord }, index) => {
      const targetClassNames = cx(
        styles[`s${String(similarity).replace(/-/, '')}`],
        {
          [styles.antonym]: !isSynonymSelected
        }
      )

      const separator = words.length - 1 !== index ? `, ` : ``

      return (
        <span key={targetWord}>
          {targetSlug ? (
            <A className={targetClassNames} href={`${browseUrl}/${targetSlug}`}>
              {targetWord}
            </A>
          ) : (
            <span className={targetClassNames}>{targetWord}</span>
          )}
          {separator}
        </span>
      )
    })

  return (
    <div className={styles.root} data-type="synonym-and-antonym-card">
      <div className={styles['pos-container']}>
        <p id={toKebabCase(definition)}>
          {`${pos}  `}
          <span>
            as in <strong>{definition}</strong>
          </span>
        </p>
        <button
          data-analytics="aa-track"
          data-linklocation="header"
          data-linkmodule="synonym-module"
          data-linkname="compare-synonyms:cta"
          data-linktype="cta"
          data-linkurl={`browse/${slug}`}
          onClick={handleCompareSynonymsClick}
        >
          Compare Synonyms
        </button>
      </div>
      <div className={styles['tabs-container']}>
        <div className={styles['tab-selection']}>
          {tabs.map(({ name, shouldShowTab }) => {
            if (!shouldShowTab) return null

            const isSynonym = name === tabs[0].name
            const isActive = isSynonym ? isSynonymSelected : !isSynonymSelected
            const classNames = cx(styles.tab, {
              [styles.active]: isActive
            })

            return (
              <button
                className={classNames}
                data-analytics="aa-track"
                data-linklocation="body"
                data-linkmodule={`${isSynonym ? 'synonym' : 'antonym'}-module`}
                data-linkname={`${isSynonym ? 'synonyms' : 'antonyms'}:${slug}`}
                data-linktype={isSynonym ? 'synonyms' : 'antonyms'}
                data-linkurl={`browse/${slug}`}
                key={name}
                onClick={handleTabClick}
                onKeyDown={handleKeyboardTabSelection}
                tabIndex={isActive ? -1 : 0}
              >
                {name}
              </button>
            )
          })}
        </div>
        <div className={styles['tab-content']}>
          {tabContent(SYNONYM_ANTONYM_WORD_STRENGTHS)}
        </div>
      </div>
      {modal}
    </div>
  )
}

SynonymAndAntonymCard.propTypes = {
  antonyms: array.isRequired,
  definition: string.isRequired,
  displayForm: string.isRequired,
  isSeoTest: bool,
  ordinal: number.isRequired,
  pos: string.isRequired,
  slug: string.isRequired,
  synonyms: array.isRequired
}

SynonymAndAntonymCard.defaultProps = {
  isSeoTest: false
}

export default withStyles(styles)(SynonymAndAntonymCard)
