import { createAsyncThunk } from '@reduxjs/toolkit'
import fetch from 'isomorphic-unfetch'
import { BROWSE_RESULTS_PER_PAGE, WORD_COMPARISON_PER_PAGE } from './slice'

export const createLunaBrowseUrl = ({ letter, page = 1 }) => {
  const offset = (page - 1) * BROWSE_RESULTS_PER_PAGE
  return `${process.env.SAFE_LUNA_LIST_ENDPOINT}/${letter}?offset=${offset}&limit=${BROWSE_RESULTS_PER_PAGE}`
}

export const fetchLunaBrowse = createAsyncThunk(
  'fetchLunaBrowse',
  async ({ letter, page }) => {
    const endpointUrl = createLunaBrowseUrl({ letter, page })
    const response = await fetch(endpointUrl)
      .then(data => data.json())
      .catch(err => {
        throw err
      })

    return response
  }
)

/**
 * Builds the endpoint URL for compare words
 * @param {String{}} versus comes back as "word1-vs-word2".
 * @returns {String} compare words api endpoint url
 */
export const createCompareWordsUrl = versus => {
  const cleanVersus = versus.replace('-vs-', ' vs ')
  return `${process.env.SAFE_COMPARE_WORDS_ENDPOINT}/${encodeURIComponent(
    cleanVersus
  )}`
}

/**
 * Fetches compare words data from Luna
 * @param {String{}} versus comes back as "word1-vs-word2".
 * @returns Promise || null
 */
export const fetchCompareWords = createAsyncThunk(
  'fetchCompareWords',
  async ({ versus }) => {
    const endpointUrl = createCompareWordsUrl(versus)
    const response = await fetch(endpointUrl)
      .then(data => data.json())
      .catch(err => {
        throw err
      })

    return response
  }
)

export const createWordComparisonUrl = page => {
  const offset = (page - 1) * WORD_COMPARISON_PER_PAGE
  return `${process.env.SAFE_LUNA_WORD_COMPARISON_ENDPOINT}?offset=${offset}&limit=${WORD_COMPARISON_PER_PAGE}`
}

export const fetchLunaWordComparisonList = createAsyncThunk(
  'fetchLunaWordComparisonList',
  async page => {
    const endpointUrl = createWordComparisonUrl(page)
    const response = await fetch(endpointUrl)
      .then(data => data.json())
      .catch(err => {
        throw err
      })
    return response
  }
)

export const createResultsDataUrl = word => {
  const entryWord = word.word
  return `${process.env.SAFE_DCOM_PAGE_DATA_ENDPOINT}/${entryWord}`
}

/**
 * Fetches dictionary results data from the pageData api
 * @param {String} word is the results word
 * @returns Promise || null
 */
export const fetchResultsData = createAsyncThunk(
  'fetchResultsData',
  async word => {
    const endpointUrl = createResultsDataUrl(word)
    const response = await fetch(endpointUrl)
      .then(data => data.json())
      .catch(err => {
        throw err
      })

    return response
  }
)

export const createWordsRelatedUrl = word => {
  const entryWord = word.word
  return `${process.env.SAFE_DCOM_RESULTS_WORDS_RELATED_DATA_ENDPOINT}/${entryWord}`
}

/**
 * Fetches dictionary definition data from the Words Related api
 * @param {String} word is the definition word
 * @returns Promise || null
 */
export const fetchWordsRelated = createAsyncThunk(
  'fetchWordsRelated',
  async word => {
    const endpointUrl = createWordsRelatedUrl(word)
    const response = await fetch(endpointUrl)
      .then(data => data.json())
      .catch(err => {
        throw err
      })
    return response
  }
)

/**
 * Fetches Example Sentences for Result page
 * @params {String} word is the results word
 * @returns Promise || null
 */

export const createExampleSentenceUrl = ({ word }) => {
  const params = `?filterQuery=${word}&perPage=5`
  return `${process.env.SAFE_EXAMPLE_SENTENCES_ENDPOINT}${params}`
}

export const fetchExampleSentences = createAsyncThunk(
  'luna/fetchExampleSentences',
  async params => {
    const endpointUrl = createExampleSentenceUrl(params)
    const response = await fetch(endpointUrl)
      .then(data => data.json())
      .catch(err => {
        throw err
      })
    return response
  }
)

/**
 * Fetches Words Nearby for Results page
 * @params {String} word is the entry word in the results page
 * @returns Promise || null
 */

export const createWordsNearbyUrl = ({ word }) => {
  return `${process.env.SAFE_LUNA_WORDS_NEARBY_ENDPOINT}/${word}`
}

export const fetchWordsNearby = createAsyncThunk(
  'fetchWordsNearby',
  async params => {
    const endpointUrl = createWordsNearbyUrl(params)
    const response = await fetch(endpointUrl)
      .then(data => data.json())
      .catch(err => {
        throw err
      })
    return response
  }
)

/**
 * Fetches Words Nearby for Results page
 * @params {String} word is the entry word in the results page
 * @returns Promise || null
 */

export const createSpellSuggestionsUrl = ({ word }) => {
  return `${process.env.SAFE_SPELL_SUGGESTIONS_URL}/${word}`
}

export const fetchSpellSuggestions = createAsyncThunk(
  'fetchSpellSuggestions',
  async params => {
    const endpointUrl = createSpellSuggestionsUrl(params)
    const response = await fetch(endpointUrl)
      .then(data => data.json())
      .catch(err => {
        throw err
      })
    return response
  }
)
