// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.knMlKcRbTCndHK5FnJSt{margin:0;padding:0}.lNjLsY2vvKRH2NH3sQwg{color:var(--lightgray);line-height:24px;margin:0 0 12px;padding:0 16px}.wA28HAOaPoQHgkEdU5ki{background:none var(--dictionaryblue);border:none;border-radius:100px;color:#fff;font-size:16px;font-weight:bold;line-height:1;padding:16px 40px;position:relative;transition:all .25s ease-in-out 0s;width:100%}.wA28HAOaPoQHgkEdU5ki:hover{transform:scale(1.05)}`, "",{"version":3,"sources":["webpack://./src/client/components/composites/WordPuzzleModalContent/WordPuzzleModalContent.module.scss"],"names":[],"mappings":"AAAA,yBACE,QAAA,CACA,SAAA,CAGF,sBACE,sBAAA,CACA,gBAAA,CACA,eAAA,CACA,cAAA,CAGF,sBACE,qCAAA,CACA,WAAA,CACA,mBAAA,CACA,UAAA,CACA,cAAA,CACA,gBAAA,CACA,aAAA,CACA,iBAAA,CACA,iBAAA,CACA,kCAAA,CACA,UAAA,CAEA,4BACE,qBAAA","sourcesContent":["div.root {\n  margin: 0;\n  padding: 0;\n}\n\n.instructions {\n  color: var(--lightgray);\n  line-height: 24px;\n  margin: 0 0 12px;\n  padding: 0 16px;\n}\n\n.close-button {\n  background: none var(--dictionaryblue);\n  border: none;\n  border-radius: 100px;\n  color: #fff;\n  font-size: 16px;\n  font-weight: bold;\n  line-height: 1;\n  padding: 16px 40px;\n  position: relative;\n  transition: all 0.25s ease-in-out 0s;\n  width: 100%;\n\n  &:hover {\n    transform: scale(1.05);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `knMlKcRbTCndHK5FnJSt`,
	"instructions": `lNjLsY2vvKRH2NH3sQwg`,
	"close-button": `wA28HAOaPoQHgkEdU5ki`
};
module.exports = ___CSS_LOADER_EXPORT___;
