// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qTyidXPNxT7gm6_o1OkR{align-items:center;display:flex;height:100%;justify-content:space-between}.qTyidXPNxT7gm6_o1OkR:link,.qTyidXPNxT7gm6_o1OkR:visited,.qTyidXPNxT7gm6_o1OkR:hover,.qTyidXPNxT7gm6_o1OkR:active{color:inherit;text-decoration:none}.XW7sjggHY83QdErmzj_A{max-width:168px}.QuDgBgAjVr0RNOr818bQ{font-size:24px;padding-right:16px}@media(min-width: 1024px){.qTyidXPNxT7gm6_o1OkR{height:auto}}`, "",{"version":3,"sources":["webpack://./src/client/components/composites/MoreQuizzesCard/MoreQuizzesCard.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,YAAA,CACA,WAAA,CACA,6BAAA,CAEA,kHAIE,aAAA,CACA,oBAAA,CAIJ,sBACE,eAAA,CAGF,sBACE,cAAA,CACA,kBAAA,CAGF,0BACE,sBACE,WAAA,CAAA","sourcesContent":[".root {\n  align-items: center;\n  display: flex;\n  height: 100%;\n  justify-content: space-between;\n\n  &:link,\n  &:visited,\n  &:hover,\n  &:active {\n    color: inherit;\n    text-decoration: none;\n  }\n}\n\n.quiz-name {\n  max-width: 168px;\n}\n\n.emoji {\n  font-size: 24px;\n  padding-right: 16px;\n}\n\n@media (min-width: 1024px) {\n  .root {\n    height: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `qTyidXPNxT7gm6_o1OkR`,
	"quiz-name": `XW7sjggHY83QdErmzj_A`,
	"emoji": `QuDgBgAjVr0RNOr818bQ`
};
module.exports = ___CSS_LOADER_EXPORT___;
