import React, { useRef, useState } from 'react'
import { array, string } from 'prop-types'
import cx from 'classnames'
import withStyles from 'isomorphic-style-loader/withStyles'
import { PronunciationAudio, QuizResults } from '~composites'
import { Button, CheckIcon, CloseIcon } from '~elements'
import styles from './SpellingQuiz.module.scss'

const SpellingQuiz = ({ name, words }) => {
  const totalNumberOfQuestions = words.length
  const quiz = { name, words }
  const [currentQuestionNumber, setCurrentQuestionNumber] = useState(1)
  const [score, setScore] = useState(0)
  const [showHint, setShowHint] = useState(false)
  const [showResults, setShowResults] = useState(false)
  const [showResultsButton, setShowResultsButton] = useState(false)
  const [disableHintButton, setDisableHintButton] = useState(false)
  const [showCorrectAnswer, setShowCorrectAnswer] = useState(false)
  const [showNextQuestionButton, setShowNextQuestionButton] = useState(false)
  const [isCorrect, setIsCorrect] = useState(false)
  const [textInput, setTextInput] = useState('')
  const [selectedAnswers, setSelectedAnswers] = useState([])
  const inputRef = useRef(null)

  const handleSubmit = displayForm => {
    setDisableHintButton(true)
    if (
      textInput.toLowerCase() ===
      displayForm
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
    ) {
      setIsCorrect(true)
      setScore(score + 1)
    } else {
      setShowCorrectAnswer(true)
    }

    if (currentQuestionNumber === totalNumberOfQuestions) {
      setShowResultsButton(true)
    } else {
      setShowNextQuestionButton(true)
    }
    setShowHint(false)
    setSelectedAnswers([...selectedAnswers, textInput])
  }

  const handleNextQuestion = () => {
    setCurrentQuestionNumber(currentQuestionNumber + 1)
    setIsCorrect(false)
    setDisableHintButton(false)
    setTextInput('')
    setShowHint(false)
    setShowCorrectAnswer(false)
    setShowNextQuestionButton(false)
  }

  const handleQuizResults = () => {
    setShowResults(true)
  }

  const handleKeyActions = (event, displayForm) => {
    if (event.key === 'Enter' || event.code === 'Enter') {
      if (
        textInput?.length === displayForm.length &&
        !showNextQuestionButton &&
        !showResultsButton
      ) {
        handleSubmit(displayForm)
      } else if (showResultsButton) {
        handleQuizResults()
      } else if (showNextQuestionButton) {
        handleNextQuestion()
      }
      event.preventDefault()
    }
  }

  const hintClassNames = cx({
    [styles.hint]: showHint || disableHintButton
  })
  const inputClassNames = cx({
    [styles.incorrect]: showCorrectAnswer,
    [styles.correct]: isCorrect
  })

  const answers = words.map(word => word.displayForm)
  const { definition, displayForm, pos, pronunciationAudio } =
    words[currentQuestionNumber - 1]
  const wordLength = displayForm.length
  const showSubmitButton =
    textInput.length === wordLength &&
    !showNextQuestionButton &&
    !showResultsButton

  return (
    <div className={styles.root} data-type="spelling-quiz">
      <h3>{name} Spelling Quiz</h3>
      <hr />
      {!showResults && (
        <div key={displayForm}>
          <p className={styles['question-count']}>
            Spelling {currentQuestionNumber} of {totalNumberOfQuestions}
          </p>
          <div className={styles['audio-container']}>
            <p>Hear The Word Now:</p>
            <PronunciationAudio
              audioSrc={pronunciationAudio}
              className={styles.audio}
              onClick={() => inputRef.current.focus()}
            />
          </div>
          <div className={styles['hint-container']}>
            <Button
              className={hintClassNames}
              disabled={disableHintButton}
              onClick={() => {
                setShowHint(true)
                setDisableHintButton(true)
                inputRef.current.focus()
              }}
            >
              HINT
            </Button>
            {showHint && (
              <p>
                <i>{pos}</i>. {definition} <span>({wordLength} letters)</span>
              </p>
            )}
          </div>
          {showCorrectAnswer && (
            <div className={styles['incorrect-answer']}>
              <CloseIcon />
              <p>
                Oh no! Not quite right. The correct spelling is:{' '}
                <span>{displayForm}</span>
              </p>
            </div>
          )}
          {isCorrect && (
            <div className={styles['correct-answer']}>
              <CheckIcon />
              <p>Got that right!</p>
            </div>
          )}
          <form className={styles['text-input']}>
            <input
              autoFocus
              className={inputClassNames}
              maxLength={wordLength}
              onChange={event => {
                setTextInput(event.target.value)
              }}
              onKeyDown={event => {
                handleKeyActions(event, displayForm)
              }}
              ref={inputRef}
              size={wordLength}
              type="text"
              value={textInput}
            />
          </form>
          {showSubmitButton && (
            <Button
              className={styles.submit}
              onClick={() => {
                handleSubmit(displayForm)
              }}
            >
              SUBMIT
            </Button>
          )}
          {showNextQuestionButton && (
            <Button className={styles.submit} onClick={handleNextQuestion}>
              TRY ANOTHER WORD
            </Button>
          )}
          {showResultsButton && (
            <Button className={styles.submit} onClick={handleQuizResults}>
              SEE RESULTS
            </Button>
          )}
        </div>
      )}
      {showResults && (
        <QuizResults
          answers={answers}
          quiz={quiz}
          quizType="learn-spelling"
          score={score}
          selectedAnswers={selectedAnswers}
          totalNumberOfQuestions={totalNumberOfQuestions}
        />
      )}
    </div>
  )
}

SpellingQuiz.propTypes = {
  name: string.isRequired,
  words: array.isRequired
}

export default withStyles(styles)(SpellingQuiz)
