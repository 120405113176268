// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kqjG3vpOzo5nopgdpyZH{margin:0;padding:0}.JS3EsqxVzqtWtK7QIF96{border:4px;display:flex;flex-wrap:wrap;list-style-type:none;margin-top:14px;padding:0}.XHnti9ER92Goiu2I9ox0{margin-bottom:16px;margin-right:8px}button.s2IwkNoWegDbw6_W3L8X:active{background-color:var(--thesaurusorange);color:#fff}.WaK1PBZXtBb_sfKJHDbC{color:var(--lightgray);margin:14px 0 6px}@media(min-width: 768px){.XHnti9ER92Goiu2I9ox0{margin-bottom:16px;margin-right:8px}}`, "",{"version":3,"sources":["webpack://./src/client/components/composites/CompareMoreModalContent/CompareMoreModalContent.module.scss"],"names":[],"mappings":"AAAA,sBACE,QAAA,CACA,SAAA,CAGF,sBACE,UAAA,CACA,YAAA,CACA,cAAA,CACA,oBAAA,CACA,eAAA,CACA,SAAA,CAGF,sBACE,kBAAA,CACA,gBAAA,CAGF,mCACE,uCAAA,CACA,UAAA,CAGF,sBACE,sBAAA,CACA,iBAAA,CAGF,yBACE,sBACE,kBAAA,CACA,gBAAA,CAAA","sourcesContent":[".root {\n  margin: 0;\n  padding: 0;\n}\n\n.pill-list {\n  border: 4px;\n  display: flex;\n  flex-wrap: wrap;\n  list-style-type: none;\n  margin-top: 14px;\n  padding: 0;\n}\n\n.pill-item {\n  margin-bottom: 16px;\n  margin-right: 8px;\n}\n\nbutton.selected:active {\n  background-color: var(--thesaurusorange);\n  color: #fff;\n}\n\n.subheading {\n  color: var(--lightgray);\n  margin: 14px 0 6px;\n}\n\n@media (min-width: 768px) {\n  .pill-item {\n    margin-bottom: 16px;\n    margin-right: 8px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `kqjG3vpOzo5nopgdpyZH`,
	"pill-list": `JS3EsqxVzqtWtK7QIF96`,
	"pill-item": `XHnti9ER92Goiu2I9ox0`,
	"selected": `s2IwkNoWegDbw6_W3L8X`,
	"subheading": `WaK1PBZXtBb_sfKJHDbC`
};
module.exports = ___CSS_LOADER_EXPORT___;
