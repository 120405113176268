import React from 'react'
import { func, oneOf, string } from 'prop-types'
import withStyles from 'isomorphic-style-loader/withStyles'
import { A } from '~elements/'
import useDictionaryUrl from '~hooks/useDictionaryUrl'
import styles from './WordListKebabMenuOptions.module.scss'

const PAGES = {
  learningHub: 'learningHub',
  wordLists: 'wordLists'
}

const WordListKebabMenuOptions = ({
  category,
  openDeleteWordListModal,
  openEditWordListModal,
  page,
  setIsKebabMenuOpen,
  shortId
}) => {
  const { learnWordListsUrl } = useDictionaryUrl()

  const isWordListsPage = page === PAGES.wordLists
  const wordListUrl = `${learnWordListsUrl}/${category}/${shortId}`

  const copyLinkToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(wordListUrl)
    } catch (error) {
      throw new Error(error)
    }
    // Delay closing menu so user can see active state
    setTimeout(() => {
      setIsKebabMenuOpen(false)
    }, 250)
  }

  return (
    <div className={styles.root} data-type="word-list-kebab-menu-options">
      <A href={`${wordListUrl}/flashcards`}>Launch Flashcards</A>
      <A href={`${wordListUrl}/quiz`}>Take a Multiple Choice Quiz</A>
      <A href={`${wordListUrl}/spelling`}>Take a Spelling Quiz</A>
      <div />
      {isWordListsPage && (
        <button onClick={openEditWordListModal}>Edit List Details</button>
      )}
      <button onClick={copyLinkToClipboard}>Copy Link</button>
      {isWordListsPage && (
        <button className={styles.delete} onClick={openDeleteWordListModal}>
          Delete List
        </button>
      )}
    </div>
  )
}

WordListKebabMenuOptions.propTypes = {
  category: string.isRequired,
  openDeleteWordListModal: func,
  openEditWordListModal: func,
  page: oneOf([PAGES.learningHub, PAGES.wordLists]),
  setIsKebabMenuOpen: func,
  shortId: string.isRequired
}

WordListKebabMenuOptions.defaultProps = {
  openDeleteWordListModal: () => {},
  openEditWordListModal: () => {},
  page: PAGES.wordLists,
  setIsKebabMenuOpen: () => {}
}

export default withStyles(styles)(WordListKebabMenuOptions)
