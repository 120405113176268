import React from 'react'
import { func } from 'prop-types'
import withStyles from 'isomorphic-style-loader/withStyles'
import { useSelector } from 'react-redux'
import { Button, ModalContent } from '~elements'
import { selectIsMobile } from '~store/userContext/selectors'
import styles from './WordPuzzleModalContent.module.scss'

const WordPuzzleModalContent = ({ closeModal }) => {
  const isMobile = useSelector(selectIsMobile)

  return (
    <ModalContent className={styles.root} data-type="word-puzzle-modal-content">
      <ul className={styles.instructions}>
        <li>Create words by selecting the letters provided.</li>
        {!isMobile && (
          <li>
            You can use your mouse or keyboard to select letters and submit
            words.
          </li>
        )}
        <li>Letters can only be used once per word.</li>
      </ul>
      <Button className={styles['close-button']} onClick={closeModal}>
        Close
      </Button>
    </ModalContent>
  )
}

WordPuzzleModalContent.propTypes = {
  closeModal: func.isRequired
}

export default withStyles(styles)(WordPuzzleModalContent)
