import React, { useEffect } from 'react'
import { bool, object, oneOf, string } from 'prop-types'
import cx from 'classnames'
import { format as formatDate, parseISO } from 'date-fns'
import withStyles from 'isomorphic-style-loader/withStyles'
import { useSelector, useStore } from 'react-redux'
import {
  DATE_ISO_8601,
  DATE_MMMDDYYYY,
  DATE_MMMMDDYYYY
} from '~client/constants'
import { PronunciationAudio, PronunciationText } from '~composites'
import { A } from '~elements'
import { selectSiteName } from '~store/location/selectors'
import { THESAURUS_SITENAME } from '~store/location/slice'
import { fetchWordOfTheDay } from '~store/wordpress/actions'
import { selectWordOfTheDay } from '~store/wordpress/selectors'
import {
  LINKID_WOTD,
  LINKID_WOTD_MEANINGS_EXAMPLES
} from '~utils/analytics/linkIds'
import isObjectWithProperties from '~utils/isObjectWithProperties'
import stripHtmlTags from '~utils/stripHtmlTags'
import styles from './WotdCard.module.scss'

const allowedDateFormats = [DATE_MMMDDYYYY, DATE_MMMMDDYYYY]
export const loadData = ({ store }) => store.dispatch(fetchWordOfTheDay())

const MEDIUM_WORD_MIN_LENGTH = 9
const LONG_WORD_MIN_LENGTH = 16
const MEDIUM_PRONUNCIATION_MIN_LENGTH = 16
const LONG_PRONUNCIATION_MIN_LENGTH = 27

export const WotdCard = ({
  className,
  ctaText,
  dateFormat,
  pronunciationClassNames,
  showDate,
  showPOS,
  useAccentColor,
  useHeader,
  wordClassNames
}) => {
  const store = useStore()
  const { results } = useSelector(selectWordOfTheDay)
  const siteName = useSelector(selectSiteName)
  const WOTD_URL = 'https://www.dictionary.com/e/word-of-the-day/'
  const isThesaurusSite = siteName === THESAURUS_SITENAME

  useEffect(() => {
    if (!results) {
      loadData({ store })
    }
  }, [])

  if (!isObjectWithProperties(results)) return null

  const safeDateFormat = allowedDateFormats.includes(dateFormat)
    ? dateFormat
    : DATE_MMMDDYYYY // choose a default

  const key = Object.keys(results)[0]
  const today = formatDate(parseISO(key), safeDateFormat)
  const todayYMD = formatDate(parseISO(key), DATE_ISO_8601)
  const {
    accentColor,
    audio: audioSrc,
    pos,
    pronunciation,
    word
  } = results[key]

  const wordLength = word.length
  const pronunciationLength = stripHtmlTags(pronunciation).length

  const classNames = cx(styles.root, className, {
    [styles['card-size']]: wordLength >= MEDIUM_WORD_MIN_LENGTH
  })

  const wordSizeClassNames = cx({
    [wordClassNames.short]: wordLength < MEDIUM_WORD_MIN_LENGTH,
    [wordClassNames.medium]:
      wordLength >= MEDIUM_WORD_MIN_LENGTH && wordLength < LONG_WORD_MIN_LENGTH,
    [wordClassNames.long]: wordLength >= LONG_WORD_MIN_LENGTH
  })

  const pronunciationSizeClassNames = cx({
    [styles.pronunciation]: true,
    [pronunciationClassNames.pronunciation]:
      pronunciationClassNames.pronunciation,
    [pronunciationClassNames.short]:
      pronunciationLength < MEDIUM_PRONUNCIATION_MIN_LENGTH,
    [pronunciationClassNames.medium]:
      pronunciationLength >= MEDIUM_PRONUNCIATION_MIN_LENGTH &&
      pronunciationLength < LONG_PRONUNCIATION_MIN_LENGTH,
    [pronunciationClassNames.long]:
      pronunciationLength >= LONG_PRONUNCIATION_MIN_LENGTH
  })

  const colorCode = useAccentColor ? accentColor : 'unset'

  const header = isThesaurusSite ? (
    <div className={styles['thesaurus-heading']}>
      <h2>Word of the Day</h2>
      <p>
        Build your vocabulary with new words, definitions, and origin stories
        every day of the week.
      </p>
    </div>
  ) : (
    <h2>Word of the Day</h2>
  )

  return (
    <div className={classNames} data-type="wotd-card">
      <div>
        {useHeader && header}
        <div className={styles.card} style={{ borderColor: colorCode }}>
          {!useHeader && <p>Word of the Day</p>}
          <span className={wordSizeClassNames}>
            <A
              data-analytics="aa-track"
              data-linklocation="body"
              data-linkmodule="word-of-the-day"
              data-linkname={word}
              data-linktype="cta"
              data-linkurl="https://www.dictionary.com/e/word-of-the-day/"
              data-type="wotd-card"
              href={WOTD_URL}
              linkId={LINKID_WOTD}
              style={{ color: colorCode }}
            >
              {word}
            </A>
          </span>
          <div className={pronunciationSizeClassNames}>
            {showPOS && <em>{stripHtmlTags(pos)}&nbsp;|&nbsp;</em>}
            <PronunciationText>{pronunciation}</PronunciationText>
            {audioSrc && (
              <PronunciationAudio
                audioSrc={audioSrc}
                data-analytics="aa-track"
                data-linklocation="body"
                data-linkmodule="word-of-the-day"
                data-linkname="pronunciation"
                data-linktype="audio"
                data-linkurl="https://www.dictionary.com/8a967e41d8107bbd4651.png"
              />
            )}
          </div>
          <div className={styles.footer}>
            <A
              data-analytics="aa-track"
              data-linklocation="body"
              data-linkmodule="word-of-the-day"
              data-linkname="meaning-examples"
              data-linktype="cta"
              data-linkurl="https://www.dictionary.com/e/word-of-the-day/"
              data-type="wotd-card"
              href={WOTD_URL}
              linkId={LINKID_WOTD_MEANINGS_EXAMPLES}
            >
              {ctaText}
            </A>
            {showDate && (
              <time className={styles.date} dateTime={todayYMD}>
                {today}
              </time>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

WotdCard.propTypes = {
  className: string,
  ctaText: string,
  dateFormat: oneOf(allowedDateFormats),
  pronunciationClassNames: object.isRequired,
  showDate: bool,
  showPOS: bool,
  useAccentColor: bool,
  useHeader: bool,
  wordClassNames: object.isRequired
}

WotdCard.defaultProps = {
  className: null,
  ctaText: 'Meaning & Examples',
  dateFormat: DATE_MMMDDYYYY,
  showDate: true,
  showPOS: false,
  useAccentColor: true,
  useHeader: true
}

export default withStyles(styles)(WotdCard)
