// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qjilxGDDmCsbje4iYFA6{margin:0;padding:0}.qjilxGDDmCsbje4iYFA6 ul{border:4px;display:flex;flex-wrap:wrap;list-style-type:none;max-width:275px;padding:0}.qjilxGDDmCsbje4iYFA6 li{margin-bottom:8px;margin-right:8px}.x09ai1M9C5kCDmF1APz9,.lO76l_MGo2R8c_GVnNKf{color:var(--darkgraytext);margin-top:0}.x09ai1M9C5kCDmF1APz9{font-size:16px;font-weight:bold;line-height:1.15}.lO76l_MGo2R8c_GVnNKf{font-size:18px;font-weight:normal;line-height:1.5}`, "",{"version":3,"sources":["webpack://./src/client/components/composites/SharedSynonymList/SharedSynonymList.module.scss"],"names":[],"mappings":"AAAA,sBACE,QAAA,CACA,SAAA,CAEA,yBACE,UAAA,CACA,YAAA,CACA,cAAA,CACA,oBAAA,CACA,eAAA,CACA,SAAA,CAGF,yBACE,iBAAA,CACA,gBAAA,CAIJ,4CAEE,yBAAA,CACA,YAAA,CAGF,sBACE,cAAA,CACA,gBAAA,CACA,gBAAA,CAGF,sBACE,cAAA,CACA,kBAAA,CACA,eAAA","sourcesContent":[".root {\n  margin: 0;\n  padding: 0;\n\n  ul {\n    border: 4px;\n    display: flex;\n    flex-wrap: wrap;\n    list-style-type: none;\n    max-width: 275px;\n    padding: 0;\n  }\n\n  li {\n    margin-bottom: 8px;\n    margin-right: 8px;\n  }\n}\n\n.shared-synonyms,\n.no-shared-synonyms {\n  color: var(--darkgraytext);\n  margin-top: 0;\n}\n\n.shared-synonyms {\n  font-size: 16px;\n  font-weight: bold;\n  line-height: 1.15;\n}\n\n.no-shared-synonyms {\n  font-size: 18px;\n  font-weight: normal;\n  line-height: 1.5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `qjilxGDDmCsbje4iYFA6`,
	"shared-synonyms": `x09ai1M9C5kCDmF1APz9`,
	"no-shared-synonyms": `lO76l_MGo2R8c_GVnNKf`
};
module.exports = ___CSS_LOADER_EXPORT___;
