// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AZBodMcvmK7ZUhRQH3GE{background-color:#35cef2;border:rgba(0,0,0,0);color:var(--darkgraytext);margin-bottom:8px}.AZBodMcvmK7ZUhRQH3GE:hover,.AZBodMcvmK7ZUhRQH3GE:visited{color:var(--darkgraytext)}.jneW5p1DPTMEtJAcm7FZ{background-color:var(--faintgray)}._orLkYid3koycScGsYIN,.mbLAdhe7yY1zh28KN7oq{background-color:var(--mildgray)}`, "",{"version":3,"sources":["webpack://./src/client/components/composites/AntonymPill/AntonymPill.module.scss"],"names":[],"mappings":"AAAA,sBACE,wBAAA,CACA,oBAAA,CACA,yBAAA,CACA,iBAAA,CAEA,0DAEE,yBAAA,CAIJ,sBACE,iCAAA,CAGF,4CAEE,gCAAA","sourcesContent":[".root {\n  background-color: #35cef2;\n  border: transparent;\n  color: var(--darkgraytext);\n  margin-bottom: 8px;\n\n  &:hover,\n  &:visited {\n    color: var(--darkgraytext);\n  }\n}\n\n.similarity-10 {\n  background-color: var(--faintgray);\n}\n\n.similarity-50,\n.similarity-100 {\n  background-color: var(--mildgray);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `AZBodMcvmK7ZUhRQH3GE`,
	"similarity-10": `jneW5p1DPTMEtJAcm7FZ`,
	"similarity-50": `_orLkYid3koycScGsYIN`,
	"similarity-100": `mbLAdhe7yY1zh28KN7oq`
};
module.exports = ___CSS_LOADER_EXPORT___;
