import React from 'react'
import { bool, string } from 'prop-types'
import cx from 'classnames'
import withStyles from 'isomorphic-style-loader/withStyles'
import { PROPTYPE_CHILDREN } from '~client/constants'
import { A } from '~elements'
import styles from './HeadlineLink.module.scss'

const HeadlineLink = ({ children, className, isMuted, ...props }) => {
  const classNames = cx(styles.root, className, {
    [styles.color]: isMuted
  })

  return (
    <A className={classNames} {...props}>
      {children}
    </A>
  )
}

HeadlineLink.propTypes = {
  children: PROPTYPE_CHILDREN.isRequired,
  className: string,
  isMuted: bool
}

HeadlineLink.defaultProps = {
  className: null,
  isMuted: false
}

export default withStyles(styles)(HeadlineLink)
