import React from 'react'
import { bool, object, string } from 'prop-types'
import cx from 'classnames'
import withStyles from 'isomorphic-style-loader/withStyles'
import { useSelector } from 'react-redux'
import { selectAllowSovrn } from '~store/userContext/selectors'
import styles from './Ad.module.scss'
import GptAd from './GptAd'
import SovrnAd from './SovrnAd'

export const refreshAd = () => {
  if (!window.googletag) return

  window.googletag.cmd.push(() => {
    window.googletag.pubads().refresh()
  })
}

const Ad = ({ adId, className, showAdHeader, useLazyLoad, ...props }) => {
  const sovrnAllowed = useSelector(selectAllowSovrn)
  const AdComponent = sovrnAllowed ? SovrnAd : GptAd
  const classNames = cx(styles.root, className)
  const { height, slotName, width } = adId

  return (
    <div className={classNames}>
      {showAdHeader && <p className={styles.header}>Advertisement</p>}
      <AdComponent
        height={height}
        slotName={slotName}
        useLazyLoad={useLazyLoad}
        width={width}
        {...props}
      />
    </div>
  )
}

Ad.propTypes = {
  adId: object.isRequired,
  className: string,
  showAdHeader: bool,
  useLazyLoad: bool
}

Ad.defaultProps = {
  className: null,
  showAdHeader: false,
  useLazyLoad: false
}

export default withStyles(styles)(Ad)
