import React from 'react'
import withStyles from 'isomorphic-style-loader/withStyles'
import { useSelector } from 'react-redux'
import { ResultsPageNavigationItems } from '~client/constants'
import { SubjectLabel } from '~elements'
import { selectOtherWordsFrom } from '~store/lexigraph/selectors'
import isArrayWithLength from '~utils/isArrayWithLength'
import { parseChunks } from '~utils/semanticChunkParsers'
import styles from './OtherWordsFrom.module.scss'

const OtherWordsFrom = () => {
  const otherWordsFrom = useSelector(selectOtherWordsFrom)

  if (!isArrayWithLength(otherWordsFrom)) return null

  return (
    <section className={styles.root} data-type="other-words-from">
      <SubjectLabel
        className={styles.label}
        id={ResultsPageNavigationItems.OTHER_WORDS_FROM.id}
        text="Discover More"
      />
      <h2 className={styles['section-heading']}>
        {ResultsPageNavigationItems.OTHER_WORDS_FROM.label}
      </h2>
      <ul>
        {otherWordsFrom.map(({ chunks }, index) => (
          <li key={`${index + 1}`}>{parseChunks(chunks)}</li>
        ))}
      </ul>
    </section>
  )
}

export default withStyles(styles)(OtherWordsFrom)
