import React from 'react'
import withStyles from 'isomorphic-style-loader/withStyles'
import { A } from '~elements'
import useDictionaryUrl from '~hooks/useDictionaryUrl'
import useGamesUrl from '~hooks/useGamesUrl'
import styles from './EasyCrosswordContent.module.scss'

const EasyCrosswordContent = () => {
  const { crosswordUrl } = useDictionaryUrl()
  const { allGamesUrl, miniCrosswordUrl, sundayCrosswordUrl } = useGamesUrl()

  return (
    <section data-type="easy-crossword-content">
      <p>
        The Easy Crossword features a regular-size crossword with a difficulty
        level that’s perfect for beginners. If you&apos;re new to crosswords or
        just want to complete a quick-and-easy puzzle with fun clues, the Easy
        Crossword is for you! See how long it takes you to solve!
      </p>
      <strong>We&apos;ve got more crossword puzzles to play!</strong>
      <ul>
        <li>
          <A href={crosswordUrl}>Daily Crossword</A>
        </li>
        <li>
          <A href={sundayCrosswordUrl}>Sunday Crossword</A>
        </li>
        <li>
          <A href={miniCrosswordUrl}>Mini Crossword</A>
        </li>
      </ul>
      <p className={styles.cta}>
        Play our entire games library which has many more{' '}
        <A href={allGamesUrl}>free online word games</A>!
      </p>
    </section>
  )
}

export default withStyles(styles)(EasyCrosswordContent)
