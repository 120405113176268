import { useEffect, useState } from 'react'

/**
 * Positions the menu based on the current location of the button
 * and opens it up in a way to ensure it doesn't bleed off the page.
 * @param {*} buttonRef
 * @param {*} menuRef Must have "position: fixed;" set
 */
const useMenuPositioning = (buttonRef, menuRef) => {
  const [menuPositioning, setMenuPositioning] = useState({})

  const positionMenu = () => {
    setMenuPositioning({})

    if (buttonRef.current && menuRef.current) {
      const buttonRect = buttonRef.current.getBoundingClientRect()
      const menuRect = menuRef.current.getBoundingClientRect()

      // Initialize menu to be just below the button at its midpoint
      const leftInitial = buttonRect.left + buttonRect.width / 2
      const topInitial = buttonRect.top + buttonRect.height

      const shouldOpenOnRight = leftInitial - menuRect.width <= 0
      const shouldOpenOnTop = topInitial + menuRect.height > window.innerHeight

      // How much the menu bleeds off the right-hand side of the page
      const rightSideOverflow = Math.max(
        0,
        leftInitial + menuRect.width - window.innerWidth
      )

      const left = shouldOpenOnRight
        ? leftInitial
        : leftInitial - menuRect.width
      const top = shouldOpenOnTop
        ? topInitial - buttonRect.height - menuRect.height
        : topInitial

      /**
       * If the menu would bleed off the page, bump it
       * over until it's hugging the right-hand side.
       */
      const leftWithoutOverflow = Math.max(0, left - rightSideOverflow)

      setMenuPositioning({
        left: `${Math.round(leftWithoutOverflow)}px`,
        top: `${Math.round(top)}px`
      })
    }
  }

  useEffect(() => {
    window.addEventListener('resize', positionMenu)
    window.addEventListener('scroll', positionMenu)
    window.addEventListener('touchmove', positionMenu)

    return () => {
      window.removeEventListener('resize', positionMenu)
      window.removeEventListener('scroll', positionMenu)
      window.removeEventListener('touchmove', positionMenu)
    }
  }, [])

  return {
    menuPositioning,
    positionMenu,
    setMenuPositioning
  }
}

export default useMenuPositioning
