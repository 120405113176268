import React from 'react'
import { array, shape, string } from 'prop-types'
import withStyles from 'isomorphic-style-loader/withStyles'
import {
  SharedAntonymList,
  SharedSynonymList,
  SynonymDefinitionList
} from '~composites'
import { A } from '~elements'
import useDictionaryUrl from '~hooks/useDictionaryUrl'
import useThesaurusUrl from '~hooks/useThesaurusUrl'
import styles from './SynonymComparisonCard.module.scss'

const SynonymComparisonCard = ({
  definitions,
  matchHeadword,
  rootDisplayForm,
  sharedSynonyms,
  sharedAntonyms
}) => {
  const { browseUrl: dictionaryBrowseUrl } = useDictionaryUrl()
  const { browseUrl: thesaurusBrowseUrl } = useThesaurusUrl()
  const { displayForm: matchDisplayForm, slug: matchSlug } = matchHeadword

  const entryContent = matchSlug ? (
    <A href={`${thesaurusBrowseUrl}/${matchSlug}`}>{matchDisplayForm}</A>
  ) : (
    matchDisplayForm
  )

  const definitionContent = matchSlug ? (
    <A href={`${dictionaryBrowseUrl}/${matchSlug}`}>Definition(s)</A>
  ) : (
    'Definition(s)'
  )

  return (
    <div className={styles.root} data-type="synonym-comparison-card">
      <h2 className={styles.headword}>{entryContent}</h2>
      <h3 className={styles.definition}>{definitionContent}</h3>

      <SynonymDefinitionList definitions={definitions} />

      <SharedSynonymList
        matchDisplayForm={matchDisplayForm}
        rootDisplayForm={rootDisplayForm}
        sharedSynonyms={sharedSynonyms}
      />

      <SharedAntonymList
        matchDisplayForm={matchDisplayForm}
        rootDisplayForm={rootDisplayForm}
        sharedAntonyms={sharedAntonyms}
      />
    </div>
  )
}

SynonymComparisonCard.propTypes = {
  definitions: array.isRequired,
  matchHeadword: shape({
    displayForm: string.isRequired,
    slug: string.isRequired
  }).isRequired,
  rootDisplayForm: string.isRequired,
  sharedAntonyms: array.isRequired,
  sharedSynonyms: array.isRequired
}

export default withStyles(styles)(SynonymComparisonCard)
