import React from 'react'
import { object } from 'prop-types'
import withStyles from 'isomorphic-style-loader/withStyles'
import { useNavigate } from 'react-router-dom'
import { refreshAd } from '~composites/Ad/Ad'
import { Button } from '~elements'
import { LINKID_QUIZ } from '~utils/analytics/linkIds'
import styles from './FeaturedQuiz.module.scss'

const FeaturedQuiz = ({ featuredQuiz }) => {
  const { category, description, name, slug } = featuredQuiz
  const navigate = useNavigate()
  const quizURL = `/games/quizzes/${slug}`

  const handleClick = () => {
    navigate(quizURL, { replace: true })
    refreshAd()
  }

  return (
    <div className={styles.root}>
      <header>
        <h1>{category}</h1>
        <h2>{name}</h2>
      </header>
      <div className={styles.description}>{description}</div>
      <Button linkId={LINKID_QUIZ.GO_TO_QUIZ} onClick={handleClick}>
        GO TO QUIZ
      </Button>
    </div>
  )
}

FeaturedQuiz.propTypes = {
  featuredQuiz: object.isRequired
}

export default withStyles(styles)(FeaturedQuiz)
