import React from 'react'
import { array, func, string } from 'prop-types'
import withStyles from 'isomorphic-style-loader/withStyles'
import { CompareMorePill } from '~composites'
import { ModalContent } from '~elements'
import styles from './CompareMoreModalContent.module.scss'

const CompareMoreModalContent = ({
  definition,
  pos,
  rootDisplayForm,
  selectWord,
  selectedSynonymsList,
  synonyms
}) => {
  const partOfSpeech = pos === 'adjective' ? 'adj.' : pos

  const synonymList = synonyms
    ? synonyms.map(({ targetWord }) => {
        return (
          <li className={styles['pill-item']} key={targetWord}>
            <CompareMorePill
              isSelected={selectedSynonymsList.includes(targetWord)}
              onClick={() => selectWord(targetWord)}
            >
              {targetWord}
            </CompareMorePill>
          </li>
        )
      })
    : null

  return (
    <ModalContent
      className={styles.root}
      data-type="compare-more-modal-content"
    >
      <p className={styles.subheading}>
        Synonyms of{' '}
        <strong>
          <em>{rootDisplayForm}</em> ({partOfSpeech} {definition})
        </strong>
      </p>
      <ul className={styles['pill-list']}>{synonymList}</ul>
    </ModalContent>
  )
}

CompareMoreModalContent.propTypes = {
  definition: string.isRequired,
  pos: string.isRequired,
  rootDisplayForm: string.isRequired,
  selectWord: func.isRequired,
  selectedSynonymsList: array.isRequired,
  synonyms: array.isRequired
}

export default withStyles(styles)(CompareMoreModalContent)
