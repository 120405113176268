import React from 'react'
import { bool, string } from 'prop-types'
import withStyles from 'isomorphic-style-loader/withStyles'
import { A } from '~elements'
import styles from './WordPuzzleAnswerCard.module.scss'

const WordPuzzleAnswerCard = ({ isCorrect, word }) => (
  <div className={styles.root}>
    <div className={styles.answer}>
      <A className={styles.link} href={`/browse/${word}`}>
        {word}
      </A>
      {isCorrect && (
        <div className={styles.icon}>
          <span />
        </div>
      )}
    </div>
  </div>
)

WordPuzzleAnswerCard.propTypes = {
  isCorrect: bool,
  word: string.isRequired
}

WordPuzzleAnswerCard.defaultProps = {
  isCorrect: false
}

export default withStyles(styles)(WordPuzzleAnswerCard)
