import { string } from 'prop-types'
import withStyles from 'isomorphic-style-loader/withStyles'
import styles from './ErrorCard.module.scss'

export const ErrorCard = ({ message }) => {
  return <div className={styles.root}>{message}</div>
}

ErrorCard.propTypes = {
  message: string.isRequired
}

export default withStyles(styles)(ErrorCard)
