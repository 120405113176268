import React from 'react'
import withStyles from 'isomorphic-style-loader/withStyles'
import { A } from '~elements'
import useGamesUrl from '~hooks/useGamesUrl'
import styles from './FamilyFeudContent.module.scss'

const FamilyFeudContent = () => {
  const { allGamesUrl } = useGamesUrl()

  return (
    <section data-type="family-feud-content">
      <p>
        Based on the popular game show, Family Feud challenges you to guess the
        most popular answers to survey questions. Try to guess the top answer to
        each question, and you will be awarded points based on how many people
        gave the answer you chose. In the Fast Money bonus round, you have a
        limited time to provide answers to five questions. How well can you
        identify the most popular opinions?
      </p>
      <p className={styles.cta}>
        Having fun playing Family Feud?{' '}
        <A href={allGamesUrl}>See our entire games collection</A>.
      </p>
    </section>
  )
}

export default withStyles(styles)(FamilyFeudContent)
