// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.V4nREAPR8xEKgyi274ie{display:grid;gap:16px;grid-template-columns:1fr;margin:0 0 32px;padding-inline-start:15px}.V4nREAPR8xEKgyi274ie ::marker{color:var(--lightgraytext);font-size:14px}.V4nREAPR8xEKgyi274ie li{display:list-item;padding-left:10px}.V4nREAPR8xEKgyi274ie li a{color:#00e;text-decoration:underline}.Y5OHVyAfkAv6WGPwKqkF{display:list-item;padding-left:10px}.vWrfShUNIH4VlIT6wH0m{color:var(--lightgray);font-style:italic;line-height:1.5;margin-bottom:8px}.AIZNY_GcmDJH2vw_0EUD{color:#00e;text-decoration:underline}@media(min-width: 480px){.V4nREAPR8xEKgyi274ie{padding-inline-start:revert}}`, "",{"version":3,"sources":["webpack://./src/client/components/composites/PartOfSpeech/PartOfSpeech.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,QAAA,CACA,yBAAA,CACA,eAAA,CACA,yBAAA,CAEA,+BACE,0BAAA,CACA,cAAA,CAGF,yBACE,iBAAA,CACA,iBAAA,CAEA,2BACE,UAAA,CACA,yBAAA,CAKN,sBACE,iBAAA,CACA,iBAAA,CAGF,sBACE,sBAAA,CACA,iBAAA,CACA,eAAA,CACA,iBAAA,CAGF,sBACE,UAAA,CACA,yBAAA,CAGF,yBACE,sBACE,2BAAA,CAAA","sourcesContent":[".definitions {\n  display: grid;\n  gap: 16px;\n  grid-template-columns: 1fr;\n  margin: 0 0 32px;\n  padding-inline-start: 15px;\n\n  ::marker {\n    color: var(--lightgraytext);\n    font-size: 14px;\n  }\n\n  li {\n    display: list-item;\n    padding-left: 10px;\n\n    a {\n      color: #00e;\n      text-decoration: underline;\n    }\n  }\n}\n\n.definition {\n  display: list-item;\n  padding-left: 10px;\n}\n\n.luna-pos {\n  color: var(--lightgray);\n  font-style: italic;\n  line-height: 1.5;\n  margin-bottom: 8px;\n}\n\n.luna-xref {\n  color: #00e;\n  text-decoration: underline;\n}\n\n@media (min-width: 480px) {\n  .definitions {\n    padding-inline-start: revert;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"definitions": `V4nREAPR8xEKgyi274ie`,
	"definition": `Y5OHVyAfkAv6WGPwKqkF`,
	"luna-pos": `vWrfShUNIH4VlIT6wH0m`,
	"luna-xref": `AIZNY_GcmDJH2vw_0EUD`
};
module.exports = ___CSS_LOADER_EXPORT___;
