import React from 'react'
import { string } from 'prop-types'
import cx from 'classnames'
import withStyles from 'isomorphic-style-loader/withStyles'
import { Notification } from '~elements'
import styles from './WordPuzzleNotification.module.scss'

const SUCCESS_STRINGS = [
  'Nice!',
  'Bravo!',
  'Hooray!',
  'Good job!',
  'Way to go!',
  'You got it!',
  'You make it look easy!'
]

const WordPuzzleNotification = ({ submissionType }) => {
  const isValidWord = submissionType === 'valid'
  const classNames = cx({
    [styles.valid]: isValidWord,
    [styles.invalid]: !isValidWord
  })

  const submissionNotification = submissionType => {
    if (submissionType === 'found') {
      return 'Already found'
    }

    if (submissionType === 'invalid') {
      return 'Not on the list'
    }

    const successStringText =
      SUCCESS_STRINGS[Math.floor(Math.random() * SUCCESS_STRINGS.length)]
    return successStringText
  }

  const notificationText = submissionNotification(submissionType)

  return <Notification className={classNames}>{notificationText}</Notification>
}

WordPuzzleNotification.propTypes = {
  submissionType: string.isRequired
}

export default withStyles(styles)(WordPuzzleNotification)
