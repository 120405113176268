import React, { useEffect } from 'react'
import { number, string } from 'prop-types'
import cx from 'classnames'
import withStyles from 'isomorphic-style-loader/withStyles'
import { useSelector } from 'react-redux'
import { AzLinkMenu, HorizontalRuleTitle } from '~elements'
import { selectSiteName } from '~store/location/selectors'
import { LINKID_BROWSE_AZ } from '~utils/analytics/linkIds'
import styles from './BrowsePageNav.module.scss'

const BASE_URL = '/list/'

export const BrowsePageNav = ({ className, letter, page }) => {
  const siteName = useSelector(selectSiteName)
  const classNames = cx(styles.root, className)
  const pageNumberingText = page > 1 ? ` (Page ${page})` : null

  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', left: 0, top: 0 })
  }, [letter, page])

  return (
    <div className={classNames} id="browse-nav">
      <HorizontalRuleTitle>
        <h1 className={styles.heading}>
          Browse <span>{siteName}</span>: Letter &quot;{letter}&quot;
          {pageNumberingText}
        </h1>
      </HorizontalRuleTitle>
      <AzLinkMenu baseUrl={BASE_URL} linkId={LINKID_BROWSE_AZ} />
    </div>
  )
}

BrowsePageNav.propTypes = {
  className: string,
  letter: string.isRequired,
  page: number
}

BrowsePageNav.defaultProps = {
  className: null,
  page: 1
}

export default withStyles(styles)(BrowsePageNav)
