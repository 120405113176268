// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BcMfXAT8upVw4RDlS9pf{align-items:center;border:2px solid var(--dictionaryblue);-webkit-box-align:center;color:var(--darkgraytext);display:flex;font-size:14px;margin-bottom:16px;padding:16px}.BcMfXAT8upVw4RDlS9pf::before{color:var(--dictionaryblue);content:var(--icon-checkmark);display:inline-block;font-family:var(--font-icon);font-size:24px;height:24px;margin-right:4px;transition:transform .3s ease 0s;vertical-align:middle;width:24px}`, "",{"version":3,"sources":["webpack://./src/client/components/composites/SuccessCard/SuccessCard.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,sCAAA,CACA,wBAAA,CACA,yBAAA,CACA,YAAA,CACA,cAAA,CACA,kBAAA,CACA,YAAA,CAEA,8BACE,2BAAA,CACA,6BAAA,CACA,oBAAA,CACA,4BAAA,CACA,cAAA,CACA,WAAA,CACA,gBAAA,CACA,gCAAA,CACA,qBAAA,CACA,UAAA","sourcesContent":[".root {\n  align-items: center;\n  border: 2px solid var(--dictionaryblue);\n  -webkit-box-align: center;\n  color: var(--darkgraytext);\n  display: flex;\n  font-size: 14px;\n  margin-bottom: 16px;\n  padding: 16px;\n\n  &::before {\n    color: var(--dictionaryblue);\n    content: var(--icon-checkmark);\n    display: inline-block;\n    font-family: var(--font-icon);\n    font-size: 24px;\n    height: 24px;\n    margin-right: 4px;\n    transition: transform 0.3s ease 0s;\n    vertical-align: middle;\n    width: 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `BcMfXAT8upVw4RDlS9pf`
};
module.exports = ___CSS_LOADER_EXPORT___;
