import React from 'react'
import { arrayOf, object, shape, string } from 'prop-types'
import withStyles from 'isomorphic-style-loader/withStyles'
import PartOfSpeech from '~composites/PartOfSpeech/PartOfSpeech'
import PronunciationAudio from '~composites/PronunciationAudio/PronunciationAudio'
import PronunciationToggle from '~composites/PronunciationToggle/PronunciationToggle'
import { A } from '~elements'
import useDictionaryUrl from '~hooks/useDictionaryUrl'
import { LINKID_WORD_COMPARISON } from '~utils/analytics/linkIds'
import styles from './WordComparisonCard.module.scss'

const WordComparisonCard = ({
  wordData: { entry, posBlocks, pronunciation }
}) => {
  const { browseUrl } = useDictionaryUrl()

  let pronunciationAudioBlock
  let pronunciationBlock

  if (pronunciation) {
    const { audio, ipa, spell } = pronunciation
    const hasAudio = audio && !!audio['audio/mpeg']
    const hasPronunciation = ipa && spell

    pronunciationAudioBlock = hasAudio ? (
      <PronunciationAudio
        audioSrc={audio['audio/mpeg']}
        className={styles.pronunciation}
      />
    ) : null

    pronunciationBlock = hasPronunciation ? (
      <PronunciationToggle ipa={ipa} spelling={spell} />
    ) : null
  }

  const hasPos = posBlocks?.length > 0

  const partOfSpeechBlock = hasPos ? (
    <div className={styles.content}>
      <PartOfSpeech entry={entry} posBlocks={posBlocks} />
    </div>
  ) : null

  return (
    <section className={styles.root} data-type="word-comparison-card">
      <header>
        <div className={styles.heading}>
          <h2>
            <A
              href={`${browseUrl}/${entry.toLowerCase()}`}
              linkId={LINKID_WORD_COMPARISON.headword}
            >
              {entry}
            </A>
          </h2>
          {pronunciationAudioBlock}
        </div>
        {pronunciationBlock}
      </header>
      {partOfSpeechBlock}
    </section>
  )
}

WordComparisonCard.propTypes = {
  wordData: shape({
    entry: string,
    posBlocks: arrayOf(object),
    pronunciation: object
  }).isRequired
}

export default withStyles(styles)(WordComparisonCard)
