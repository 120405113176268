import React from 'react'
import withStyles from 'isomorphic-style-loader/withStyles'
import { A } from '~elements'
import useGamesUrl from '~hooks/useGamesUrl'
import styles from './HurdleContent.module.scss'

const HurdleContent = () => {
  const { allGamesUrl } = useGamesUrl()

  return (
    <section data-type="hurdle-content">
      <p>
        Hurdle is a daily puzzle that challenges you to find a secret
        five-letter word. For the first four puzzles, you have six guesses each.
        A green letter means the letter is in the right spot, a yellow letter
        means the letter is not in the right spot, and a gray letter means that
        letter isn’t in the word at all. The correct answer of each puzzle will
        be used as your first guess in the next puzzle. In the final puzzle, the
        four previous words will be used as your first four guesses. You only
        have two tries to guess the final word and complete the puzzle!
      </p>
      <p className={styles.cta}>
        Enjoying Hurdle? Check out these other{' '}
        <A href={allGamesUrl}>word games</A>!
      </p>
    </section>
  )
}

export default withStyles(styles)(HurdleContent)
