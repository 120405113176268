import React from 'react'
import { array } from 'prop-types'
import withStyles from 'isomorphic-style-loader/withStyles'
import styles from './SynonymDefinitionList.module.scss'

const MAX_DEFINITIONS = 3

const SynonymDefinitionList = ({ definitions }) => {
  const definitionList = definitions
    .slice(0, MAX_DEFINITIONS)
    .map(definition => (
      <li className={styles['definition-item']} key={definition}>
        {definition}
      </li>
    ))

  return <ol className={styles.root}>{definitionList}</ol>
}

SynonymDefinitionList.propTypes = {
  definitions: array.isRequired
}

export default withStyles(styles)(SynonymDefinitionList)
