// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Kd5YM3Lqo7S1QrScZGXV{height:750px;margin-bottom:125px;position:relative;top:120px}.Kd5YM3Lqo7S1QrScZGXV .cbola-separated::before,.Kd5YM3Lqo7S1QrScZGXV .cbola-separated::after{border:none}.Cy7RVUbRw902iLBknQxB{position:sticky;top:150px}`, "",{"version":3,"sources":["webpack://./src/client/components/composites/CarambolaAd/CarambolaAd.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,mBAAA,CACA,iBAAA,CACA,SAAA,CAEA,6FAEE,WAAA,CAIJ,sBACE,eAAA,CACA,SAAA","sourcesContent":[".root {\n  height: 750px;\n  margin-bottom: 125px;\n  position: relative;\n  top: 120px;\n\n  :global(.cbola-separated::before),\n  :global(.cbola-separated::after) {\n    border: none;\n  }\n}\n\n.carambola-container {\n  position: sticky;\n  top: 150px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `Kd5YM3Lqo7S1QrScZGXV`,
	"carambola-container": `Cy7RVUbRw902iLBknQxB`
};
module.exports = ___CSS_LOADER_EXPORT___;
