// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.S3sz75sUX8TIP162Nzd3{background-color:#fff;border-radius:12px;color:var(--darkgraytext);display:flex;flex-direction:column;font-family:Arial,sans-serif;height:inherit;padding:24px}.HtcaxwXmT4fkpzABHerH>a,.ROHEScUTv_4RUIqj2l44>a{color:var(--darkgraytext);font-family:Arial,sans-serif;text-decoration:none}.HtcaxwXmT4fkpzABHerH>a:hover,.ROHEScUTv_4RUIqj2l44>a:hover{text-decoration:underline}.HtcaxwXmT4fkpzABHerH{line-height:.8;margin:0}.ROHEScUTv_4RUIqj2l44{font-size:16px;line-height:1.15}`, "",{"version":3,"sources":["webpack://./src/client/components/composites/SynonymComparisonCard/SynonymComparisonCard.module.scss"],"names":[],"mappings":"AAAA,sBACE,qBAAA,CACA,kBAAA,CACA,yBAAA,CACA,YAAA,CACA,qBAAA,CACA,4BAAA,CACA,cAAA,CACA,YAAA,CAKA,gDACE,yBAAA,CACA,4BAAA,CACA,oBAAA,CAEA,4DACE,yBAAA,CAKN,sBACE,cAAA,CACA,QAAA,CAGF,sBACE,cAAA,CACA,gBAAA","sourcesContent":[".root {\n  background-color: #fff;\n  border-radius: 12px;\n  color: var(--darkgraytext);\n  display: flex;\n  flex-direction: column;\n  font-family: Arial, sans-serif;\n  height: inherit;\n  padding: 24px;\n}\n\n.headword,\n.definition {\n  > a {\n    color: var(--darkgraytext);\n    font-family: Arial, sans-serif;\n    text-decoration: none;\n\n    &:hover {\n      text-decoration: underline;\n    }\n  }\n}\n\n.headword {\n  line-height: 0.8;\n  margin: 0;\n}\n\n.definition {\n  font-size: 16px;\n  line-height: 1.15;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `S3sz75sUX8TIP162Nzd3`,
	"headword": `HtcaxwXmT4fkpzABHerH`,
	"definition": `ROHEScUTv_4RUIqj2l44`
};
module.exports = ___CSS_LOADER_EXPORT___;
