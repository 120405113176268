import React, { useCallback } from 'react'
import { string } from 'prop-types'
import cx from 'classnames'
import withStyles from 'isomorphic-style-loader/withStyles'
import { VolumeIcon } from '~elements'
import styles from './PronunciationAudioRedesign.module.scss'

export const PronunciationAudioRedesign = ({
  audioSrc,
  className,
  displayForm,
  ...props
}) => {
  const classNames = cx(styles.root, className)

  const playAudio = useCallback(
    event => {
      event.stopPropagation()
      const audio = new window.Audio(audioSrc)
      audio.play()
    },
    [audioSrc]
  )

  return (
    <button
      aria-label={`Audio: pronunciation of ${displayForm}`}
      className={classNames}
      data-type="pronunciation-audio"
      onClick={playAudio}
      {...props}
    >
      <VolumeIcon />
    </button>
  )
}

PronunciationAudioRedesign.propTypes = {
  audioSrc: string.isRequired,
  className: string,
  displayForm: string
}

PronunciationAudioRedesign.defaultProps = {
  className: null,
  displayForm: 'this word'
}

export default withStyles(styles)(PronunciationAudioRedesign)
