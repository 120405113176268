import React from 'react'
import { bool, object } from 'prop-types'
import withStyles from 'isomorphic-style-loader/withStyles'
import { A, ChevronDownIcon, DefaultLink } from '~elements'
import styles from './RelatedWordCard.module.scss'

const RelatedWordCard = ({ isFirstCard, relatedWord }) => {
  const { pos, relatedWords, shortDef, slug } = relatedWord

  return (
    <details
      className={styles.root}
      data-type="related-word-card"
      key={slug.url}
      open={isFirstCard}
    >
      <summary>
        <div className={styles['related-word']}>
          <A href={`/browse/${slug.url}`}>{slug.headword.displayForm}</A>
          <p>
            <span className={styles.pos}>{pos}</span>
            <em className={styles['as-in']}>
              as in <strong className={styles.definition}>{shortDef}</strong>
            </em>
          </p>
        </div>
        <ChevronDownIcon className={styles.arrow} />
      </summary>
      <ul>
        {relatedWords.map(relatedWord => (
          <li key={relatedWord.url}>
            <DefaultLink
              className={styles.synonym}
              href={`/browse/${relatedWord.url}`}
            >
              {relatedWord.headword.displayForm}
            </DefaultLink>
          </li>
        ))}
      </ul>
    </details>
  )
}

RelatedWordCard.propTypes = {
  isFirstCard: bool,
  relatedWord: object.isRequired
}

RelatedWordCard.defaultProps = {
  isFirstCard: false
}

export default withStyles(styles)(RelatedWordCard)
