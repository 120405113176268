import React from 'react'
import { bool, func } from 'prop-types'
import cx from 'classnames'
import withStyles from 'isomorphic-style-loader/withStyles'
import { PROPTYPE_CHILDREN } from '~client/constants'
import { Button } from '~elements'
import styles from './WordPuzzleLetterButton.module.scss'

const WordPuzzleLetterButton = ({ children, isSelected, onClick }) => {
  const classNames = cx(styles.root, { [styles.selected]: isSelected })

  return (
    <Button
      className={classNames}
      data-type="word-puzzle-letter"
      onClick={onClick}
    >
      {children}
    </Button>
  )
}

WordPuzzleLetterButton.propTypes = {
  children: PROPTYPE_CHILDREN.isRequired,
  isSelected: bool.isRequired,
  onClick: func.isRequired
}

export default withStyles(styles)(WordPuzzleLetterButton)
