import React from 'react'
import { number, object, string } from 'prop-types'
import cx from 'classnames'
import withStyles from 'isomorphic-style-loader/withStyles'
import { SeeMoreBlock } from '~composites'
import { ExampleSentence } from '~elements'
import isArrayWithLength from '~utils/isArrayWithLength'
import styles from './ExampleSentences.module.scss'

const ExampleSentences = ({
  className,
  sentences,
  sentencesToDisplay,
  word
}) => {
  const classNames = cx(styles.root, className)

  const { nlp = [], tdb = [], gbg = [] } = sentences
  const allSentences = [...nlp, ...tdb, ...gbg]

  const initalSentencesData = allSentences.slice(0, sentencesToDisplay)
  const remainingSentencesData = allSentences.slice(sentencesToDisplay)

  const getExampleBlocks = blocks => {
    const exampleBlock = blocks.map(eachExample => {
      const { id, sentence, source } = eachExample

      return (
        <ExampleSentence
          key={id}
          sentence={sentence}
          source={source}
          word={word}
        />
      )
    })
    return <ul>{exampleBlock}</ul>
  }

  const firstBlock = getExampleBlocks(initalSentencesData)
  const secondBlock = isArrayWithLength(remainingSentencesData)
    ? getExampleBlocks(remainingSentencesData)
    : null

  return (
    <div className={classNames}>
      <h2>How to use {word} in a sentence</h2>
      {firstBlock}
      {secondBlock && (
        <SeeMoreBlock
          buttonClosedText={'See More Examples'}
          buttonOpenText={'See Fewer Examples'}
          className={styles['see-more-example-usage']}
        >
          {secondBlock}
        </SeeMoreBlock>
      )}
    </div>
  )
}

ExampleSentences.propTypes = {
  className: string,
  sentences: object.isRequired,
  sentencesToDisplay: number,
  word: string.isRequired
}

ExampleSentences.defaultProps = {
  className: null,
  sentencesToDisplay: 5
}

export default withStyles(styles)(ExampleSentences)
