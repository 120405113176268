import React, { useEffect } from 'react'
import { array, func } from 'prop-types'
import {
  Select as AkSelect,
  SelectArrow,
  SelectItem,
  SelectPopover,
  useSelectState
} from 'ariakit/select'
import withStyles from 'isomorphic-style-loader/withStyles'
import styles from './SelectPos.module.scss'

/**
 * SelectPos dropdown component
 * @param {{ name: String, value: String }[]} items - Select options
 * @callback setSelected - State method to set the selected value for the parent component
 */
const SelectPos = ({ items, setSelected }) => {
  const select = useSelectState({
    defaultValue: items[0].value,
    gutter: 4,
    sameWidth: true
  })

  const renderValue = itemSelected => {
    return (
      <div
        className={styles['luna-pos']}
        dangerouslySetInnerHTML={{ __html: itemSelected.value }}
      />
    )
  }

  const filteredSelectItems = items.filter(item => item.value !== select.value)

  const selectItems = filteredSelectItems?.map(item => {
    const { name, value } = item
    return (
      <SelectItem className={styles['select-item']} key={value} value={value}>
        <div
          className={styles['luna-pos']}
          dangerouslySetInnerHTML={{ __html: name }}
        />
      </SelectItem>
    )
  })

  useEffect(() => {
    setSelected(select.value)
  }, [select.value])

  return (
    <div className={styles.root} data-type="select-pos-dropdown">
      <AkSelect className={styles.select} state={select}>
        {renderValue(select)}
        <SelectArrow />
      </AkSelect>
      <SelectPopover className={styles.popover} state={select}>
        {selectItems}
      </SelectPopover>
    </div>
  )
}

SelectPos.propTypes = {
  items: array.isRequired,
  setSelected: func.isRequired
}

export default withStyles(styles)(SelectPos)
