import React from 'react'
import { bool, string } from 'prop-types'
import cx from 'classnames'
import withStyles from 'isomorphic-style-loader/withStyles'
import { A } from '~elements'
import styles from './AzLinkMenu.module.scss'

const AZ = '0abcdefghijklmnopqrstuvwxyz'

export const AzLinkMenu = ({
  baseUrl,
  className,
  linkId,
  showBothCases,
  title
}) => {
  const classNames = cx(styles.root, className)
  const linkListItems = Array.from(AZ).map((letter, index) => {
    const url = `${baseUrl}${letter}`
    let text = letter

    if (index === 0) text = '#'
    else if (showBothCases) text = `${letter}${letter}`

    return (
      <li className={classNames} data-type="az-menu-item" key={letter}>
        <A href={url} linkId={linkId}>
          {text}
        </A>
      </li>
    )
  })
  return (
    <nav className={classNames} data-type="az-menu">
      <menu>
        {title && <li>{title}</li>}
        {linkListItems}
      </menu>
    </nav>
  )
}

AzLinkMenu.propTypes = {
  baseUrl: string.isRequired,
  className: string,
  linkId: string.isRequired,
  showBothCases: bool,
  title: string
}

AzLinkMenu.defautProps = {
  className: null,
  showBothCases: false,
  title: null
}

export default withStyles(styles)(AzLinkMenu)
