// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TwsmLNYPI4kUxOk8d4jD{background-color:rgba(0,0,0,0);border:none;display:inline}._sLTuq7V6_Lwe5ivsK8O{cursor:pointer;padding:0}`, "",{"version":3,"sources":["webpack://./src/client/components/composites/IconButton/IconButton.module.scss"],"names":[],"mappings":"AAAA,sBACE,8BAAA,CACA,WAAA,CACA,cAAA,CAGF,sBACE,cAAA,CACA,SAAA","sourcesContent":[".root {\n  background-color: transparent;\n  border: none;\n  display: inline;\n}\n\n.icon {\n  cursor: pointer;\n  padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `TwsmLNYPI4kUxOk8d4jD`,
	"icon": `_sLTuq7V6_Lwe5ivsK8O`
};
module.exports = ___CSS_LOADER_EXPORT___;
