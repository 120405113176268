import React from 'react'
import { string } from 'prop-types'
import cx from 'classnames'
import withStyles from 'isomorphic-style-loader/withStyles'
import { PROPTYPE_CHILDREN } from '~client/constants'
import { Pill } from '~elements'
import useThesaurusUrl from '~hooks/useThesaurusUrl'
import { LINKID_SYNONYM_COMPARISON } from '~utils/analytics/linkIds'
import styles from './SynonymPill.module.scss'

const SynonymPill = ({ children, similarity, slug }) => {
  const { browseUrl } = useThesaurusUrl()

  const classNames = cx(styles.root, {
    [styles[`similarity-${similarity}`]]: similarity
  })

  const href = slug ? `${browseUrl}/${slug}` : '#'

  return (
    <Pill
      className={classNames}
      data-type="synonym-pill"
      href={href}
      linkId={LINKID_SYNONYM_COMPARISON.synonym}
    >
      {children}
    </Pill>
  )
}

SynonymPill.propTypes = {
  children: PROPTYPE_CHILDREN.isRequired,
  similarity: string,
  slug: string
}

SynonymPill.defaultProps = {
  similarity: null,
  slug: null
}

export default withStyles(styles)(SynonymPill)
