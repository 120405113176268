import React, { useEffect, useRef, useState } from 'react'
import { number, string } from 'prop-types'

const LazyAd = ({ className, height, slot, slotName, width, ...props }) => {
  const lazyAdRef = useRef(null)
  const [hasRequestedAd, setHasRequestedAd] = useState(false)

  useEffect(() => {
    if (lazyAdRef.current) {
      const observer = new IntersectionObserver(handleShowingAd)
      observer.observe(lazyAdRef.current)

      if (hasRequestedAd) {
        window.googletag = window.googletag || {}
        window.googletag.cmd = window.googletag.cmd || []

        window.googletag.cmd.push(function () {
          window.googletag.display(slot)
        })
      }

      return () => observer.disconnect()
    }
  }, [hasRequestedAd])

  const handleShowingAd = ([entry]) => {
    if (entry.isIntersecting) {
      setHasRequestedAd(true)
    }
  }

  return (
    <div ref={lazyAdRef}>
      {hasRequestedAd && (
        <div
          className={className}
          id={slotName}
          style={{ height, width }}
          {...props}
        />
      )}
    </div>
  )
}

LazyAd.propTypes = {
  className: string,
  height: number,
  slot: string.isRequired,
  slotName: string.isRequired,
  width: number
}

LazyAd.defaultProps = {
  className: null,
  height: null,
  width: null
}

export default LazyAd
