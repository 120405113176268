import { string } from 'prop-types'
import withStyles from 'isomorphic-style-loader/withStyles'
import styles from './SuccessCard.module.scss'

export const SuccessCard = ({ message }) => {
  return <div className={styles.root}>{message}</div>
}

SuccessCard.propTypes = {
  message: string.isRequired
}

export default withStyles(styles)(SuccessCard)
