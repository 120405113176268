import React from 'react'
import { array } from 'prop-types'
import withStyles from 'isomorphic-style-loader/withStyles'
import { A } from '~elements'
import styles from './BrowseList.module.scss'

export const BrowseList = ({ browseListEntries }) => {
  const browseListItems = browseListEntries.length && browseListEntries.map(
      ({ slug, displayForm }) => (
        <li key={displayForm}>
          <A href={`/browse/${slug}`}>{displayForm}</A>
        </li>
      )
    );

  return (
    <div className={styles.root} data-type="browse-list">
      <ul>{browseListItems}</ul>
    </div>
  )
}

BrowseList.propTypes = {
  browseListEntries: array.isRequired
}

export default withStyles(styles)(BrowseList)
