import React from 'react'
import { string } from 'prop-types'
import cx from 'classnames'
import withStyles from 'isomorphic-style-loader/withStyles'
import { PROPTYPE_CHILDREN } from '~client/constants'
import { ExpandableContent } from '~elements'
import styles from './SeeMoreInline.module.scss'

const SeeMoreInline = ({ children, className, ...props }) => {
  const classNames = cx(styles.root, className)

  return (
    <ExpandableContent
      className={classNames}
      data-type="see-more-inline"
      {...props}
    >
      {children}
    </ExpandableContent>
  )
}

SeeMoreInline.propTypes = {
  children: PROPTYPE_CHILDREN.isRequired,
  className: string
}

SeeMoreInline.defaultProps = {
  className: null
}

export default withStyles(styles)(SeeMoreInline)
