import React, { useState } from 'react'
import { array, object } from 'prop-types'
import cx from 'classnames'
import withStyles from 'isomorphic-style-loader/withStyles'
import isArrayWithLength from '~utils/isArrayWithLength'
import isObjectWithProperties from '~utils/isObjectWithProperties'
import parseHtml from '~utils/parseHtml'
import { parseChunks } from '~utils/semanticChunkParsers'
import styles from './PronunciationToggleRedesign.module.scss'

const IPA = 'ipa'
const PHONETIC = 'phonetic'

export const PronunciationToggleRedesign = ({
  pronunciations,
  parsedPronunciationChunks
}) => {
  if (
    !isArrayWithLength(pronunciations) &&
    !isObjectWithProperties(parsedPronunciationChunks)
  )
    return null

  const inflection = pronunciations.find(
    ({ inflection }) => inflection
  )?.inflection

  const getPronunciationList = type => {
    if (
      !pronunciations.some(pronunciation =>
        isArrayWithLength(pronunciation.groups)
      )
    ) {
      const pronunciationText = pronunciations
        ?.map(pronunciation => pronunciation[type])
        .filter(pronunciation => typeof pronunciation === 'string')
        .join('; ')

      return parseHtml(pronunciationText)
    }

    return pronunciations.map((pronunciation, index) => {
      const partOfSpeech = pronunciation.partsOfSpeech?.[0]
      const parsedChunks = parseChunks(
        pronunciation.groups?.[0]?.[type]?.chunks
      )
      const punctuation =
        pronunciations.length > 1 && index < pronunciations.length - 1
          ? '; '
          : ''

      return (
        <React.Fragment key={`pronunciation-${index + 1}`}>
          {partOfSpeech ? partOfSpeech + ' ' : ''}
          {parsedChunks}
          {punctuation}
        </React.Fragment>
      )
    })
  }

  const ipaList = getPronunciationList('ipa')
  const spellList = getPronunciationList('spell')

  if (
    !isObjectWithProperties(parsedPronunciationChunks) &&
    !ipaList.length &&
    !spellList.length
  )
    return null

  const hasBothForms = Boolean(
    (ipaList.length && spellList.length) ||
      (parsedPronunciationChunks?.parsedSpellChunks &&
        parsedPronunciationChunks?.parsedIpaChunks)
  )
  const hasOnlySpell = Boolean(!ipaList.length && spellList.length)
  const [selectedForm, setSelectedForm] = useState(
    hasBothForms || hasOnlySpell ? PHONETIC : IPA
  )
  const showPhonetic = selectedForm === PHONETIC

  const displayTextFromText = showPhonetic ? (
    <>[ {spellList} ]</>
  ) : (
    <>/ {ipaList} /</>
  )
  const displayTextFromChunks = showPhonetic ? (
    <>[ {parsedPronunciationChunks?.parsedSpellChunks} ]</>
  ) : (
    <>/ {parsedPronunciationChunks?.parsedIpaChunks} /</>
  )
  const displayText = isObjectWithProperties(parsedPronunciationChunks)
    ? displayTextFromChunks
    : displayTextFromText

  const handleSelection = ({ target: { value } }) => {
    setSelectedForm(value)
  }

  const hasMultiplePhoneticSpellings =
    spellList.length > 1 &&
    typeof spellList !== 'string' &&
    spellList?.every(item => typeof item === 'object')
  const hasMultipleIpaSpellings =
    ipaList.length > 1 &&
    typeof ipaList !== 'string' &&
    ipaList?.every(item => typeof item === 'object')

  const classNames = cx(styles.root, {
    [styles.stacked]:
      (showPhonetic && hasMultiplePhoneticSpellings) ||
      (!showPhonetic && hasMultipleIpaSpellings),
    [styles.inflection]: inflection
  })

  return (
    <div className={classNames} data-type="pronunciation-toggle">
      <p>{displayText}</p>
      {inflection && (
        <p>
          {', '}
          {parseHtml(inflection)}
        </p>
      )}
      {hasBothForms && (
        <fieldset>
          <label>
            <input
              checked={showPhonetic}
              onChange={handleSelection}
              type="radio"
              value={PHONETIC}
            />
            <span>Phonetic (Standard)</span>
          </label>
          <label>
            <input
              checked={!showPhonetic}
              onChange={handleSelection}
              type="radio"
              value={IPA}
            />
            <span>IPA</span>
          </label>
        </fieldset>
      )}
    </div>
  )
}

PronunciationToggleRedesign.propTypes = {
  parsedPronunciationChunks: object,
  pronunciations: array
}

PronunciationToggleRedesign.defaultProps = {
  parsedPronunciationChunks: {},
  pronunciations: []
}

export default withStyles(styles)(PronunciationToggleRedesign)
