import React from 'react'
import { array } from 'prop-types'
import withStyles from 'isomorphic-style-loader/withStyles'
import { MoreQuizzesCard } from '~composites'
import styles from './MoreQuizzes.module.scss'

const MoreQuizzes = ({ quizList }) => {
  const quizzes = quizList.map(({ quiz }) => (
    <li key={quiz.quizID}>
      <MoreQuizzesCard quiz={quiz} />
    </li>
  ))

  return (
    <div className={styles.root} data-type="more-quizzes">
      <header>
        <h2>More quizzes from Dictionary.com</h2>
      </header>
      <ul>{quizzes}</ul>
    </div>
  )
}

MoreQuizzes.propTypes = {
  quizList: array.isRequired
}

export default withStyles(styles)(MoreQuizzes)
