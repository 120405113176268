import React from 'react'
import withStyles from 'isomorphic-style-loader/withStyles'
import { A } from '~elements'
import useDictionaryUrl from '~hooks/useDictionaryUrl'
import useGamesUrl from '~hooks/useGamesUrl'
import styles from './MiniCrosswordContent.module.scss'

const MiniCrosswordContent = () => {
  const { crosswordUrl } = useDictionaryUrl()
  const { allGamesUrl, easyCrosswordUrl, sundayCrosswordUrl } = useGamesUrl()

  return (
    <section data-type="mini-crossword-content">
      <p>
        The Mini Crossword is a smaller version of a regular crossword puzzle
        that’s great for a quick brain break! The rules and gameplay are the
        same, just in a more compact layout. See how quickly you can solve each
        clue and fill in all the boxes to complete the entire Mini Crossword!
      </p>
      <strong>Enjoy these additional crossword puzzles!</strong>
      <ul>
        <li>
          <A href={crosswordUrl}>Daily Crossword</A>
        </li>
        <li>
          <A href={sundayCrosswordUrl}>Sunday Crossword</A>
        </li>
        <li>
          <A href={easyCrosswordUrl}>Easy Crossword</A>
        </li>
      </ul>
      <p className={styles.cta}>
        We’ve got more for you to enjoy. Visit the{' '}
        <A href={allGamesUrl}>online games hub</A>!
      </p>
    </section>
  )
}

export default withStyles(styles)(MiniCrosswordContent)
