// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dDeYl3zUalQgXsSgFtAi{column-count:1;column-gap:2.5em;display:flex;flex-direction:column;font-family:Arial,sans-serif}.dDeYl3zUalQgXsSgFtAi ul{list-style-type:circle}.dDeYl3zUalQgXsSgFtAi a{color:var(--dictionaryblue);cursor:pointer;font-size:18px;text-decoration:none}.qK9jSmbtQGgLWj7jDV5I::before{color:var(--lightgray);content:"|";margin-left:.25em;margin-right:.25em}@media(min-width: 768px){.dDeYl3zUalQgXsSgFtAi ul{column-count:2}}`, "",{"version":3,"sources":["webpack://./src/client/components/composites/BrowseList/BrowseList.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,gBAAA,CACA,YAAA,CACA,qBAAA,CACA,4BAAA,CAEA,yBACE,sBAAA,CAGF,wBACE,2BAAA,CACA,cAAA,CACA,cAAA,CACA,oBAAA,CAIJ,8BACE,sBAAA,CACA,WAAA,CACA,iBAAA,CACA,kBAAA,CAGF,yBAEI,yBACE,cAAA,CAAA","sourcesContent":[".root {\n  column-count: 1;\n  column-gap: 2.5em;\n  display: flex;\n  flex-direction: column;\n  font-family: Arial, sans-serif;\n\n  ul {\n    list-style-type: circle;\n  }\n\n  a {\n    color: var(--dictionaryblue);\n    cursor: pointer;\n    font-size: 18px;\n    text-decoration: none;\n  }\n}\n\n.alternative-slug::before {\n  color: var(--lightgray);\n  content: '|';\n  margin-left: 0.25em;\n  margin-right: 0.25em;\n}\n\n@media (min-width: 768px) {\n  .root {\n    ul {\n      column-count: 2;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `dDeYl3zUalQgXsSgFtAi`,
	"alternative-slug": `qK9jSmbtQGgLWj7jDV5I`
};
module.exports = ___CSS_LOADER_EXPORT___;
