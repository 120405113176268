import React, { useCallback } from 'react'
import { func, string } from 'prop-types'
import cx from 'classnames'
import withStyles from 'isomorphic-style-loader/withStyles'
import { IconButton } from '~composites'
import speakerIcon from '~public/images/icons/speaker-icon.svg'
import styles from './PronunciationAudio.module.scss'

export const PronunciationAudio = ({
  className,
  audioSrc,
  onClick,
  ...props
}) => {
  const classNames = cx(styles.root, className)

  const handleAudio = useCallback(() => {
    const audio = new window.Audio(audioSrc)
    audio.play()
    if (onClick) onClick()
  }, [audioSrc])

  return (
    <IconButton
      className={classNames}
      data-type="pronunciation-text"
      onClick={handleAudio}
      src={speakerIcon}
      {...props}
    />
  )
}

PronunciationAudio.propTypes = {
  audioSrc: string.isRequired,
  className: string,
  onClick: func
}

PronunciationAudio.defaultProps = {
  className: null,
  onClick: null
}

export default withStyles(styles)(PronunciationAudio)
