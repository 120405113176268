// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `a.ST7dAofAIh8VkqxRX40I{background-color:#ffb600;border:none;color:var(--darkgraytext);font-size:12px;font-weight:bold;line-height:1;padding:8px 16px;text-transform:uppercase}`, "",{"version":3,"sources":["webpack://./src/client/components/composites/WotyBadge/WotyBadge.module.scss"],"names":[],"mappings":"AAAA,uBACE,wBAAA,CACA,WAAA,CACA,yBAAA,CACA,cAAA,CACA,gBAAA,CACA,aAAA,CACA,gBAAA,CACA,wBAAA","sourcesContent":["a.root {\n  background-color: #ffb600;\n  border: none;\n  color: var(--darkgraytext);\n  font-size: 12px;\n  font-weight: bold;\n  line-height: 1;\n  padding: 8px 16px;\n  text-transform: uppercase;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `ST7dAofAIh8VkqxRX40I`
};
module.exports = ___CSS_LOADER_EXPORT___;
