import {
  PATHNAME_EASY_CROSSWORD,
  PATHNAME_MINI_CROSSWORD,
  PATHNAME_SUNDAY_CROSSWORD,
  PLAY_DICTIONARY_URL
} from '~client/constants'

const useGamesUrl = () => {
  const baseGamesUrl = PLAY_DICTIONARY_URL

  const allGamesUrl = PLAY_DICTIONARY_URL

  const pathNameMiniCrossword = PATHNAME_MINI_CROSSWORD

  const easyCrosswordUrl = `${baseGamesUrl}${PATHNAME_EASY_CROSSWORD}`
  const miniCrosswordUrl = `${baseGamesUrl}${pathNameMiniCrossword}`
  const sundayCrosswordUrl = `${baseGamesUrl}${PATHNAME_SUNDAY_CROSSWORD}`

  return {
    allGamesUrl,
    easyCrosswordUrl,
    miniCrosswordUrl,
    sundayCrosswordUrl
  }
}

export default useGamesUrl
