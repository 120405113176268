import React, { useEffect, useRef, useState } from 'react'
import { bool, func, string } from 'prop-types'
import cx from 'classnames'
import withStyles from 'isomorphic-style-loader/withStyles'
import { PROPTYPE_CHILDREN } from '~client/constants'
import { EllipsisVerticalIcon, XIcon } from '~elements'
import useMenuPositioning from '~hooks/useMenuPositioning'
import styles from './WordListKebabMenu.module.scss'

const ANIMATION_DURATION = 250

const WordListKebabMenu = ({
  children,
  className,
  isOpen,
  setIsOpen,
  ...props
}) => {
  const buttonRef = useRef()
  const menuRef = useRef()
  const { menuPositioning, positionMenu, setMenuPositioning } =
    useMenuPositioning(buttonRef, menuRef)
  const [isInitialLoad, setIsInitialLoad] = useState(true)
  const [keepInDom, setKeepInDom] = useState(false)

  const overlayClassNames = cx({
    [styles.active]: isOpen
  })

  const menuContainerClassNames = cx(styles['menu-container'], {
    [styles.closing]: !isOpen,
    [styles.opening]: isOpen
  })

  useEffect(() => {
    setIsInitialLoad(false)
  }, [])

  useEffect(() => {
    if (isOpen) {
      positionMenu()
    } else if (!isInitialLoad && !isOpen) {
      setKeepInDom(true)
      setTimeout(() => {
        setKeepInDom(false)
        setMenuPositioning({})
      }, ANIMATION_DURATION)
    }
  }, [isOpen])

  const toggleMenu = () => {
    setIsOpen(!isOpen)
  }

  const onClick = event => {
    event.stopPropagation()
    toggleMenu()
  }

  return (
    <div className={className} data-type="word-list-kebab-menu">
      <div
        aria-hidden="true"
        className={overlayClassNames}
        onClick={toggleMenu}
      ></div>
      <button
        className={styles['toggle-button']}
        onClick={onClick}
        ref={buttonRef}
        type="button"
      >
        {isOpen ? <XIcon /> : <EllipsisVerticalIcon />}
      </button>
      {(isOpen || keepInDom) && (
        <div
          className={menuContainerClassNames}
          ref={menuRef}
          {...props}
          style={menuPositioning}
        >
          {children}
        </div>
      )}
    </div>
  )
}

WordListKebabMenu.propTypes = {
  children: PROPTYPE_CHILDREN.isRequired,
  className: string,
  isOpen: bool.isRequired,
  setIsOpen: func.isRequired
}

WordListKebabMenu.defaultProps = {
  className: null
}

export default withStyles(styles)(WordListKebabMenu)
