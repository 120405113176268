// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.V21uF5JqjsSVqQwpbDcm{align-items:center;background-color:#fff;border:none;border-radius:100px;color:var(--dictionaryblue);display:flex;font-size:16px;font-weight:bold;margin:0;width:max-content}.V21uF5JqjsSVqQwpbDcm:hover{transform:scale(1.05)}.quLPtp_65BxUZcwUGRb4{font-family:var(--font-icon);font-size:24px;margin:0}.quLPtp_65BxUZcwUGRb4::before{content:var(--icon-retry)}`, "",{"version":3,"sources":["webpack://./src/client/components/composites/RetakeButton/RetakeButton.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,qBAAA,CACA,WAAA,CACA,mBAAA,CACA,2BAAA,CACA,YAAA,CACA,cAAA,CACA,gBAAA,CACA,QAAA,CACA,iBAAA,CAEA,4BACE,qBAAA,CAIJ,sBACE,4BAAA,CACA,cAAA,CACA,QAAA,CAEA,8BACE,yBAAA","sourcesContent":[".root {\n  align-items: center;\n  background-color: #fff;\n  border: none;\n  border-radius: 100px;\n  color: var(--dictionaryblue);\n  display: flex;\n  font-size: 16px;\n  font-weight: bold;\n  margin: 0;\n  width: max-content;\n\n  &:hover {\n    transform: scale(1.05);\n  }\n}\n\n.icon {\n  font-family: var(--font-icon);\n  font-size: 24px;\n  margin: 0;\n\n  &::before {\n    content: var(--icon-retry);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `V21uF5JqjsSVqQwpbDcm`,
	"icon": `quLPtp_65BxUZcwUGRb4`
};
module.exports = ___CSS_LOADER_EXPORT___;
