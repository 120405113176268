import React from 'react'
import globalStyles from '~client/globals.css'
import { Export, FooterModule, HeaderModule } from '~exportable'

export const Header = props => (
  <Export>
    <HeaderModule {...props} />
  </Export>
)

export const Footer = props => (
  <Export>
    <FooterModule {...props} />
  </Export>
)

export const GlobalStyles = () => <style>{globalStyles._getCss()}</style>

export { default as getSiteContext } from '~utils/getSiteContext'
export { default as React } from 'react'
// Once we are sure all export consumers are using
// ReactDomClient, we can safely remove ReactDOM
export { default as ReactDOM } from 'react-dom'
export { default as ReactDomClient } from 'react-dom/client'
