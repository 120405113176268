import React, { useState } from 'react'
import { bool, object, string } from 'prop-types'
import withStyles from 'isomorphic-style-loader/withStyles'
import { BackToWordLink, QuizResults } from '~composites'
import { refreshAd } from '~composites/Ad/Ad'
import { Button } from '~elements'
import useDictionaryUrl from '~hooks/useDictionaryUrl'
import hasValue from '~utils/hasValue'
import QuizOption from '../QuizOption/QuizOption'
import styles from './Quiz.module.scss'

const Quiz = ({ answers, isWordListQuiz, quiz, quizType }) => {
  const { name, questions } = quiz
  const totalNumberOfQuestions = questions.length
  const { learnQuizzesUrl } = useDictionaryUrl()
  const [currentQuestionNumber, setCurrentQuestionNumber] = useState(0)
  const [score, setScore] = useState(0)
  const [selectedAnswers, setSelectedAnswers] = useState([])
  const [showNextQuestion, setShowNextQuestion] = useState(false)
  const [showScore, setShowScore] = useState(false)

  const handleAnswerOptionClick = (questionID, option, optionID) => {
    if (selectedAnswers.find(answer => answer.questionID === questionID)) {
      return
    }

    if (optionID === answers[questionID]) {
      setScore(score + 1)
    }

    setSelectedAnswers([...selectedAnswers, { option, optionID, questionID }])
    setShowNextQuestion(true)
  }

  const handleNextQuestion = nextQuestion => {
    if (nextQuestion < totalNumberOfQuestions) {
      setCurrentQuestionNumber(nextQuestion)
    } else {
      setShowScore(true)
    }
    refreshAd()
    setShowNextQuestion(false)
  }

  const selectedOption = selectedAnswers.length
    ? selectedAnswers[currentQuestionNumber]
    : {}
  const currentQuestion = questions[currentQuestionNumber]
  const correctAnswer = answers[currentQuestion.questionID]

  const quizOptions =
    totalNumberOfQuestions > 0
      ? currentQuestion.options.map(answerOption => (
          <li key={answerOption.optionID}>
            <QuizOption
              isCorrect={answerOption.optionID === correctAnswer}
              isSelected={hasValue(
                selectedOption,
                'optionID',
                answerOption.optionID
              )}
              onClick={() =>
                handleAnswerOptionClick(
                  currentQuestion.questionID,
                  answerOption.option,
                  answerOption.optionID
                )
              }
              questionAnswered={showNextQuestion}
            >
              {answerOption.option}
            </QuizOption>
          </li>
        ))
      : null

  return (
    <div className={styles.root} data-type="quiz">
      {!showScore && (
        <>
          <header>
            {!isWordListQuiz && (
              <BackToWordLink text="ALL QUIZZES" url={learnQuizzesUrl} />
            )}
            <h1>
              {name}
              {isWordListQuiz ? ' Quiz' : ''}
            </h1>
          </header>
          <div className={styles.container}>
            <div className={styles.count}>
              Question {currentQuestionNumber + 1} of {totalNumberOfQuestions}
            </div>
            {isWordListQuiz && (
              <>
                <p className={styles['word-list-question']}>
                  Which word matches the definition below?
                </p>
                <p className={styles['word-list-definition']}>Definition</p>
              </>
            )}
            <div className={styles.question}>{currentQuestion.question}</div>
          </div>
          <ul>{quizOptions}</ul>
          {showNextQuestion && (
            <Button
              className={styles['next-question']}
              onClick={() => handleNextQuestion(currentQuestionNumber + 1)}
            >
              NEXT QUESTION
            </Button>
          )}
        </>
      )}

      {showScore && (
        <QuizResults
          answers={answers}
          quiz={quiz}
          quizType={quizType}
          score={score}
          selectedAnswers={selectedAnswers}
          totalNumberOfQuestions={totalNumberOfQuestions}
        />
      )}
    </div>
  )
}

Quiz.propTypes = {
  answers: object.isRequired,
  isWordListQuiz: bool,
  quiz: object.isRequired,
  quizType: string.isRequired
}

Quiz.defaultProps = {
  isWordListQuiz: false
}

export default withStyles(styles)(Quiz)
