import React from 'react'
import { array, oneOfType, string } from 'prop-types'
import withStyles from 'isomorphic-style-loader/withStyles'
import { SubjectLabel } from '~elements'
import { getAllowedProps } from '~utils/props'
import { parseChunks } from '~utils/semanticChunkParsers'
import styles from './CommonContent.module.scss'

const CommonContent = ({ data, heading, id, ...props }) => (
  <section
    className={styles.root}
    data-type="common-content"
    {...getAllowedProps(props)}
  >
    <SubjectLabel className={styles.label} id={id} text="Discover More" />
    <h2 className={styles['section-heading']}>{heading}</h2>
    {typeof data === 'string' ? (
      <div>{parseChunks(data)}</div>
    ) : (
      data?.map(({ noteChunks }, index) => (
        <div className={styles.note} key={`${heading}-${index + 1}`}>
          <div>{parseChunks(noteChunks)}</div>
        </div>
      ))
    )}
  </section>
)

CommonContent.propTypes = {
  data: oneOfType([array, string]).isRequired,
  heading: string.isRequired,
  id: string.isRequired
}

export default withStyles(styles)(CommonContent)
