// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pzAb4KG141SuMsit5NFK>hr{margin:40px 0}`, "",{"version":3,"sources":["webpack://./src/client/components/composites/DictionaryResultsAnyClipVideo/DictionaryResultsAnyClipVideo.module.scss"],"names":[],"mappings":"AAAA,yBACE,aAAA","sourcesContent":[".root > hr {\n  margin: 40px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `pzAb4KG141SuMsit5NFK`
};
module.exports = ___CSS_LOADER_EXPORT___;
