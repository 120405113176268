// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.o6x7us0JIcFKY09D7bSC{all:unset;align-items:center;background-color:#fff;color:var(--darkgraytext);cursor:pointer;display:flex;font-size:32px;font-weight:bold;height:66px;justify-content:center;text-align:center}.o6x7us0JIcFKY09D7bSC:hover{background-color:var(--mildgray)}.KltVlA05hmj2lgKJQom3,.NDsXoBtmPU75nIo4TsHf{background-color:#cae0f5}`, "",{"version":3,"sources":["webpack://./src/client/components/composites/WordPuzzleLetterButton/WordPuzzleLetterButton.module.scss"],"names":[],"mappings":"AACA,sBACE,SAAA,CACA,kBAAA,CACA,qBAAA,CACA,yBAAA,CACA,cAAA,CACA,YAAA,CACA,cAAA,CACA,gBAAA,CACA,WAAA,CACA,sBAAA,CACA,iBAAA,CAEA,4BACE,gCAAA,CAIJ,4CAEE,wBAAA","sourcesContent":["/* stylelint-disable order/properties-alphabetical-order */\n.root {\n  all: unset;\n  align-items: center;\n  background-color: #fff;\n  color: var(--darkgraytext);\n  cursor: pointer;\n  display: flex;\n  font-size: 32px;\n  font-weight: bold;\n  height: 66px;\n  justify-content: center;\n  text-align: center;\n\n  &:hover {\n    background-color: var(--mildgray);\n  }\n}\n\n.active,\n.selected {\n  background-color: #cae0f5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `o6x7us0JIcFKY09D7bSC`,
	"active": `KltVlA05hmj2lgKJQom3`,
	"selected": `NDsXoBtmPU75nIo4TsHf`
};
module.exports = ___CSS_LOADER_EXPORT___;
