import React from 'react'
import { string } from 'prop-types'
import { PROPTYPE_CHILDREN } from '~client/constants'
import parseHtml from '~utils/parseHtml'

export const PronunciationText = ({ children, className }) => {
  return (
    <span className={className} data-type="pronunciation-text">
      {parseHtml(children)}
    </span>
  )
}

PronunciationText.propTypes = {
  children: PROPTYPE_CHILDREN.isRequired,
  className: string
}

PronunciationText.defaultProps = {
  className: null
}

export default PronunciationText
