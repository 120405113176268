import React from 'react'
import { func, string } from 'prop-types'
import cx from 'classnames'
import withStyles from 'isomorphic-style-loader/withStyles'
import { Button, Icon } from '~elements'
import styles from './IconButton.module.scss'

export const IconButton = ({ className, onClick, src, ...props }) => {
  const classNames = cx(styles.root, className)

  return (
    <Button
      className={classNames}
      data-type="icon-button"
      onClick={onClick}
      {...props}
    >
      <Icon className={styles.icon} src={src} />
    </Button>
  )
}

IconButton.propTypes = {
  className: string,
  onClick: func.isRequired,
  src: string.isRequired
}

IconButton.defaultProps = {
  className: null
}

export default withStyles(styles)(IconButton)
