// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sTvZTZi0cH9gHAwFH9aM{background-color:var(--teal)}.yEZYDvqRWmRcXuwkIXWe{background-color:var(--mildgray)}`, "",{"version":3,"sources":["webpack://./src/client/components/composites/WordPuzzleNotification/WordPuzzleNotification.module.scss"],"names":[],"mappings":"AAAA,sBACE,4BAAA,CAGF,sBACE,gCAAA","sourcesContent":[".valid {\n  background-color: var(--teal);\n}\n\n.invalid {\n  background-color: var(--mildgray);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"valid": `sTvZTZi0cH9gHAwFH9aM`,
	"invalid": `yEZYDvqRWmRcXuwkIXWe`
};
module.exports = ___CSS_LOADER_EXPORT___;
