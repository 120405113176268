// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lZcdt6jLY8wTTucfRiQ1{background:#fff;border:2px solid var(--thesaurusorange);border-radius:100px;color:var(--darkgraytext);cursor:pointer;font-size:16px;font-weight:normal;line-height:1;padding:6px 14px;position:relative;transition:all .25s ease-in-out 0s}.A1LGtjg3AxODKN2w_8QL{background-color:var(--thesaurusorange);color:#fff}`, "",{"version":3,"sources":["webpack://./src/client/components/composites/CompareMorePill/CompareMorePill.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,uCAAA,CACA,mBAAA,CACA,yBAAA,CACA,cAAA,CACA,cAAA,CACA,kBAAA,CACA,aAAA,CACA,gBAAA,CACA,iBAAA,CACA,kCAAA,CAGF,sBACE,uCAAA,CACA,UAAA","sourcesContent":[".root {\n  background: #fff;\n  border: 2px solid var(--thesaurusorange);\n  border-radius: 100px;\n  color: var(--darkgraytext);\n  cursor: pointer;\n  font-size: 16px;\n  font-weight: normal;\n  line-height: 1;\n  padding: calc(6px) calc(14px);\n  position: relative;\n  transition: all 0.25s ease-in-out 0s;\n}\n\n.selected {\n  background-color: var(--thesaurusorange);\n  color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `lZcdt6jLY8wTTucfRiQ1`,
	"selected": `A1LGtjg3AxODKN2w_8QL`
};
module.exports = ___CSS_LOADER_EXPORT___;
