// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.jEeE4pu3VPUa19aFG2fG{--gradient-height: 16px;background-color:#fff}div.jEeE4pu3VPUa19aFG2fG>button{background-color:#fff;color:var(--lightgray);font-size:12px;padding-top:0;position:relative;text-align:left;text-decoration:underline;width:auto}div.jEeE4pu3VPUa19aFG2fG>button:hover{color:var(--warmgray)}`, "",{"version":3,"sources":["webpack://./src/client/components/composites/SeeMoreInline/SeeMoreInline.module.scss"],"names":[],"mappings":"AAAA,yBACE,uBAAA,CAEA,qBAAA,CAEA,gCACE,qBAAA,CACA,sBAAA,CACA,cAAA,CACA,aAAA,CACA,iBAAA,CACA,eAAA,CACA,yBAAA,CACA,UAAA,CAEA,sCACE,qBAAA","sourcesContent":["div.root {\n  --gradient-height: 16px;\n\n  background-color: #fff;\n\n  & > button {\n    background-color: #fff;\n    color: var(--lightgray);\n    font-size: 12px;\n    padding-top: 0;\n    position: relative;\n    text-align: left;\n    text-decoration: underline;\n    width: auto;\n\n    &:hover {\n      color: var(--warmgray);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `jEeE4pu3VPUa19aFG2fG`
};
module.exports = ___CSS_LOADER_EXPORT___;
