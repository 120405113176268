import React, { useState } from 'react'
import { string } from 'prop-types'
import cx from 'classnames'
import withStyles from 'isomorphic-style-loader/withStyles'
import {
  DICTIONARY_URL_TERMS,
  EMAIL_VALIDATION_REGEX,
  MAILING_LIST_TERMS_COPY,
  MAILING_LIST_TERMS_LINK_COPY,
  PROPTYPE_MAILING_LISTS,
  SIGNUP_ERROR_MESSAGE
} from '~client/constants'
import { A, RedesignButton } from '~elements'
import addSignUpToDataLayer from '~utils/adobe-analytics/addSignUpToDataLayer'
import mailingListSignUp from '~utils/mailingListSignUp'
import styles from './MailingListSignUpForm.module.scss'

const INVALID_EMAIL_ERROR_MESSAGE = 'Please enter a valid email address.'

const DEFAULT_STATE = 'DEFAULT STATE'
const ERROR_STATE = 'ERROR STATE'
const SUCCESS_STATE = 'SUCCESS STATE'
const VALID_STATE = 'VALID STATE'

const reCaptchaApiKey = process.env.SAFE_RECAPTCHA_API_KEY

export const MailingListSignUpForm = ({
  dataLinkModule,
  errorMessage,
  formContext,
  mailingList
}) => {
  const dataLocation = dataLinkModule === 'newsletter' ? 'left-rail' : 'body'
  const [value, setValue] = useState('')
  const [status, setStatus] = useState(DEFAULT_STATE)
  const [message, setMessage] = useState('')

  const classNames = cx(styles.root, {
    [styles.newsletter]: formContext === 'newsletter'
  })

  const inputClassNames = cx({
    [styles.error]: status === ERROR_STATE,
    [styles.success]: status === SUCCESS_STATE
  })

  const validateEmail = email => EMAIL_VALIDATION_REGEX.test(email)

  const validateEmailUpdateMessage = () => {
    const isEmailValid = validateEmail(value)

    if (isEmailValid) {
      setStatus(VALID_STATE)
      setMessage('')
      return
    }

    setStatus(ERROR_STATE)
    setMessage(INVALID_EMAIL_ERROR_MESSAGE)
  }

  const onChange = ({ target: { value } }) => {
    setValue(value)

    const isEmailValid = validateEmail(value)

    if (isEmailValid) {
      setStatus(VALID_STATE)
      setMessage('')
      return
    }
    setStatus(DEFAULT_STATE)
    setMessage('')
  }

  const onBlur = () => {
    validateEmailUpdateMessage()
  }

  const onKeyDown = ({ key }) => {
    if (key === 'Enter') {
      validateEmailUpdateMessage()
    }
  }

  const onFocus = () => {
    setMessage('')
  }

  const onSubmit = e => {
    e.preventDefault()

    const isEmailValid = validateEmail(value)

    if (!isEmailValid) {
      setStatus(ERROR_STATE)
      setMessage(errorMessage)
      return
    }
    window.grecaptcha.enterprise.ready(function () {
      window.grecaptcha.enterprise
        .execute(reCaptchaApiKey, { action: 'submit' })
        .then(function (token) {
          addSignUpToDataLayer(formContext)

          mailingListSignUp({
            email: value,
            error: () => {
              setStatus(ERROR_STATE)
              setMessage(SIGNUP_ERROR_MESSAGE)
            },
            mailingList,
            success: () => {
              setStatus(SUCCESS_STATE)
            },
            token
          })
        })
    })
  }

  return (
    <div className={classNames} data-type="mailing-list-sign-up-form">
      <form onSubmit={onSubmit}>
        <div className={styles['input-container']}>
          <input
            aria-autocomplete="list"
            autoCapitalize="none"
            autoComplete="on"
            autoCorrect="off"
            className={inputClassNames}
            name="sign-up"
            onBlur={onBlur}
            onChange={onChange}
            onFocus={onFocus}
            onKeyDown={onKeyDown}
            placeholder="Enter your email address"
            spellCheck="false"
            type="text"
            value={value}
          />
          {message && <p className={styles['helper-text']}>{message}</p>}
        </div>
        <RedesignButton
          data-analytics="aa-track"
          data-linklocation={dataLocation}
          data-linkmodule={dataLinkModule}
          data-linkname="sign-up"
          data-linktype="cta"
          data-linkurl="action:email-submit"
          data-type="sign-up"
          disabled={status !== VALID_STATE && status !== SUCCESS_STATE}
          success={status === SUCCESS_STATE}
          type="submit"
        >
          Sign Up
        </RedesignButton>
      </form>
      <p className={styles['terms-copy']}>
        {`${MAILING_LIST_TERMS_COPY} `}
        <A className={styles['terms-link']} href={DICTIONARY_URL_TERMS}>
          {MAILING_LIST_TERMS_LINK_COPY}
        </A>
      </p>
    </div>
  )
}

MailingListSignUpForm.propTypes = {
  dataLinkModule: string,
  errorMessage: string,
  formContext: string.isRequired,
  mailingList: PROPTYPE_MAILING_LISTS.isRequired
}

MailingListSignUpForm.defaultProps = {
  dataLinkModule: 'newsletter',
  errorMessage: SIGNUP_ERROR_MESSAGE
}

export default withStyles(styles)(MailingListSignUpForm)
