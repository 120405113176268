// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Ptch5NpYBO3voBG_IRiT{background-color:#fff;margin-bottom:24px;max-width:1200px;padding:24px}.Ptch5NpYBO3voBG_IRiT h2{color:var(--darkgraytext);font-size:16px;line-height:1.15;margin:0;padding:0}.Ptch5NpYBO3voBG_IRiT p{color:var(--lightgray);font-size:16px;line-height:1.5}`, "",{"version":3,"sources":["webpack://./src/client/components/composites/WordComparisonEditorial/WordComparisonEditorial.module.scss"],"names":[],"mappings":"AAAA,sBACE,qBAAA,CACA,kBAAA,CACA,gBAAA,CACA,YAAA,CAEA,yBACE,yBAAA,CACA,cAAA,CACA,gBAAA,CACA,QAAA,CACA,SAAA,CAGF,wBACE,sBAAA,CACA,cAAA,CACA,eAAA","sourcesContent":[".root {\n  background-color: #fff;\n  margin-bottom: 24px;\n  max-width: 1200px;\n  padding: 24px;\n\n  h2 {\n    color: var(--darkgraytext);\n    font-size: 16px;\n    line-height: 1.15;\n    margin: 0;\n    padding: 0;\n  }\n\n  p {\n    color: var(--lightgray);\n    font-size: 16px;\n    line-height: 1.5;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `Ptch5NpYBO3voBG_IRiT`
};
module.exports = ___CSS_LOADER_EXPORT___;
