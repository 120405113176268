import React, { useState } from 'react'
import { string } from 'prop-types'
import cx from 'classnames'
import withStyles from 'isomorphic-style-loader/withStyles'
import { PronunciationText } from '~composites'
import { Button } from '~elements'
import styles from './PronunciationToggle.module.scss'

export const PronunciationToggle = ({ className, ipa, spelling }) => {
  const classNames = cx(styles.root, className)
  const [showSpelling, setShowSpelling] = useState(true)
  const showSpellingToggle = () => setShowSpelling(!showSpelling)

  const content = showSpelling ? `[ ${spelling} ]` : `/ ${ipa} /`
  const buttonText = showSpelling ? 'show ipa' : 'phonetic respelling'

  return (
    <div className={classNames} data-type="pronunciation-toggle">
      <PronunciationText className={styles.content}>
        {content}
      </PronunciationText>
      <Button onClick={showSpellingToggle}>{buttonText}</Button>
    </div>
  )
}

PronunciationToggle.propTypes = {
  className: string,
  ipa: string.isRequired,
  spelling: string.isRequired
}

PronunciationToggle.defaultProps = {
  className: null
}

export default withStyles(styles)(PronunciationToggle)
