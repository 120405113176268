// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Qt8mDQjMvFnAPD8HPtDg{font-weight:bold}`, "",{"version":3,"sources":["webpack://./src/client/components/composites/MiniCrosswordContent/MiniCrosswordContent.module.scss"],"names":[],"mappings":"AAAA,sBACE,gBAAA","sourcesContent":[".cta {\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cta": `Qt8mDQjMvFnAPD8HPtDg`
};
module.exports = ___CSS_LOADER_EXPORT___;
