// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ZmI8Sf0DTBDqQFvv0zY5{align-items:center;background:#fff;border:1px solid var(--mildgray);border-radius:3px;color:var(--darkgraytext);display:flex;font-size:16px;height:58px;margin:0 0 8px;padding:16px;text-align:left;width:100%}.RPKoG0eccSOWQlSFaerh{background-color:var(--teal)}.MIfYni8ZNAWjSVavsAsx{border:2px solid var(--error)}`, "",{"version":3,"sources":["webpack://./src/client/components/composites/QuizOption/QuizOption.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,eAAA,CACA,gCAAA,CACA,iBAAA,CACA,yBAAA,CACA,YAAA,CACA,cAAA,CACA,WAAA,CACA,cAAA,CACA,YAAA,CACA,eAAA,CACA,UAAA,CAGF,sBACE,4BAAA,CAGF,sBACE,6BAAA","sourcesContent":[".root {\n  align-items: center;\n  background: #fff;\n  border: 1px solid var(--mildgray);\n  border-radius: 3px;\n  color: var(--darkgraytext);\n  display: flex;\n  font-size: 16px;\n  height: 58px;\n  margin: 0 0 8px;\n  padding: 16px;\n  text-align: left;\n  width: 100%;\n}\n\n.correct {\n  background-color: var(--teal);\n}\n\n.wrong {\n  border: 2px solid var(--error);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `ZmI8Sf0DTBDqQFvv0zY5`,
	"correct": `RPKoG0eccSOWQlSFaerh`,
	"wrong": `MIfYni8ZNAWjSVavsAsx`
};
module.exports = ___CSS_LOADER_EXPORT___;
