import React from 'react'
import withStyles from 'isomorphic-style-loader/withStyles'
import { useSelector } from 'react-redux'
import { ResultsPageNavigationItems } from '~client/constants'
import { SubjectLabel } from '~elements'
import { selectDerivedForms } from '~store/lexigraph/selectors'
import isArrayWithLength from '~utils/isArrayWithLength'
import styles from './DerivedForms.module.scss'

const DerivedForms = () => {
  const derivedForms = useSelector(selectDerivedForms)

  if (!isArrayWithLength(derivedForms)) return null

  return (
    <section className={styles.root} data-type="derived-forms">
      <SubjectLabel
        className={styles.label}
        id={ResultsPageNavigationItems.DERIVED_FORMS.id}
        text="Discover More"
      />
      <h2 className={styles['section-heading']}>
        {ResultsPageNavigationItems.DERIVED_FORMS.label}
      </h2>
      <ul>
        {derivedForms.map(({ headword: { displayForm }, partsOfSpeech }) => (
          <li key={`${displayForm}-${partsOfSpeech}`}>
            <strong>{displayForm}</strong>, <span>{partsOfSpeech}</span>
          </li>
        ))}
      </ul>
    </section>
  )
}

export default withStyles(styles)(DerivedForms)
