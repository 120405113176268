// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qEijW2cM8ic9OlhtmKw4{background-color:rgba(0,0,0,0);border:none;display:inline}.qEijW2cM8ic9OlhtmKw4:hover{transform:scale(1.1)}.qTSNrGd2383z9o80nPmu{cursor:pointer;padding:0}`, "",{"version":3,"sources":["webpack://./src/client/components/composites/PronunciationAudio/PronunciationAudio.module.scss"],"names":[],"mappings":"AAAA,sBACE,8BAAA,CACA,WAAA,CACA,cAAA,CAEA,4BACE,oBAAA,CAIJ,sBACE,cAAA,CACA,SAAA","sourcesContent":[".root {\n  background-color: transparent;\n  border: none;\n  display: inline;\n\n  &:hover {\n    transform: scale(1.1);\n  }\n}\n\n.icon {\n  cursor: pointer;\n  padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `qEijW2cM8ic9OlhtmKw4`,
	"icon": `qTSNrGd2383z9o80nPmu`
};
module.exports = ___CSS_LOADER_EXPORT___;
