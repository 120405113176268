import React from 'react'
import { object } from 'prop-types'
import { WordPuzzleModalContent } from '~composites'
import { Modal } from '~elements'
import styles from './WordPuzzleModal.module.scss'

const WordPuzzleModal = ({ modalState }) => {
  const closeModal = () => modalState.toggle()

  return (
    <Modal
      className={styles.root}
      data-type="compare-more-modal"
      heading="How to Play"
      modalState={modalState}
    >
      <WordPuzzleModalContent closeModal={closeModal} />
    </Modal>
  )
}

WordPuzzleModal.propTypes = {
  modalState: object.isRequired
}

export default WordPuzzleModal
