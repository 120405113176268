// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.yWHGFoK8LXISHjMSiHkB{background-color:var(--thesaurusorange);border:rgba(0,0,0,0);color:#fff}.yWHGFoK8LXISHjMSiHkB:hover,.yWHGFoK8LXISHjMSiHkB:visited{color:#fff}.xoRK77XeI1M5vlD3MpA3{background-color:var(--thesaurusorange)}.EbxqYzbhUlJDXHfR5bDK{background-color:#ffb600;color:var(--darkgraytext)}.TuEvZ0LSHVrjSVQbi0A8{background-color:var(--orange300);color:var(--darkgraytext)}`, "",{"version":3,"sources":["webpack://./src/client/components/composites/SynonymPill/SynonymPill.module.scss"],"names":[],"mappings":"AAAA,sBACE,uCAAA,CACA,oBAAA,CACA,UAAA,CAEA,0DAEE,UAAA,CAIJ,sBACE,uCAAA,CAGF,sBACE,wBAAA,CACA,yBAAA,CAGF,sBACE,iCAAA,CACA,yBAAA","sourcesContent":[".root {\n  background-color: var(--thesaurusorange);\n  border: transparent;\n  color: #fff;\n\n  &:hover,\n  &:visited {\n    color: #fff;\n  }\n}\n\n.similarity-100 {\n  background-color: var(--thesaurusorange);\n}\n\n.similarity-10 {\n  background-color: #ffb600;\n  color: var(--darkgraytext);\n}\n\n.similarity-50 {\n  background-color: var(--orange300);\n  color: var(--darkgraytext);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `yWHGFoK8LXISHjMSiHkB`,
	"similarity-100": `xoRK77XeI1M5vlD3MpA3`,
	"similarity-10": `EbxqYzbhUlJDXHfR5bDK`,
	"similarity-50": `TuEvZ0LSHVrjSVQbi0A8`
};
module.exports = ___CSS_LOADER_EXPORT___;
