import { createSelector } from '@reduxjs/toolkit'
import { BROWSE_RESULTS_PER_PAGE } from '~store/lexigraph/slice'
import isArrayWithLength from '~utils/isArrayWithLength'
import isObjectWithProperties from '~utils/isObjectWithProperties'

const EMPTY_ARRAY = []
const EMPTY_OBJECT = {}
const MAX_RELATED_WORDS = 20

export const selectDictionaryData = state =>
  state.lexigraph?.dictionaryData?.data || EMPTY_OBJECT
export const selectThesaurusData = state =>
  state.lexigraph?.thesaurusData?.data || EMPTY_OBJECT
export const selectThesaurusCompareSynonymsData = state =>
  state.lexigraph?.thesaurusCompareSynonymsData?.data?.compareSynonyms ||
  EMPTY_OBJECT
export const selectThesaurusRelatedWordsData = state =>
  state.lexigraph?.thesaurusRelatedWordsData?.data?.relatedWords?.results ||
  EMPTY_ARRAY
export const selectDictionaryBrowseByLetterData = state =>
  state.lexigraph?.dictionaryBrowseByLetterData?.data || EMPTY_OBJECT;
export const selectThesaurusBrowseByLetterData = state =>
  state.lexigraph?.thesaurusBrowseByLetterData?.data || EMPTY_OBJECT;

/* Dictionary selectors */
export const selectDictionaryEntries = createSelector(
  selectDictionaryData,
  dictionaryData =>
    dictionaryData.slugs?.[0]?.entries
      ?.filter(({ __typename }) => __typename === 'Entry')
      ?.sort((a, b) => a.orderKey - b.orderKey) || EMPTY_ARRAY
)

export const selectBritishEntries = createSelector(
  selectDictionaryData,
  dictionaryData =>
    dictionaryData.slugs?.[0]?.entries?.filter(
      ({ __typename }) => __typename === 'CollinsEntry'
    ) || EMPTY_ARRAY
)

export const selectScienceEntries = createSelector(
  selectDictionaryData,
  dictionaryData =>
    dictionaryData.slugs?.[0]?.entries?.filter(
      ({ __typename }) => __typename === 'ScienceEntry'
    ) || EMPTY_ARRAY
)

export const selectCulturalEntries = createSelector(
  selectDictionaryData,
  dictionaryData =>
    dictionaryData.slugs?.[0]?.entries?.filter(
      ({ __typename }) => __typename === 'CulturalEntry'
    ) || EMPTY_ARRAY
)

export const selectIdiomsEntries = createSelector(
  selectDictionaryData,
  dictionaryData =>
    dictionaryData.slugs?.[0]?.entries?.filter(
      ({ __typename }) => __typename === 'IdiomsEntry'
    ) || EMPTY_ARRAY
)

export const selectAllDictionaryEntries = createSelector(
  selectDictionaryEntries,
  selectBritishEntries,
  selectScienceEntries,
  selectCulturalEntries,
  selectIdiomsEntries,
  (
    dictionaryEntries,
    britishEntries,
    scienceEntries,
    culturalEntries,
    idiomsEntries
  ) => [
    ...dictionaryEntries,
    ...britishEntries,
    ...scienceEntries,
    ...culturalEntries,
    ...idiomsEntries
  ]
)

export const selectHasDictionaryData = createSelector(
  selectAllDictionaryEntries,
  entries => entries.some(isObjectWithProperties)
)

export const selectDictionaryThesaurusEntry = createSelector(
  selectDictionaryData,
  dictionaryData =>
    dictionaryData.slugs?.[0]?.entries.find(
      ({ __typename }) => __typename === 'ThesaurusEntry'
    )
)

export const selectHasThesaurusData = createSelector(
  selectDictionaryThesaurusEntry,
  entry => isObjectWithProperties(entry)
)

export const selectConfusablesNote = createSelector(
  selectDictionaryEntries,
  entries => {
    const entryWithConfusablesNote = entries.find(entry =>
      isArrayWithLength(entry.confusableNotes)
    )
    return entryWithConfusablesNote?.confusableNotes || EMPTY_ARRAY
  }
)

export const selectDerivedForms = createSelector(
  selectBritishEntries,
  entries => entries.flatMap(({ subEntry }) => subEntry)
)

export const selectIdioms = createSelector(selectDictionaryEntries, entries => {
  return entries
    .filter(entry => isArrayWithLength(entry.idioms))
    .flatMap(entry => entry.idioms)
})

export const selectMoreIdioms = createSelector(
  selectDictionaryData,
  dictionaryData => {
    const moreIdioms = (dictionaryData.slugs?.[0]?.entries || EMPTY_ARRAY).find(
      ({ __typename }) => __typename === 'IdiomsEntry'
    )
    return moreIdioms?.descriptionChunks || ''
  }
)

export const selectSynonymStudies = createSelector(
  selectDictionaryEntries,
  entries => {
    return entries
      .filter(entry => isArrayWithLength(entry.synonymStudy))
      .flatMap(entry => entry.synonymStudy)
  }
)

export const selectUsageAlerts = createSelector(
  selectDictionaryEntries,
  entries => {
    return entries
      .filter(entry => isArrayWithLength(entry.usageAlerts))
      .flatMap(entry => entry.usageAlerts)
  }
)

export const selectUsageNote = createSelector(
  selectDictionaryEntries,
  entries => {
    const entryWithUsageNote = entries.find(entry =>
      isArrayWithLength(entry.usageNotes)
    )
    return entryWithUsageNote?.usageNotes || EMPTY_ARRAY
  }
)

export const selectGenderNote = createSelector(
  selectDictionaryEntries,
  entries => {
    const entryWithUsageNote = entries.find(entry =>
      isArrayWithLength(entry.genderNotes)
    )
    return entryWithUsageNote?.genderNotes || EMPTY_ARRAY
  }
)

export const selectSensitiveNote = createSelector(
  selectDictionaryEntries,
  entries => {
    const entryWithUsageNote = entries.find(entry =>
      isArrayWithLength(entry.sensitiveNotes)
    )
    return entryWithUsageNote?.sensitiveNotes || EMPTY_ARRAY
  }
)

export const selectSpellingNote = createSelector(
  selectDictionaryEntries,
  entries => {
    const entryWithUsageNote = entries.find(entry =>
      isArrayWithLength(entry.spellingNotes)
    )
    return entryWithUsageNote?.spellingNotes || EMPTY_ARRAY
  }
)

export const selectBritishNote = createSelector(selectBritishEntries, entries =>
  entries
    .filter(({ note }) => isObjectWithProperties(note))
    .flatMap(({ note }) => note)
)

export const selectCulturalNote = createSelector(
  selectCulturalEntries,
  entries => {
    const entryWithCulturalNote = entries.find(entry =>
      isArrayWithLength(entry.notes)
    )
    return entryWithCulturalNote?.notes || EMPTY_ARRAY
  }
)

export const selectGrammarNote = createSelector(
  selectDictionaryEntries,
  entries => {
    const entryWithGrammarNote = entries.find(entry =>
      isArrayWithLength(entry.grammarNotes)
    )
    return entryWithGrammarNote?.grammarNotes || EMPTY_ARRAY
  }
)

export const selectScienceNote = createSelector(
  selectScienceEntries,
  entries => {
    const entryWithNote = entries.find(entry =>
      isObjectWithProperties(entry.note)
    )
    return entryWithNote?.note || EMPTY_OBJECT
  }
)

export const selectOtherWordsFrom = createSelector(
  selectDictionaryEntries,
  entries => {
    const entryWithOtherWordsFrom = entries.find(entry =>
      isArrayWithLength(entry.relatedFormSets)
    )
    return entryWithOtherWordsFrom?.relatedFormSets || EMPTY_ARRAY
  }
)

export const selectDictionaryOrigins = createSelector(
  selectDictionaryEntries,
  entries => entries.filter(entry => entry.origin?.originChunks)
)

export const selectBritishOrigins = createSelector(
  selectBritishEntries,
  entries => entries.filter(entry => entry.origin?.originChunks)
)

export const selectPronunciationNote = createSelector(
  selectDictionaryEntries,
  entries => {
    const entryWithPronunciationNote = entries.find(entry =>
      isArrayWithLength(entry.pronunciationNotes)
    )
    return entryWithPronunciationNote?.pronunciationNotes || EMPTY_ARRAY
  }
)

export const selectDictionaryHeadword = createSelector(
  selectAllDictionaryEntries,
  entries => {
    const headword = entries?.find(({ headword }) => headword)?.headword
    return headword || EMPTY_OBJECT
  }
)

export const selectDictionaryRelatedWordsData = createSelector(
  selectDictionaryThesaurusEntry,
  entry =>
    entry?.partOfSpeechGroups?.[0]?.shortDefinitions
      ?.map(({ synonyms }) =>
        synonyms?.filter(synonym => synonym?.similarity === 100)
      )
      .reduce(
        (mostSynonyms, currentSynonyms) =>
          mostSynonyms?.length < currentSynonyms?.length
            ? currentSynonyms
            : mostSynonyms,
        EMPTY_ARRAY
      )
      .slice(0, MAX_RELATED_WORDS)
)

/* Thesaurus selectors */

export const selectThesaurusEntries = createSelector(
  selectThesaurusData,
  thesaurusData =>
    (thesaurusData.slugs?.[0]?.entries || EMPTY_ARRAY).filter(
      entry => entry?.__typename === 'ThesaurusEntry'
    )
)

export const selectDisplayForm = createSelector(
  selectThesaurusEntries,
  entries => entries?.[0]?.headword?.displayForm
)

export const selectStandardForm = createSelector(
  selectThesaurusEntries,
  entries => entries?.[0]?.headword?.standardForm
)

export const selectSynonymAntonymDefinitions = createSelector(
  selectThesaurusEntries,
  entries =>
    (entries[0]?.partOfSpeechGroups || EMPTY_ARRAY).flatMap(
      ({ partOfSpeech, shortDefinitions }) =>
        (shortDefinitions || EMPTY_ARRAY).map(shortDefinition => ({
          ...shortDefinition,
          partOfSpeech,
          shortDef: shortDefinition.shortDef?.replace(/as in /gi, '')
        }))
    )
)

export const selectAudioSrc = createSelector(
  selectThesaurusData,
  thesaurusData => {
    const entryWithAudioPronuncitions = (
      thesaurusData.slugs?.[0]?.entries || EMPTY_ARRAY
    ).find(entry => Object.hasOwn(entry, 'audioPronunciations'))

    return entryWithAudioPronuncitions?.audioPronunciations?.[0]?.mpeg
  }
)

/* Browse by letter selectors */

export const selectDictionaryBrowseByLetterResult = createSelector(
  selectDictionaryBrowseByLetterData,
  dictionaryBrowseByLetterData => {
    const totalResults = dictionaryBrowseByLetterData?.slugsByStartingLetterResult?.total || 0;
    const totalPages = totalResults > 0
      ? Math.ceil(totalResults / BROWSE_RESULTS_PER_PAGE)
      : 0;
    return {...dictionaryBrowseByLetterData?.slugsByStartingLetterResult, totalPages};
  }
);

export const selectThesaurusBrowseByLetterResult = createSelector(
  selectThesaurusBrowseByLetterData,
  thesaurusBrowseByLetterData => {
    const totalResults = thesaurusBrowseByLetterData?.slugsByStartingLetterResult?.total || 0;
    const totalPages = totalResults > 0
      ? Math.ceil(totalResults / BROWSE_RESULTS_PER_PAGE)
      : 0;
    return {...thesaurusBrowseByLetterData?.slugsByStartingLetterResult, totalPages};
  }
);
