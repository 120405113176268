// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cs30rl9RI3fBcNDr3Hhc{display:flex;justify-content:center;padding-bottom:12px;padding-top:4px}.cs30rl9RI3fBcNDr3Hhc ul{font-family:Helvetica-Bold,sans-serif;font-size:14px;list-style-type:none;margin:0;overflow:hidden;padding:0;text-align:center}`, "",{"version":3,"sources":["webpack://./src/client/components/composites/BottomPaging/BottomPaging.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,sBAAA,CACA,mBAAA,CACA,eAAA,CAEA,yBACE,qCAAA,CACA,cAAA,CACA,oBAAA,CACA,QAAA,CACA,eAAA,CACA,SAAA,CACA,iBAAA","sourcesContent":[".bottom-paging {\n  display: flex;\n  justify-content: center;\n  padding-bottom: 12px;\n  padding-top: 4px;\n\n  ul {\n    font-family: Helvetica-Bold, sans-serif;\n    font-size: 14px;\n    list-style-type: none;\n    margin: 0;\n    overflow: hidden;\n    padding: 0;\n    text-align: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bottom-paging": `cs30rl9RI3fBcNDr3Hhc`
};
module.exports = ___CSS_LOADER_EXPORT___;
