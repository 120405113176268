import React from 'react'
import { array, object } from 'prop-types'
import cx from 'classnames'
import withStyles from 'isomorphic-style-loader/withStyles'
import { Accordion } from '~elements'
import styles from './QuizSummary.module.scss'

const QuizSummary = ({ answers, questions, selectedAnswers }) => {
  const questionList = questions.map((question, index) => {
    let isCorrect = false
    const correct = answers[question.questionID]
    let correctAnswer = ''
    const selected = selectedAnswers[index].optionID
    const selectedAnswer = selectedAnswers[index].option
    const options = question.options

    if (correct === selected) {
      isCorrect = true
    }

    options.forEach(option => {
      if (option.optionID === correct) {
        correctAnswer = option.option
      }
    })

    const classNames = cx(styles['answer-border'], {
      [styles.correct]: isCorrect,
      [styles.wrong]: !isCorrect
    })

    return (
      <li key={question.questionID}>
        <div className={styles['question-details']}>
          <div className={styles.question}>{question.question}</div>
          <div className={classNames}>
            <span className={styles['answer-icon']} />
          </div>
        </div>
        {!isCorrect && (
          <div className={styles.answer}>
            <p>
              <strong>Correct Answer:</strong>
              <br />
              {correctAnswer}
            </p>
          </div>
        )}
        <div className={styles.answer}>
          <p>
            <strong>Your Answer:</strong>
            <br />
            {selectedAnswer}
          </p>
        </div>
      </li>
    )
  })

  return (
    <div className={styles.root} data-type="quiz-summary">
      <Accordion defaultIsOpen={false} headline="Summary">
        <ol>{questionList}</ol>
      </Accordion>
    </div>
  )
}

QuizSummary.propTypes = {
  answers: object.isRequired,
  questions: array.isRequired,
  selectedAnswers: array.isRequired
}

export default withStyles(styles)(QuizSummary)
