// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.emHYTa6VJvARBmi8J1mZ h2{color:var(--darkgraytext);font-size:16px;font-weight:bold;margin:0 0 14px;text-transform:uppercase}.emHYTa6VJvARBmi8J1mZ ul{list-style-type:none;margin:0;padding-left:0}.emHYTa6VJvARBmi8J1mZ li{display:inline-block;margin-right:5px}.emHYTa6VJvARBmi8J1mZ li:not(:last-child)::after{content:", "}.emHYTa6VJvARBmi8J1mZ a{color:var(--dictionaryblue)}.emHYTa6VJvARBmi8J1mZ a:hover{text-decoration:none}.JngGuQbVbNMAc0WpsulG{font-size:20px;line-height:23px;word-break:break-all}`, "",{"version":3,"sources":["webpack://./src/client/components/composites/WordsNearbyCard/WordsNearbyCard.module.scss"],"names":[],"mappings":"AACE,yBACE,yBAAA,CACA,cAAA,CACA,gBAAA,CACA,eAAA,CACA,wBAAA,CAGF,yBACE,oBAAA,CACA,QAAA,CACA,cAAA,CAGF,yBACE,oBAAA,CACA,gBAAA,CAEA,iDACE,YAAA,CAIJ,wBACE,2BAAA,CAEA,8BACE,oBAAA,CAKN,sBACE,cAAA,CACA,gBAAA,CACA,oBAAA","sourcesContent":[".root {\n  h2 {\n    color: var(--darkgraytext);\n    font-size: 16px;\n    font-weight: bold;\n    margin: 0 0 14px;\n    text-transform: uppercase;\n  }\n\n  ul {\n    list-style-type: none;\n    margin: 0;\n    padding-left: 0;\n  }\n\n  li {\n    display: inline-block;\n    margin-right: 5px;\n\n    &:not(:last-child)::after {\n      content: ', ';\n    }\n  }\n\n  a {\n    color: var(--dictionaryblue);\n\n    &:hover {\n      text-decoration: none;\n    }\n  }\n}\n\n.entry-word {\n  font-size: 20px;\n  line-height: 23px;\n  word-break: break-all;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `emHYTa6VJvARBmi8J1mZ`,
	"entry-word": `JngGuQbVbNMAc0WpsulG`
};
module.exports = ___CSS_LOADER_EXPORT___;
