import React, { useEffect, useState } from 'react'
import { array, number, object, string } from 'prop-types'
import cx from 'classnames'
import withStyles from 'isomorphic-style-loader/withStyles'
import { SYNONYM_ANTONYM_WORD_STRENGTHS } from '~client/constants'
import { ModalContent, ModalFooter, ModalRedesign } from '~elements'
import isArrayWithLength from '~utils/isArrayWithLength'
import pluralize from '~utils/pluralize'
import styles from './CompareSynonymsModal.module.scss'

const MAX_SELECTABLE_SYNONYMS = 3

const CompareSynonymsModal = ({
  definition,
  modalState,
  ordinal,
  pos,
  rootDisplayForm,
  synonyms
}) => {
  const [selectedSynonymsList, setSelectedSynonymsList] = useState([])

  useEffect(() => {
    if (!modalState.open) {
      setSelectedSynonymsList([])
    }
  }, [modalState])

  if (!isArrayWithLength(synonyms)) return null

  const handleWordClick = slug => {
    const isSelectedSynonym = selectedSynonymsList.includes(slug)

    if (
      selectedSynonymsList.length === MAX_SELECTABLE_SYNONYMS &&
      !isSelectedSynonym
    ) {
      return
    }

    if (isSelectedSynonym) {
      const newList = selectedSynonymsList.filter(word => word !== slug)
      setSelectedSynonymsList(newList)
    } else {
      setSelectedSynonymsList([...selectedSynonymsList, slug])
    }
  }

  const handleCompareSynonymsClick = () => {
    const uriRootDisplayForm = encodeURIComponent(rootDisplayForm)
    const compareSynonymsUrl = `/compare-synonyms?root=${uriRootDisplayForm}&compare=${selectedSynonymsList.join(
      ','
    )}&pos=${pos}&ordinal=${ordinal}`
    window.location.href = compareSynonymsUrl
  }

  const wordStrengthMap = synonyms.reduce((wordStrength, word) => {
    const key = String(word.similarity).replace(/-/, '')
    if (!wordStrength[key]) {
      wordStrength[key] = []
    }
    wordStrength[key].push(word)
    return wordStrength
  }, {})

  const groupContent = words =>
    words.map(({ similarity, targetWord }) => {
      const buttonClassNames = cx(
        styles[`s${String(similarity).replace(/-/, '')}`],
        {
          [styles.selected]: selectedSynonymsList.includes(targetWord)
        }
      )

      return (
        <button
          className={buttonClassNames}
          key={targetWord}
          onClick={() => {
            handleWordClick(targetWord)
          }}
        >
          {targetWord}
        </button>
      )
    })

  const buttonLabel = `Compare ${
    selectedSynonymsList.length ? `${selectedSynonymsList.length} ` : ''
  }${pluralize(selectedSynonymsList.length, 'Synonym')}`

  const modalContent = SYNONYM_ANTONYM_WORD_STRENGTHS.map(
    ({ label, value }) => {
      const words = wordStrengthMap[value]

      if (!isArrayWithLength(words)) return null

      const wordStrengthTitle = `${label} ${pluralize(
        words.length,
        'match',
        'matches'
      )}`

      return (
        <div className={styles['word-group-container']} key={label}>
          <p className={styles['word-strength-title']}>{wordStrengthTitle}</p>
          <div className={styles['word-group']}>{groupContent(words)}</div>
        </div>
      )
    }
  )

  return (
    <ModalRedesign
      className={styles.root}
      data-type="compare-synonyms-modal"
      heading={`Select up to ${MAX_SELECTABLE_SYNONYMS} synonyms to compare:`}
      modalState={modalState}
    >
      <div className={styles['headword-container']}>
        <p>Synonyms for</p>
        <p>
          {rootDisplayForm}
          <span>{` (${pos} as in ${definition})`}</span>
        </p>
      </div>
      <ModalContent className={styles['modal-content']}>
        {modalContent}
      </ModalContent>
      <ModalFooter className={styles['modal-footer']}>
        <button
          disabled={selectedSynonymsList.length < 2}
          onClick={handleCompareSynonymsClick}
        >
          {buttonLabel}
        </button>
      </ModalFooter>
    </ModalRedesign>
  )
}

CompareSynonymsModal.propTypes = {
  definition: string.isRequired,
  modalState: object.isRequired,
  ordinal: number.isRequired,
  pos: string.isRequired,
  rootDisplayForm: string.isRequired,
  synonyms: array.isRequired
}

export default withStyles(styles)(CompareSynonymsModal)
