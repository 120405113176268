import React from 'react'
import { array } from 'prop-types'
import withStyles from 'isomorphic-style-loader/withStyles'
import { useSelector } from 'react-redux'
import { selectIsMobile } from '~store/userContext/selectors'
import styles from './WordPuzzleWordsFound.module.scss'

const WordPuzzleWordsFound = ({ foundWords }) => {
  const isMobile = useSelector(selectIsMobile)
  const totalWordsFound = foundWords.length
  const words = foundWords.map(word => <li key={word}>{word}</li>)

  if (totalWordsFound > 0) {
    return (
      <div className={styles['words-found']}>
        <h4>WORDS FOUND</h4>
        <ul>{words}</ul>
      </div>
    )
  }

  return (
    <div className={styles['game-instructions']}>
      <h4>HOW TO PLAY</h4>
      <ul>
        <li>Create words by selecting the letters provided.</li>
        {!isMobile && (
          <li>
            You can use your mouse or keyboard to select letters and submit
            words.
          </li>
        )}
        <li>Letters can only be used once per word.</li>
      </ul>
    </div>
  )
}

WordPuzzleWordsFound.propTypes = {
  foundWords: array.isRequired
}

export default withStyles(styles)(WordPuzzleWordsFound)
