// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dA5ZT_HBIEHe7rBIxGFD{align-items:center;background-color:#fff;border:none;border-radius:100px;display:flex;font-size:16px;font-weight:bold;margin:0;width:max-content}.dA5ZT_HBIEHe7rBIxGFD:hover{transform:scale(1.05)}.aQZIq4boovu42uSwdHA7{border:2px solid var(--thesaurusorange);height:40px;padding:4px 18px 4px 8px}.ptgO2fQn5NH8K7mHHiJ3{height:22px;line-height:1.15;margin:0 6px 0 8px;transform:rotate(450deg);width:18px}`, "",{"version":3,"sources":["webpack://./src/client/components/composites/CompareMoreButton/CompareMoreButton.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,qBAAA,CACA,WAAA,CACA,mBAAA,CACA,YAAA,CACA,cAAA,CACA,gBAAA,CACA,QAAA,CACA,iBAAA,CAEA,4BACE,qBAAA,CAIJ,sBACE,uCAAA,CACA,WAAA,CACA,wBAAA,CAGF,sBACE,WAAA,CACA,gBAAA,CACA,kBAAA,CACA,wBAAA,CACA,UAAA","sourcesContent":[".root {\n  align-items: center;\n  background-color: #fff;\n  border: none;\n  border-radius: 100px;\n  display: flex;\n  font-size: 16px;\n  font-weight: bold;\n  margin: 0;\n  width: max-content;\n\n  &:hover {\n    transform: scale(1.05);\n  }\n}\n\n.tcom {\n  border: 2px solid var(--thesaurusorange);\n  height: 40px;\n  padding: 4px 18px 4px 8px;\n}\n\n.icon {\n  height: 22px;\n  line-height: 1.15;\n  margin: 0 6px 0 8px;\n  transform: rotate(450deg);\n  width: 18px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `dA5ZT_HBIEHe7rBIxGFD`,
	"tcom": `aQZIq4boovu42uSwdHA7`,
	"icon": `ptgO2fQn5NH8K7mHHiJ3`
};
module.exports = ___CSS_LOADER_EXPORT___;
