import React from 'react'
import withStyles from 'isomorphic-style-loader/withStyles'
import { A } from '~elements'
import useGamesUrl from '~hooks/useGamesUrl'
import styles from './WordWipeContent.module.scss'

const WordWipeContent = () => {
  const { allGamesUrl } = useGamesUrl()

  return (
    <section data-type="word-wipe-content">
      <p>
        Word Wipe begins with a grid full of letters The object of the game is
        to clear lines of letters by making words. Every time you combine
        letters to create a word, those letters will disappear and all the
        letters above will drop down. You can combine letters together in any
        direction, including diagonally. Race against the clock and make the
        longest words you can in order to clear as many lines of letters as
        possible from the top of the grid before time runs out!
      </p>
      <p className={styles.cta}>
        Enjoy <A href={allGamesUrl}>more to play</A> on our games hub page!
      </p>
    </section>
  )
}

export default withStyles(styles)(WordWipeContent)
