import React from 'react'
import { number, string } from 'prop-types'
import withStyles from 'isomorphic-style-loader/withStyles'
import { PagingArrow, PagingListItem } from '~elements'
import styles from './BottomPaging.module.scss'

const arrowLeft = '<'
const doubleArrowLeft = '<<'
const arrowRight = '>'
const doubleArrowRight = '>>'

export const BottomPaging = ({ baseUrl, currentPage, totalPages }) => {
  if (totalPages === 0) return null
  const firstPageUrl = currentPage > 1 ? `/${baseUrl}/1` : null
  const prevPageUrl = currentPage > 1 ? `/${baseUrl}/${currentPage - 1}` : null
  const lastPageUrl =
    currentPage < totalPages ? `/${baseUrl}/${totalPages}` : null
  const nextPageUrl =
    currentPage < totalPages ? `/${baseUrl}/${currentPage + 1}` : null
  const NumberOfLinksToGenerate = Math.min(9, totalPages - currentPage)
  const links = [...Array(NumberOfLinksToGenerate).keys()].map(index => {
    const pageNumber = index + currentPage + 1
    const href = `/${baseUrl}/${pageNumber}`

    return (
      <PagingListItem href={href} key={href}>
        {pageNumber}
      </PagingListItem>
    )
  })

  return (
    <div className={styles['bottom-paging']} data-type="bottom-paging">
      <ul>
        <PagingArrow href={firstPageUrl}>{doubleArrowLeft}</PagingArrow>
        <PagingArrow href={prevPageUrl}>{arrowLeft}</PagingArrow>
      </ul>
      <ul>
        <PagingListItem>{currentPage}</PagingListItem>
        {links}
      </ul>
      <ul>
        <PagingArrow href={nextPageUrl}>{arrowRight}</PagingArrow>
        <PagingArrow href={lastPageUrl}>{doubleArrowRight}</PagingArrow>
      </ul>
    </div>
  )
}

BottomPaging.propTypes = {
  baseUrl: string.isRequired,
  currentPage: number.isRequired,
  totalPages: number.isRequired
}

export default withStyles(styles)(BottomPaging)
