import { useSelector } from 'react-redux'
import {
  PATHNAME_BROWSE,
  PATHNAME_COMPARE_SYNONYMS,
  THESAURUS_URL
} from '~client/constants'
import { selectThesaurusUrl } from '~store/location/selectors'

const useThesaurusUrl = () => {
  const thesaurusUrl = useSelector(selectThesaurusUrl) || THESAURUS_URL

  const browseUrl = `${thesaurusUrl}${PATHNAME_BROWSE}`
  const compareSynonymsUrl = `${thesaurusUrl}${PATHNAME_COMPARE_SYNONYMS}`

  return {
    browseUrl,
    compareSynonymsUrl,
    thesaurusUrl
  }
}

export default useThesaurusUrl
