import React, { useEffect, useRef, useState } from 'react'
import { array, func, string } from 'prop-types'
import cx from 'classnames'
import withStyles from 'isomorphic-style-loader/withStyles'
import { useSelector } from 'react-redux'
import { ArrowDropdownIcon, XIcon } from '~elements'
import useOnClickOutside from '~hooks/useOnClickOutside'
import { selectLocationUrl } from '~store/location/selectors'
import styles from './TabsMajor.module.scss'

const TabsMajor = ({ selectedTab, setSelectedTab, tabs }) => {
  const siteUrl = useSelector(selectLocationUrl)
  const [mobileOrder, setMobileOrder] = useState([...tabs])
  const [showMobile, setShowMobile] = useState(false)
  const [displayedTabOrder, setDisplayedTabOrder] = useState(tabs)

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      setShowMobile(entries[0].contentRect.width <= 767)
    })

    resizeObserver.observe(document?.body)
    return () => {
      resizeObserver.disconnect()
    }
  }, [])

  useEffect(() => {
    setDisplayedTabOrder(showMobile ? mobileOrder : tabs)
  }, [showMobile, mobileOrder])

  const [dropdownDrawerOpen, setDropdownDrawerOpen] = useState(false)

  const showMobileDropdown = tabs.length > 2

  const classNames = cx(styles.root, {
    [styles['show-dropdown']]: showMobileDropdown
  })
  const dropdownDrawerClassNames = cx(styles['dropdown-drawer'], {
    [styles.hidden]: !dropdownDrawerOpen
  })

  const dropdownRef = useRef()

  useOnClickOutside(dropdownRef, () => setDropdownDrawerOpen(false))

  const handleTabKeyPress =
    ({ key }) =>
    tabId => {
      if (key === 'Enter') {
        setSelectedTab(tabId)
        setDropdownDrawerOpen(false)
      }
    }

  const handleDrawerKeyPress = ({ key, preventDefault }) => {
    preventDefault()
    if (key === 'Enter') {
      setDropdownDrawerOpen(prev => !prev)
    }
  }

  const handleTabClick =
    (tabId, index, dropdownTab = false) =>
    () => {
      setSelectedTab(tabId)

      if (dropdownTab) {
        if (tabId === tabs[0]) setMobileOrder(tabs)
        else {
          const trueIndex = index + 2
          const frontTabs = mobileOrder.slice(trueIndex - 1)
          const backTabs = mobileOrder.slice(0, trueIndex - 1)
          const updatedMobileTabOrder = [...frontTabs, ...backTabs]
          setMobileOrder(updatedMobileTabOrder)
        }
      } else if (index > 1) {
        const frontTabs = tabs.slice(index - 1)
        const backTabs = tabs.slice(0, index - 1)
        const updatedMobileTabOrder = [...frontTabs, ...backTabs]
        setMobileOrder(updatedMobileTabOrder)
      } else if (index < 2 && !showMobile) {
        setMobileOrder(tabs)
      }
    }

  return (
    <div className={classNames}>
      {displayedTabOrder.map((tabId, index) => {
        const tabClassNames = cx(styles.tab, {
          [styles.selected]: tabId === selectedTab
        })

        return (
          <button
            className={tabClassNames}
            data-analytics="aa-track"
            data-linklocation="body"
            data-linkmodule="dictionary-source"
            data-linkname={`dictionary-source:${tabId}`}
            data-linktype="cta"
            data-linkurl={siteUrl}
            key={tabId}
            onClick={handleTabClick(tabId, index)}
            onKeyDown={handleTabKeyPress(tabId)}
            tabIndex={0}
          >
            {tabId}
          </button>
        )
      })}
      {showMobileDropdown && (
        <button
          className={styles.dropdown}
          onClick={() => setDropdownDrawerOpen(prev => !prev)}
          onKeyDown={handleDrawerKeyPress}
          ref={dropdownRef}
          tabIndex={0}
        >
          {!dropdownDrawerOpen ? <ArrowDropdownIcon /> : <XIcon />}
          <div className={dropdownDrawerClassNames}>
            {dropdownDrawerOpen &&
              displayedTabOrder.slice(2).map((tabId, index) => {
                const tabClassNames = cx(styles.tab, {
                  [styles.selected]: tabId === selectedTab
                })

                return (
                  <button
                    className={tabClassNames}
                    data-analytics="aa-track"
                    data-linklocation="body"
                    data-linkmodule="dictionary-source"
                    data-linkname={`dictionary-source:${tabId}`}
                    data-linktype="cta"
                    data-linkurl={siteUrl}
                    key={tabId}
                    onClick={handleTabClick(tabId, index, true)}
                    onKeyDown={handleTabKeyPress(tabId)}
                    tabIndex={0}
                  >
                    {tabId}
                  </button>
                )
              })}
          </div>
        </button>
      )}
    </div>
  )
}

TabsMajor.propTypes = {
  selectedTab: string.isRequired,
  setSelectedTab: func.isRequired,
  tabs: array.isRequired
}

export default withStyles(styles)(TabsMajor)
