// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DwsFezPApex0O1k3Qa6H{background-color:#fff;border-radius:12px;padding:24px}.DwsFezPApex0O1k3Qa6H header{border-bottom:1px solid var(--warmgray);border-top:0;display:flex;flex-direction:column;margin-bottom:20px;padding-bottom:5px;width:inherit}.DwsFezPApex0O1k3Qa6H h2>a{color:var(--darkgraytext);font-size:24px;font-weight:bold;line-height:.8;margin:0 0 5px;padding:0}.DwsFezPApex0O1k3Qa6H li{line-height:27px}.ln0In1tyooUFJDfijHtz{display:flex;justify-content:space-between}.V4JNnKlWQOmdUClAVT_v{font-size:18px}.trHfa0MhAdkpizjRyCAN>img{height:25px}`, "",{"version":3,"sources":["webpack://./src/client/components/composites/WordComparisonCard/WordComparisonCard.module.scss"],"names":[],"mappings":"AAAA,sBACE,qBAAA,CACA,kBAAA,CACA,YAAA,CAEA,6BACE,uCAAA,CACA,YAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,kBAAA,CACA,aAAA,CAGF,2BACE,yBAAA,CACA,cAAA,CACA,gBAAA,CACA,cAAA,CACA,cAAA,CACA,SAAA,CAGF,yBACE,gBAAA,CAIJ,sBACE,YAAA,CACA,6BAAA,CAGF,sBACE,cAAA,CAIA,0BACE,WAAA","sourcesContent":[".root {\n  background-color: #fff;\n  border-radius: 12px;\n  padding: 24px;\n\n  header {\n    border-bottom: 1px solid var(--warmgray);\n    border-top: 0;\n    display: flex;\n    flex-direction: column;\n    margin-bottom: 20px;\n    padding-bottom: 5px;\n    width: inherit;\n  }\n\n  h2 > a {\n    color: var(--darkgraytext);\n    font-size: 24px;\n    font-weight: bold;\n    line-height: 0.8;\n    margin: 0 0 5px;\n    padding: 0;\n  }\n\n  li {\n    line-height: 27px;\n  }\n}\n\n.heading {\n  display: flex;\n  justify-content: space-between;\n}\n\n.content {\n  font-size: 18px;\n}\n\n.pronunciation {\n  & > img {\n    height: 25px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `DwsFezPApex0O1k3Qa6H`,
	"heading": `ln0In1tyooUFJDfijHtz`,
	"content": `V4JNnKlWQOmdUClAVT_v`,
	"pronunciation": `trHfa0MhAdkpizjRyCAN`
};
module.exports = ___CSS_LOADER_EXPORT___;
