import React from 'react'
import { object } from 'prop-types'
import withStyles from 'isomorphic-style-loader/withStyles'
import parseHtml from '~utils/parseHtml'
import styles from './WordComparisonEditorial.module.scss'

const WordComparisonEditorial = ({
  editorialContent: { comparisonTitle, richTextContent }
}) => (
  <div className={styles.root} data-type="word-comparison-editorial-content">
    <h2>{comparisonTitle}</h2>
    <p>{parseHtml(richTextContent)}</p>
  </div>
)

WordComparisonEditorial.propTypes = {
  editorialContent: object.isRequired
}

export default withStyles(styles)(WordComparisonEditorial)
