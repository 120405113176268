import React, { useState } from 'react'
import { arrayOf, bool, object, shape, string } from 'prop-types'
import cx from 'classnames'
import withStyles from 'isomorphic-style-loader/withStyles'
import { WordListKebabMenu, WordListKebabMenuOptions } from '~composites'
import useDictionaryUrl from '~hooks/useDictionaryUrl'
import pluralize from '~utils/pluralize'
import styles from './LearningHubWordListCard.module.scss'

const LearningHubWordListCard = ({
  isFeatured,
  wordList: { category, description, name, shortId, words }
}) => {
  const { learnWordListsUrl } = useDictionaryUrl()
  const [isKebabMenuOpen, setIsKebabMenuOpen] = useState(false)

  const classNames = cx(styles.root, {
    [styles['featured-card']]: isFeatured
  })

  const showDescription = !!description && isFeatured
  const wordListUrl = `${learnWordListsUrl}/${category}/${shortId}`

  const navigateToWordList = ({ key, type }) => {
    const isClick = type === 'click'
    const isEnterPress = key === 'Enter'
    const shouldNavigate = !isKebabMenuOpen && (isClick || isEnterPress)
    if (shouldNavigate) {
      window.location.href = wordListUrl
    }
  }

  return (
    <div
      className={classNames}
      data-type="learning-hub-word-list-card"
      onClick={navigateToWordList}
      onKeyDown={navigateToWordList}
      tabIndex={0}
    >
      <p className={styles['card-name']}>{name}</p>
      {showDescription && <p className={styles.description}>{description}</p>}
      <div className={styles['card-footer-container']}>
        <span>{`${words.length} ${pluralize(words.length, 'word')}`}</span>
        <WordListKebabMenu
          className={styles['kebab-menu']}
          isOpen={isKebabMenuOpen}
          setIsOpen={setIsKebabMenuOpen}
        >
          <WordListKebabMenuOptions
            category={category}
            page="learningHub"
            setIsKebabMenuOpen={setIsKebabMenuOpen}
            shortId={shortId}
          />
        </WordListKebabMenu>
      </div>
    </div>
  )
}

LearningHubWordListCard.propTypes = {
  isFeatured: bool,
  wordList: shape({
    category: string.isRequired,
    description: string,
    name: string.isRequired,
    shortId: string.isRequired,
    words: arrayOf(object).isRequired
  })
}

LearningHubWordListCard.defaultProps = {
  isFeatured: false,
  wordList: { description: '' }
}

export default withStyles(styles)(LearningHubWordListCard)
