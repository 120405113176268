import React from 'react'
import withStyles from 'isomorphic-style-loader/withStyles'
import { Pill } from '~elements'
import styles from './WotyBadge.module.scss'

const YEAR = '2023'
const WOTY_URL = 'https://content.dictionary.com/word-of-the-year-2023'

const WotyBadge = () => {
  const badgeText = `${YEAR} Word Of The Year | See Why`

  return (
    <Pill
      className={styles.root}
      data-analytics="aa-track"
      data-linklocation="body"
      data-linkmodule="woty-badge"
      data-linkname={badgeText}
      data-linktype="link"
      data-linkurl={WOTY_URL}
      data-type="woty-badge"
      href={WOTY_URL}
      linkId="dcjtos"
    >
      {badgeText}
    </Pill>
  )
}

export default withStyles(styles)(WotyBadge)
