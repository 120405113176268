// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nb4u0K0NJbHEE4mlW4Fr{position:relative}a.ThrIJkGT04HOV99Ug7u9{border-radius:12px;color:#fff;cursor:pointer;display:flex;flex-direction:column;height:132px;margin-bottom:16px;padding:18px;text-align:left;text-decoration:none}a.ThrIJkGT04HOV99Ug7u9:hover{color:#fff;text-decoration:none}.x_XUISFAw6BgAL04SoaQ{font-size:20px;height:76px;margin:0;word-break:break-word}.eYnDWu96NHDI_FNtWCL7{align-items:center;display:flex;justify-content:space-between;width:100%}.QQnwOclyYOELfdc2UHwq{font-size:18px;margin:0}div.zJZ5st4jRfZPfhe9wzSR{bottom:16px;position:absolute;right:0}`, "",{"version":3,"sources":["webpack://./src/client/components/composites/WordListCard/WordListCard.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CAGF,uBACE,kBAAA,CACA,UAAA,CACA,cAAA,CACA,YAAA,CACA,qBAAA,CACA,YAAA,CACA,kBAAA,CACA,YAAA,CACA,eAAA,CACA,oBAAA,CAEA,6BACE,UAAA,CACA,oBAAA,CAIJ,sBACE,cAAA,CACA,WAAA,CACA,QAAA,CACA,qBAAA,CAGF,sBACE,kBAAA,CACA,YAAA,CACA,6BAAA,CACA,UAAA,CAGF,sBACE,cAAA,CACA,QAAA,CAGF,yBACE,WAAA,CACA,iBAAA,CACA,OAAA","sourcesContent":[".root {\n  position: relative;\n}\n\na.wordlist-card {\n  border-radius: 12px;\n  color: #fff;\n  cursor: pointer;\n  display: flex;\n  flex-direction: column;\n  height: 132px;\n  margin-bottom: 16px;\n  padding: 18px;\n  text-align: left;\n  text-decoration: none;\n\n  &:hover {\n    color: #fff;\n    text-decoration: none;\n  }\n}\n\n.wordlist-title {\n  font-size: 20px;\n  height: 76px;\n  margin: 0;\n  word-break: break-word;\n}\n\n.card-footer {\n  align-items: center;\n  display: flex;\n  justify-content: space-between;\n  width: 100%;\n}\n\n.word-count {\n  font-size: 18px;\n  margin: 0;\n}\n\ndiv.kebab-menu {\n  bottom: 16px;\n  position: absolute;\n  right: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `nb4u0K0NJbHEE4mlW4Fr`,
	"wordlist-card": `ThrIJkGT04HOV99Ug7u9`,
	"wordlist-title": `x_XUISFAw6BgAL04SoaQ`,
	"card-footer": `eYnDWu96NHDI_FNtWCL7`,
	"word-count": `QQnwOclyYOELfdc2UHwq`,
	"kebab-menu": `zJZ5st4jRfZPfhe9wzSR`
};
module.exports = ___CSS_LOADER_EXPORT___;
