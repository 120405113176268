import React, { useEffect, useState } from 'react'
import { bool, number, string } from 'prop-types'
import LazyAd from './LazyAd'

const GptAd = ({ className, height, slotName, useLazyLoad, width }) => {
  const [isSlotDefined, setIsSlotDefined] = useState(false)
  const [slot, setSlot] = useState(null)

  const isWindowDefined = typeof window !== 'undefined'

  // Define ad slot
  useEffect(() => {
    if (isWindowDefined && !isSlotDefined) {
      window.googletag = window.googletag || {}
      window.googletag.cmd = window.googletag.cmd || []

      let slot

      window.googletag.cmd.push(function () {
        if (slotName.includes('interstitial')) {
          slot = window.googletag.defineOutOfPageSlot(
            `/23219321/dictionary.com/${slotName}`,
            window.googletag.enums.OutOfPageFormat.INTERSTITIAL
          )
        } else {
          slot = window.googletag.defineSlot(
            `/23219321/dictionary.com/${slotName}`,
            [width, height],
            slotName
          )
        }
        slot.addService(window.googletag.pubads())
        window.googletag.enableServices()
        setSlot(slot)
      })
      setIsSlotDefined(true)
    }
  }, [isWindowDefined, isSlotDefined])

  // Display ad slot
  useEffect(() => {
    if (isSlotDefined && slot && !useLazyLoad) {
      window.googletag.cmd.push(function () {
        window.googletag.display(slot)
        window.googletag.pubads().refresh([slot])
      })

      return () => {
        window.googletag.cmd.push(function () {
          window.googletag.destroySlots([slot])
          setIsSlotDefined(false)
        })
      }
    }
  }, [isSlotDefined, slot])

  if (useLazyLoad && slot) {
    return (
      <LazyAd
        className={className}
        slot={slot}
        slotName={slotName}
        style={{ height, width }}
      />
    )
  }

  return <div className={className} id={slotName} style={{ height, width }} />
}

GptAd.propTypes = {
  className: string,
  height: number.isRequired,
  slotName: string.isRequired,
  useLazyLoad: bool,
  width: number.isRequired
}

GptAd.defaultProps = {
  className: null,
  useLazyLoad: false
}

export default GptAd
