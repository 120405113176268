import React from 'react'
import { func } from 'prop-types'
import withStyles from 'isomorphic-style-loader/withStyles'
import { Button } from '~elements'
import styles from './RetakeButton.module.scss'

const RetakeButton = ({ onClick }) => {
  return (
    <Button className={styles.root} data-type="retake-button" onClick={onClick}>
      Retake
      <div className={styles.icon} />
    </Button>
  )
}

RetakeButton.propTypes = {
  onClick: func.isRequired
}

export default withStyles(styles)(RetakeButton)
