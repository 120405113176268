const addSignUpToDataLayer = formContext => {
  window.appEventData = window.appEventData || []

  window.appEventData.push({
    event: 'Form Submission Succeeded',
    form: {
      formName: `${formContext}-sign-up`,
      formType: 'subscribe'
    }
  })

  window.appEventData.push({
    event: 'Form Submission Success Completed'
  })
}

export default addSignUpToDataLayer
