import React from 'react'
import withStyles from 'isomorphic-style-loader/withStyles'
import {
  DICTIONARY_URL_CROSSWORD_SOLVER,
  DICTIONARY_URL_CROSSWORD_TRIVIA
} from '~client/constants'
import { A } from '~elements'
import useGamesUrl from '~hooks/useGamesUrl'
import styles from './CrosswordContent.module.scss'

const CrosswordContent = () => {
  const {
    allGamesUrl,
    easyCrosswordUrl,
    miniCrosswordUrl,
    sundayCrosswordUrl
  } = useGamesUrl()

  return (
    <section data-type="crossword-content">
      <p>
        Play the daily crossword puzzle from Dictionary.com. Featuring a new
        puzzle every day! Learn new words and grow your vocabulary while solving
        the daily puzzle. For Crossword help, clues and answers, check out our{' '}
        <A href={DICTIONARY_URL_CROSSWORD_SOLVER}>crossword solver</A>. For some
        trivia, click here to find out{' '}
        <A data-linkid="65kp26" href={DICTIONARY_URL_CROSSWORD_TRIVIA}>
          who invented the crossword puzzle
        </A>
        .
      </p>
      <strong>Play more of our exciting crossword puzzles today!</strong>
      <ul>
        <li>
          <A href={sundayCrosswordUrl}>Sunday Crossword</A>
        </li>
        <li>
          <A href={easyCrosswordUrl}>Easy Crossword</A>
        </li>
        <li>
          <A href={miniCrosswordUrl}>Mini Crossword</A>
        </li>
      </ul>
      <p className={styles.cta}>
        Ready for more games? Our <A href={allGamesUrl}>fun word games</A> are
        just one click away!
      </p>
    </section>
  )
}

export default withStyles(styles)(CrosswordContent)
