import React, { useState } from 'react'
import { func, string } from 'prop-types'
import cx from 'classnames'
import withStyles from 'isomorphic-style-loader/withStyles'
import { useLocation } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import { PROPTYPE_NAVIGATION_GROUP } from '~client/constants'
import { ChevronDownIcon } from '~elements'
import isArrayWithLength from '~utils/isArrayWithLength'
import styles from './ResultsPageNavigationGroup.module.scss'

const ResultsPageNavigationGroup = ({
  closeNavigationMenu,
  highlightedNavigationItemId,
  navigationGroup,
  scrollToElement
}) => {
  const { pathname, search } = useLocation()
  const [isExpanded, setIsExpanded] = useState(true)

  const { heading, items, linkname } = navigationGroup

  if (!isArrayWithLength(items)) return null

  const classNames = cx(styles.root, {
    [styles.expanded]: isExpanded
  })

  const navigationIds = items.map(item => item.id)

  const posToggleClassNames = cx(styles['pos-toggle'], {
    [styles.highlighted]:
      navigationIds.includes(highlightedNavigationItemId) && !isExpanded
  })

  const toggleMenu = () => {
    setIsExpanded(!isExpanded)
  }

  const handleKeyboardSelection = ({ key }) => {
    if (key === 'Enter') {
      toggleMenu()
    }
  }

  return (
    <div className={classNames} data-type="results-page-navigation-group">
      <button
        className={posToggleClassNames}
        data-analytics="aa-track"
        data-linklocation="body"
        data-linkmodule="results-page:side-nav-bar"
        data-linkname={linkname}
        data-linktype="cta"
        data-linkurl="/'"
        onClick={toggleMenu}
        onKeyDown={handleKeyboardSelection}
        tabIndex={0}
      >
        {heading}
        <ChevronDownIcon />
      </button>
      {items.map(({ content, id }) => {
        const navigationItemClassNames = cx({
          [styles.active]: id === highlightedNavigationItemId
        })

        return (
          <HashLink
            className={navigationItemClassNames}
            key={id}
            onClick={closeNavigationMenu}
            scroll={scrollToElement}
            tabIndex={isExpanded ? 0 : -1}
            to={`${pathname}${search}#${id}`}
          >
            {content}
          </HashLink>
        )
      })}
    </div>
  )
}

ResultsPageNavigationGroup.propTypes = {
  closeNavigationMenu: func.isRequired,
  highlightedNavigationItemId: string.isRequired,
  navigationGroup: PROPTYPE_NAVIGATION_GROUP.isRequired,
  scrollToElement: func.isRequired
}

export default withStyles(styles)(ResultsPageNavigationGroup)
