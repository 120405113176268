import React from 'react'
import { bool, func } from 'prop-types'
import cx from 'classnames'
import withStyles from 'isomorphic-style-loader/withStyles'
import { PROPTYPE_CHILDREN } from '~client/constants'
import { Button } from '~elements'
import styles from './QuizOption.module.scss'

const QuizOption = ({
  children,
  isCorrect,
  isSelected,
  onClick,
  questionAnswered
}) => {
  const classNames = cx(styles.root, {
    [styles.correct]: isCorrect && questionAnswered,
    [styles.wrong]: !isCorrect && isSelected
  })

  return (
    <Button className={classNames} data-type="quiz-option" onClick={onClick}>
      {children}
    </Button>
  )
}

QuizOption.propTypes = {
  children: PROPTYPE_CHILDREN.isRequired,
  isCorrect: bool.isRequired,
  isSelected: bool,
  onClick: func.isRequired,
  questionAnswered: bool.isRequired
}

QuizOption.defaultProps = {
  isSelected: false
}

export default withStyles(styles)(QuizOption)
