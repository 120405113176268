import { useSelector } from 'react-redux'
import {
  DICTIONARY_URL,
  PATHNAME_ACCOUNT,
  PATHNAME_ACCOUNT_WORD_LISTS,
  PATHNAME_BROWSE,
  PATHNAME_COMPARE_WORDS,
  PATHNAME_CROSSWORD,
  PATHNAME_FORGOT_PASSWORD,
  PATHNAME_GAMES_QUIZZES,
  PATHNAME_GAMES_WORD_PUZZLE,
  PATHNAME_LEARN_QUIZZES,
  PATHNAME_LEARN_WORD_LISTS,
  PATHNAME_SIGN_IN,
  PATHNAME_SIGN_UP,
  PATHNAME_WORD_LISTS
} from '~client/constants'
import { selectDictionaryUrl } from '~store/location/selectors'

const useDictionaryUrl = () => {
  const dictionaryUrl = useSelector(selectDictionaryUrl) || DICTIONARY_URL

  const accountUrl = `${dictionaryUrl}${PATHNAME_ACCOUNT}`
  const accountWordListsUrl = `${dictionaryUrl}${PATHNAME_ACCOUNT_WORD_LISTS}`
  const browseUrl = `${dictionaryUrl}${PATHNAME_BROWSE}`
  const compareWordsUrl = `${dictionaryUrl}${PATHNAME_COMPARE_WORDS}`
  const crosswordUrl = `${dictionaryUrl}${PATHNAME_CROSSWORD}`
  const forgotPasswordUrl = `${dictionaryUrl}${PATHNAME_FORGOT_PASSWORD}`
  const gamesQuizzesUrl = `${dictionaryUrl}${PATHNAME_GAMES_QUIZZES}`
  const gamesWordPuzzleUrl = `${dictionaryUrl}${PATHNAME_GAMES_WORD_PUZZLE}`
  const learnQuizzesUrl = `${dictionaryUrl}${PATHNAME_LEARN_QUIZZES}`
  const learnWordListsUrl = `${dictionaryUrl}${PATHNAME_LEARN_WORD_LISTS}`
  const signInUrl = `${dictionaryUrl}${PATHNAME_SIGN_IN}`
  const signUpUrl = `${dictionaryUrl}${PATHNAME_SIGN_UP}`
  const wordListsUrl = `${dictionaryUrl}${PATHNAME_WORD_LISTS}`

  return {
    accountUrl,
    accountWordListsUrl,
    browseUrl,
    compareWordsUrl,
    crosswordUrl,
    dictionaryUrl,
    forgotPasswordUrl,
    gamesQuizzesUrl,
    gamesWordPuzzleUrl,
    learnQuizzesUrl,
    learnWordListsUrl,
    signInUrl,
    signUpUrl,
    wordListsUrl
  }
}

export default useDictionaryUrl
