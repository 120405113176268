import React from 'react'
import { array, string } from 'prop-types'
import withStyles from 'isomorphic-style-loader/withStyles'
import { AntonymPill } from '~composites'
import styles from './SharedAntonymList.module.scss'

const SharedAntonymList = ({
  matchDisplayForm,
  rootDisplayForm,
  sharedAntonyms
}) => {
  const antonymList = sharedAntonyms
    ? sharedAntonyms.map(({ displayForm, slug }) => (
        <li key={slug}>
          <AntonymPill slug={slug}>{displayForm}</AntonymPill>
        </li>
      ))
    : null

  return (
    <div className={styles.root}>
      <h3 className={styles['shared-antonyms']}>
        Shared antonyms between <em>{matchDisplayForm}</em> and{' '}
        <em>{rootDisplayForm}</em>
      </h3>
      {antonymList && antonymList.length > 0 ? (
        <ul>{antonymList}</ul>
      ) : (
        <h3 className={styles['no-shared-antonyms']}>
          There are no shared antonyms between <em>{rootDisplayForm}</em> and{' '}
          <em>{matchDisplayForm}</em>. 😞
        </h3>
      )}
    </div>
  )
}

SharedAntonymList.propTypes = {
  matchDisplayForm: string.isRequired,
  rootDisplayForm: string.isRequired,
  sharedAntonyms: array.isRequired
}

export default withStyles(styles)(SharedAntonymList)
