/**
 * Pluralizes the word based on the count. Adds an "s" to word if no
 * third argument is passed, but can accommodate irregular plural forms.
 *
 * Examples:
 * - pluralize(1, 'parent') → 'parent'
 * - pluralize(2, 'parent') → 'parents'
 * - pluralize(1, 'child', 'children') → 'child'
 * - pluralize(2, 'child', 'children') → 'children'
 */
const pluralize = (count, word, plural = `${word}s`) =>
  count === 1 ? word : plural

export default pluralize
