import React from 'react'
import withStyles from 'isomorphic-style-loader/withStyles'
import { A } from '~elements'
import useDictionaryUrl from '~hooks/useDictionaryUrl'
import useGamesUrl from '~hooks/useGamesUrl'
import styles from './SundayCrosswordContent.module.scss'

const SundayCrosswordContent = () => {
  const { crosswordUrl } = useDictionaryUrl()
  const { allGamesUrl, easyCrosswordUrl, miniCrosswordUrl } = useGamesUrl()

  return (
    <section data-type="sunday-crossword-content">
      <p>
        The Sunday Crossword is larger and more challenging than your everyday
        crossword. It features the classic crossword gameplay you love, but with
        a larger grid and even tougher clues, composed especially for seasoned
        cruciverbalists who crave a challenge. Do you have what it takes to
        conquer the Sunday Crossword?
      </p>
      <strong>Explore our library of crossword puzzles!</strong>
      <ul>
        <li>
          <A href={crosswordUrl}>Daily Crossword</A>
        </li>
        <li>
          <A href={easyCrosswordUrl}>Easy Crossword</A>
        </li>
        <li>
          <A href={miniCrosswordUrl}>Mini Crossword</A>
        </li>
      </ul>
      <p className={styles.cta}>
        More <A href={allGamesUrl}>online games</A> are waiting for you to play!
      </p>
    </section>
  )
}

export default withStyles(styles)(SundayCrosswordContent)
