import React from 'react'
import withStyles from 'isomorphic-style-loader/withStyles'
import styles from './IdiomsCitation.module.scss'

const IdiomsCitation = () => (
  <section className={styles.root}>
    <p>
      Definitions and idiom definitions from Dictionary.com Unabridged, based on
      the Random House Unabridged Dictionary, © Random House, Inc. 2023
    </p>
    <p>
      Idioms from The American Heritage® Idioms Dictionary copyright © 2002,
      2001, 1995 by Houghton Mifflin Harcourt Publishing Company. Published by
      Houghton Mifflin Harcourt Publishing Company.
    </p>
  </section>
)

export default withStyles(styles)(IdiomsCitation)
