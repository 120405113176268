import React, { useMemo, useState } from 'react'
import { array, func } from 'prop-types'
import cx from 'classnames'
import withStyles from 'isomorphic-style-loader/withStyles'
import { NUMBER_OF_WORDS_TO_WIN } from '~client/constants'
import { RetakeButton, WordPuzzleAnswerCard } from '~composites'
import { A, Button } from '~elements'
import useDictionaryUrl from '~hooks/useDictionaryUrl'
import styles from './WordPuzzleResults.module.scss'

const WordPuzzleResults = ({ allWords, foundWords, resetGame }) => {
  const { gamesWordPuzzleUrl, learnQuizzesUrl } = useDictionaryUrl()
  const [showAnswers, setShowAnswers] = useState(false)
  const totalWordsFound = foundWords.length
  const totalWords = allWords.length

  const showAnswersClassNames = cx(styles['show-answers-button'], {
    [styles['answer-up-arrow']]: showAnswers,
    [styles['answer-down-arrow']]: !showAnswers
  })

  const unfoundWords = useMemo(
    () =>
      allWords.filter(words => foundWords.indexOf(words.toUpperCase()) === -1),
    [allWords, foundWords]
  )

  const wordsFound = foundWords.map(word => (
    <WordPuzzleAnswerCard isCorrect key={word} word={word.toLowerCase()} />
  ))

  const wordsNotFound = unfoundWords.map(word => (
    <WordPuzzleAnswerCard key={word} word={word.toLowerCase()} />
  ))

  const resultMessage =
    totalWordsFound === NUMBER_OF_WORDS_TO_WIN
      ? "Nice! You're a bona fide wordie!"
      : "We think there's a 100 % in your future."

  const handleShowAnswers = () => {
    setShowAnswers(!showAnswers)
  }

  return (
    <div className={styles.root} data-type="word-puzzle-results">
      <div className={styles['results-container']}>
        <header>Your Results</header>
        <div className={styles.results}>
          <div className={styles.score}>
            {totalWordsFound} / {NUMBER_OF_WORDS_TO_WIN}
          </div>
          <div className={styles.message}>
            <p>{resultMessage}</p>
            <RetakeButton onClick={resetGame} />
          </div>
        </div>
        <Button className={showAnswersClassNames} onClick={handleShowAnswers}>
          {showAnswers ? 'Hide' : 'Show'} Answers ({totalWords})
        </Button>
        {showAnswers && (
          <div className={styles['word-list']}>
            {wordsFound}
            {wordsNotFound}
          </div>
        )}
        <A className={styles['new-game']} href={gamesWordPuzzleUrl}>
          Play New Game
        </A>
        <A className={styles['try-quizzes']} href={learnQuizzesUrl}>
          Try Our Quizzes
        </A>
      </div>
    </div>
  )
}

WordPuzzleResults.propTypes = {
  allWords: array.isRequired,
  foundWords: array.isRequired,
  resetGame: func.isRequired
}

export default withStyles(styles)(WordPuzzleResults)
