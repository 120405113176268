import React from 'react'
import { array, string } from 'prop-types'
import cx from 'classnames'
import withStyles from 'isomorphic-style-loader/withStyles'
import { Button, ModalFooter } from '~elements'
import useThesaurusUrl from '~hooks/useThesaurusUrl'
import { LINKID_SYNONYM_COMPARISON } from '~utils/analytics/linkIds'
import styles from './CompareMoreModalFooter.module.scss'

const CompareMoreModalFooter = ({
  pos,
  selectedSynonymsList,
  urlOrdinal,
  urlRoot
}) => {
  const { compareSynonymsUrl } = useThesaurusUrl()

  const synonymList = selectedSynonymsList.join(',')
  const comparisonLink = `${compareSynonymsUrl}?root=${urlRoot}&compare=${synonymList}&pos=${pos}&ordinal=${urlOrdinal}`

  const handleClick = () => {
    window.location.href = comparisonLink
  }

  const classNames = cx(styles['compare-button'], {
    [styles.disabled]: selectedSynonymsList.length < 2
  })

  return (
    <ModalFooter className={styles.root} data-type="compare-more-modal-footer">
      <Button
        className={classNames}
        linkId={LINKID_SYNONYM_COMPARISON.compareModalCTA}
        onClick={handleClick}
      >
        Compare Synonyms
      </Button>
    </ModalFooter>
  )
}

CompareMoreModalFooter.propTypes = {
  pos: string.isRequired,
  selectedSynonymsList: array.isRequired,
  urlOrdinal: string.isRequired,
  urlRoot: string.isRequired
}

export default withStyles(styles)(CompareMoreModalFooter)
