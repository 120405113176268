// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.O5uMvQllx4sVP_P4gLan{display:grid;gap:16px;grid-auto-flow:column;grid-template-columns:repeat(3, minmax(260px, 324px));list-style-type:none;margin-bottom:24px;padding:0;place-content:start;width:100%}.UwaUqSu8jqC9cwkqmYfI{height:100%}`, "",{"version":3,"sources":["webpack://./src/client/components/composites/SynonymComparisonCardList/SynonymComparisonCardList.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,QAAA,CACA,qBAAA,CACA,qDAAA,CACA,oBAAA,CACA,kBAAA,CACA,SAAA,CACA,mBAAA,CACA,UAAA,CAGF,sBACE,WAAA","sourcesContent":[".root {\n  display: grid;\n  gap: 16px;\n  grid-auto-flow: column;\n  grid-template-columns: repeat(3, minmax(260px, 324px));\n  list-style-type: none;\n  margin-bottom: 24px;\n  padding: 0;\n  place-content: start;\n  width: 100%;\n}\n\n.card-item {\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `O5uMvQllx4sVP_P4gLan`,
	"card-item": `UwaUqSu8jqC9cwkqmYfI`
};
module.exports = ___CSS_LOADER_EXPORT___;
