/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react'
import { string } from 'prop-types'
import withStyles from 'isomorphic-style-loader/withStyles'
import { DICTIONARY_SITENAME } from '~client/constants'
import { carambolaAdScript } from '~utils/ads/carambolaAdScript'
import styles from './CarambolaAd.module.scss'

const DICTIONARY_CARAMBOLA_IMG_SRC =
  '//pixel.carambo.la/Pixel/dctn68/112845/2/1/0'
const DICTIONARY_CARAMBOLA_SCRIPT_SRC =
  '//route.carambo.la/inimage/getlayer?pid=dctn68&did=112845&wid=2&rdn='
const DICTIONARY_CARAMBOLA_WIDGET_ID = '2'

const THESAURUS_CARAMBOLA_IMG_SRC =
  '//pixel.carambo.la/Pixel/dctn68/112846/6/1/0'
const THESAURUS_CARAMBOLA_SCRIPT_SRC =
  '//route.carambo.la/inimage/getlayer?pid=dctn68&did=112846&wid=6&rdn='
const THESAURUS_CARAMBOLA_WIDGET_ID = '6'

const CarambolaAd = ({ siteName }) => {
  const [hasMounted, setHasMounted] = useState(false)

  const isDictionarySite = siteName === DICTIONARY_SITENAME
  const carambolaImgSrc = isDictionarySite
    ? DICTIONARY_CARAMBOLA_IMG_SRC
    : THESAURUS_CARAMBOLA_IMG_SRC
  const carambolaScriptSrc = isDictionarySite
    ? DICTIONARY_CARAMBOLA_SCRIPT_SRC
    : THESAURUS_CARAMBOLA_SCRIPT_SRC
  const carambolaWidgetId = isDictionarySite
    ? DICTIONARY_CARAMBOLA_WIDGET_ID
    : THESAURUS_CARAMBOLA_WIDGET_ID

  useEffect(() => {
    setHasMounted(true)

    carambolaAdScript(
      window,
      document,
      'InContent',
      'script',
      'mediaType',
      'carambola_proxy',
      'Cbola_IC',
      'localStorage',
      'set',
      'get',
      'Item',
      'cbolaDt',
      carambolaScriptSrc,
      siteName
    )
  }, [])

  return (
    <div className={styles.root} data-type={`${siteName}-carambola-ad`}>
      <aside className={styles['carambola-container']}>
        <img
          alt="carambola ad carousel"
          height="0"
          id="carambola-img"
          src={carambolaImgSrc}
          style={{
            display: 'block',
            height: 0,
            width: 0
          }}
          width="0"
        />
        {hasMounted && (
          <script
            /* eslint-disable react/no-unknown-property */
            cbola_wid={carambolaWidgetId}
            className="carambola_InContent"
            data-cfasync="false"
            id="carmabola-script"
            type="text/javascript"
          />
        )}
      </aside>
    </div>
  )
}

CarambolaAd.propTypes = {
  siteName: string.isRequired
}

export default withStyles(styles)(CarambolaAd)
