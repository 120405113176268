// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fRt5UF_J1om1e0j3_tBV{display:grid;gap:16px;grid-template-columns:1fr;margin:0 0 32px}.IrXdKql6VSkyirjqqXam{font-size:18px;line-height:1.5}`, "",{"version":3,"sources":["webpack://./src/client/components/composites/SynonymDefinitionList/SynonymDefinitionList.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,QAAA,CACA,yBAAA,CACA,eAAA,CAGF,sBACE,cAAA,CACA,eAAA","sourcesContent":[".root {\n  display: grid;\n  gap: 16px;\n  grid-template-columns: 1fr;\n  margin: 0 0 32px;\n}\n\n.definition-item {\n  font-size: 18px;\n  line-height: 1.5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `fRt5UF_J1om1e0j3_tBV`,
	"definition-item": `IrXdKql6VSkyirjqqXam`
};
module.exports = ___CSS_LOADER_EXPORT___;
