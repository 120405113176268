import React from 'react'
import { array, string } from 'prop-types'
import withStyles from 'isomorphic-style-loader/withStyles'
import { Pill, ResultsCard } from '~elements'
import useDictionaryUrl from '~hooks/useDictionaryUrl'
import styles from './CompareMeaningsCard.module.scss'

const CompareMeaningsCard = ({ word, wordComparisons }) => {
  const { compareWordsUrl } = useDictionaryUrl()

  const comparisons = wordComparisons.map(words => {
    /* eslint camelcase: ["error", {properties: "never"}] */
    const { display_form: displayForm, keyphrase } = words
    const compare = keyphrase.replaceAll(' vs ', '-vs-')

    return (
      <Pill
        className={styles.pill}
        href={`${compareWordsUrl}/${compare}?root=${word}`}
        key={keyphrase}
        linkId="mb2pqh"
      >
        {displayForm}
      </Pill>
    )
  })

  return (
    <ResultsCard className={styles.root} data-type="compare-meanings-card">
      <header>
        <h2>Compare Meanings</h2>
      </header>
      <div className={styles['compare-buttons']}>{comparisons}</div>
      <div className={styles.text}>
        <p>
          Click for a side-by-side comparison of meanings. Use the word
          comparison feature to learn the differences between similar and
          commonly confused words.
        </p>
      </div>
    </ResultsCard>
  )
}

CompareMeaningsCard.propTypes = {
  word: string.isRequired,
  wordComparisons: array.isRequired
}

export default withStyles(styles)(CompareMeaningsCard)
