import React from 'react'
import { array, string } from 'prop-types'
import withStyles from 'isomorphic-style-loader/withStyles'
import { SynonymPill } from '~composites'
import styles from './SharedSynonymList.module.scss'

const SharedSynonymList = ({
  matchDisplayForm,
  rootDisplayForm,
  sharedSynonyms
}) => {
  const synonymList = sharedSynonyms
    ? sharedSynonyms.map(({ displayForm, slug }) => (
        <li key={slug}>
          <SynonymPill slug={slug}>{displayForm}</SynonymPill>
        </li>
      ))
    : null

  return (
    <div className={styles.root}>
      <h3 className={styles['shared-synonyms']}>
        Shared synonyms between <em>{matchDisplayForm}</em> and{' '}
        <em>{rootDisplayForm}</em>
      </h3>
      {synonymList && synonymList.length > 0 ? (
        <ul>{synonymList}</ul>
      ) : (
        <h3 className={styles['no-shared-synonyms']}>
          There are no shared synonyms between <em>{rootDisplayForm}</em> and{' '}
          <em>{matchDisplayForm}</em>. 😞
        </h3>
      )}
    </div>
  )
}

SharedSynonymList.propTypes = {
  matchDisplayForm: string.isRequired,
  rootDisplayForm: string.isRequired,
  sharedSynonyms: array.isRequired
}

export default withStyles(styles)(SharedSynonymList)
