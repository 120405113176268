import React from 'react'
import withStyles from 'isomorphic-style-loader/withStyles'
import { A } from '~elements'
import useGamesUrl from '~hooks/useGamesUrl'
import styles from './OutspellContent.module.scss'

const OutspellContent = () => {
  const { allGamesUrl } = useGamesUrl()

  return (
    <section data-type="outspell-content">
      <p>
        Inspired by the classic word game Scrabble, Outspell challenges you to
        make the best words you can using the letter tiles you are given.
        Compete against three different levels of AI opponents to see how great
        of a wordsmith you are! Play starts in the middle of the board, and
        words are built out from there by combining your letter tiles with those
        already on the board. Each letter tile has its own point value, and
        points are earned based on the total value of the letter tiles used
        during a turn.
      </p>
      <p className={styles.cta}>
        <A href={allGamesUrl}>Check out our additional games</A>!
      </p>
    </section>
  )
}

export default withStyles(styles)(OutspellContent)
