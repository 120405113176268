import React from 'react'
import { bool, object } from 'prop-types'
import withStyles from 'isomorphic-style-loader/withStyles'
import { useSelector } from 'react-redux'
import useDictionaryUrl from '~client/hooks/useDictionaryUrl'
import { ArrowTopRightIcon, DefaultLink } from '~elements'
import useThesaurusUrl from '~hooks/useThesaurusUrl'
import { selectSiteName } from '~store/location/selectors'
import { DICTIONARY_SITENAME } from '~store/location/slice'
import isObjectWithProperties from '~utils/isObjectWithProperties'
import styles from './ViewDefinitionsOrSynonymsFor.module.scss'

const ViewDefinitionsOrSynonymsFor = ({ data, isSeoTest }) => {
  const siteName = useSelector(selectSiteName)
  const { browseUrl: dictionaryBrowseUrl } = useDictionaryUrl()
  const { browseUrl: thesaurusBrowseUrl } = useThesaurusUrl()

  if (!isObjectWithProperties(data)) return null

  const { displayForm, slug } = data

  const isDictionarySite = siteName === DICTIONARY_SITENAME
  const browseUrl = isDictionarySite ? thesaurusBrowseUrl : dictionaryBrowseUrl
  const viewType = isDictionarySite ? 'synonyms' : 'definitions'

  const viewTypeRedirect = isSeoTest
    ? 'Definition for '
    : `View ${viewType} for `

  return (
    <span className={styles.root} data-type="view-definitions-or-synonyms-for">
      {viewTypeRedirect}
      <DefaultLink
        data-analytics="aa-track"
        data-linklocation="header"
        data-linkmodule={`view-${viewType}-for`}
        data-linkname={`view-${viewType}:${slug}`}
        data-linktype="cta"
        data-linkurl={`${browseUrl}/${slug}`}
        href={`${browseUrl}/${slug}`}
        target="_blank"
      >
        {displayForm}
        <ArrowTopRightIcon />
      </DefaultLink>
    </span>
  )
}

ViewDefinitionsOrSynonymsFor.propTypes = {
  data: object.isRequired,
  isSeoTest: bool
}

ViewDefinitionsOrSynonymsFor.defaultProps = {
  isSeoTest: false
}

export default withStyles(styles)(ViewDefinitionsOrSynonymsFor)
