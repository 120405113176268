import React, { useEffect, useState } from 'react'
import { array, string } from 'prop-types'
import withStyles from 'isomorphic-style-loader/withStyles'
import SelectPos from '~elements/SelectPos/SelectPos'
import parseHtml from '~utils/parseHtml'
import styles from './PartOfSpeech.module.scss'

const PartOfSpeech = ({ entry, posBlocks }) => {
  const [selectedPos, setSelectedPos] = useState()
  const [selectedDefinitions, setSelectedDefinitions] = useState([])

  const hasPos = posBlocks?.length > 0
  const hasManyPos = posBlocks?.length > 1

  const definitions = pos =>
    pos?.definitions?.length > 0
      ? pos.definitions.map(definition => {
          if (typeof definition.subdefinitions !== 'undefined') {
            return (
              <div key={`${entry}-${definition.order}`}>
                <li className={styles.definition}>
                  {parseHtml(definition.predefinitionContent)}
                </li>
                <ol className={styles.definitions} type="a">
                  {subdefinitions(definition.subdefinitions)}
                </ol>
              </div>
            )
          }
          return (
            <li
              className={styles.definition}
              key={`${entry}-${definition.order}`}
            >
              {parseHtml(definition.definition)}
            </li>
          )
        })
      : null

  const subdefinitions = subdefs =>
    subdefs.length > 0 &&
    subdefs.map(def => (
      <li className={styles.definition} key={def.definition}>
        {parseHtml(def.definition)}
      </li>
    ))

  useEffect(() => {
    const definitions = posBlocks?.find(pos => pos.pos === selectedPos)
    setSelectedDefinitions(definitions)
  }, [selectedPos])

  if (!hasManyPos) {
    return hasPos ? (
      <>
        <div className={styles['luna-pos']}>{parseHtml(posBlocks[0].pos)}</div>
        <ol className={styles.definitions}>{definitions(posBlocks[0])}</ol>
      </>
    ) : null
  } else {
    const items = posBlocks?.map(posBlock => {
      return { name: posBlock.pos, value: posBlock.pos }
    })
    const renderDefinitions = selectedDefinitions ? (
      <ol className={styles.definitions}>{definitions(selectedDefinitions)}</ol>
    ) : null

    return (
      <>
        <SelectPos
          data-type="select-pos"
          items={items}
          setSelected={setSelectedPos}
        />
        {renderDefinitions}
      </>
    )
  }
}

PartOfSpeech.propTypes = {
  entry: string.isRequired,
  posBlocks: array
}

PartOfSpeech.defaultProps = {
  posBlocks: []
}

export default withStyles(styles)(PartOfSpeech)
