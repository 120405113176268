import { Fragment } from 'react'
import isArrayWithLength from '~utils/isArrayWithLength'
import { toSlugCase } from '~utils/string'

/**
 * Mapping objects
 */
const CROSS_REFERENCE_GROUP_LABEL_MAPPING = {
  compare: 'Compare',
  contr: 'contrasted with',
  dist: 'distinguished from',
  etyCF: 'Cf.',
  etycf: 'cf.',
  etyseealso: 'see also',
  lcsee: 'see',
  none: '',
  opp: 'opposed to',
  see: 'See',
  seealso: 'See also',
  seeat: 'See at',
  seeentryat: 'See entry at',
  seeex: 'See example at',
  seeoriginat: 'see origin at',
  seeunder: 'See under'
}

const LABEL_ATTRIBUTE_MAPPING = {
  'Capital:_commercial,Capital': '(commercial)',
  'Capital:_economic,Capital': '(economic)',
  'Capital:_political,Capital': '(political)',
  Coastal_Georgia_South_Carolina_and_the_Bahamas:
    'Coastal Georgia, South Carolina, and the Bahamas',
  History_Historical: 'History/Historical',
  Medicine_Medical: 'Medicine/Medical',
  'Midland_Southern_and_Western_U.S.': 'Midland, Southern, and Western U.S.',
  'New_England_Midland_and_Southern_U.S.':
    'New England, Midland, and Southern U.S.',
  'New_England_South_Midland_and_Southern_U.S.':
    'New England, South Midland, and Southern U.S.',
  'New_England_Southern_and_South_Midland_U.S.':
    'New England, Southern, and South Midland U.S.',
  'North_Atlantic_South_Midland_and_Southern_U.S.':
    'North Atlantic, South Midland, and Southern U.S.',
  'North_Central_South_Midland_and_Southern_U.S.':
    'North Central, South Midland, and Southern U.S.',
  North_Dakota_Wisconsin_and_the_Canadian_Prairie_Provinces:
    'North Dakota, Wisconsin, and the Canadian Prairie Provinces',
  'Northern_Midland_and_Western_U.S.': 'Northern, Midland, and Western U.S.',
  'Northern_North_Midland_and_Western_U.S.':
    'Northern, North Midland, and Western U.S.',
  Pacific_Northwest_Northwest_Canada_and_Alaska:
    'Pacific Northwest, Northwest Canada, and Alaska',
  'South_Midland_Southern_and_Western_U.S.':
    'South Midland, Southern, and Western U.S.',
  'Southern_U.S._especially_South_Carolina_and_Bahamian_English':
    'Southern U.S., especially South Carolina, and Bahamian English',
  abbr: 'abbreviation',
  adj: 'adjective',
  adv: 'adverb',
  auxiliary_v: 'auxiliary verb',
  auxiliary_v_and_v: 'auxiliary verb and verb',
  chiefly_Winnipeg_Calgary_and_Vancouver:
    'chiefly Winnipeg, Calgary, and Vancouver',
  compar: 'comparative',
  conj: 'conjunction',
  gen: 'genitive',
  imperative_infinitive_and_participles_lacking:
    'imperative, infinitive, and participles lacking',
  imperative_infinitive_and_present_participle_lacking:
    'imperative, infinitive, and present participle lacking',
  in_British_use_except_in_the_navy: 'in British use, except in the navy',
  interj: 'interjection',
  n: 'noun',
  nom: 'nominative',
  obj: 'objective',
  past_and_past_part: 'past and past participle',
  past_part: 'past participle',
  past_pl: 'past plural',
  past_sing_1st_pers: 'past singular 1st person',
  past_subj_pl: 'past subjunctive plural',
  past_subj_sing_1st_pers: 'past subjunctive singular 1st person',
  pl: 'plural',
  pl_nom: 'plural nominative',
  plural_n: 'plural noun',
  plural_pron: 'plural pronoun',
  poss: 'possessive',
  prep: 'preposition',
  pres_part: 'present participle',
  pres_pl: 'present plural',
  pres_sing_1st_pers: 'present singular 1st person',
  pres_sing_3rd_pers: 'present singular 3rd person',
  pres_subj: 'present subjunctive',
  pron: 'pronoun',
  sing: 'singular',
  sing_nom: 'singular nominative',
  superl: 'superlative',
  v: 'verb',
  v_imperative: 'verb (imperative)',
  v_impers: 'verb (impersonal)',
  vi: 'verb (used without object)',
  vt: 'verb (used with object)',
  vt_vi: 'verb (used with or without object)'
}

export const PART_OF_SPEECH_MAPPING = {
  abbr: 'abbreviation',
  abbreviation: 'abbreviation for',
  'abbreviation:Internet_domain_name': 'the internet domain name for',
  'abbreviation:international_car_registration':
    'abbreviation:international_car_registration',
  'abbreviation:modifier': 'modifier abbreviation',
  'abbreviation:noun': 'abbreviation for',
  acronym: 'acronym for',
  'acronym:adjective': 'acronym for',
  'acronym:noun': 'acronym for',
  adj: 'adjective',
  adjective: 'adjective',
  adv: 'adverb',
  adverb: 'adverb',
  auxiliary_v: 'auxiliary verb',
  auxiliary_v_and_v: 'auxiliary verb and verb',
  combining_form: 'combining_form',
  'combining_form:in_adjective': 'combining form',
  'combining_form:in_adverb': 'combining form',
  'combining_form:in_noun:countable': 'combining form',
  'combining_form:in_noun_and_adjective': 'combining form',
  conj: 'conjunction',
  conjunction: 'conjunction',
  connective_vowel: 'connective vowel',
  contraction: 'contraction of',
  definite_article: 'definite article',
  determiner: 'determiner',
  idiom: 'idiom',
  indefinite_article: 'indefinite article',
  interj: 'interjection',
  interjection: 'interjection',
  modifier: 'adjective',
  n: 'noun',
  noun: 'noun',
  'noun:feminine': 'feminine noun',
  'noun:plural': 'plural noun',
  'numeral:Roman': 'the Roman numeral for',
  plural_n: 'plural noun',
  plural_pron: 'plural pronoun',
  prefix: 'prefix',
  'prefix:forming_verbs': 'prefix forming verbs and verbal derivatives',
  prep: 'preposition',
  preposition: 'preposition',
  pron: 'pronoun',
  pronoun: 'pronoun',
  sentence_connector: 'sentence connector',
  sentence_substitute: 'sentence substitute',
  suffix: 'suffix',
  'suffix:forming_adjectives': 'suffix forming adjectives',
  'suffix:forming_adverbs': 'suffix forming adverbs',
  'suffix:forming_nouns': 'suffix forming nouns',
  'suffix:forming_nouns:plural:proper': 'suffix forming plural proper nouns',
  'suffix:forming_ordinal_numbers': 'suffix forming ordinal numbers',
  'suffix:forming_verbs': 'suffix forming verbs',
  symbol: 'symbol for',
  'symbol:chemical': 'the chemical symbol for',
  unknown: '(no translation)',
  v: 'verb',
  v_imperative: 'verb (imperative)',
  v_impers: 'verb (impersonal)',
  verb: 'verb',
  verb_phrase: 'verb phrase',
  vi: 'verb (used without object)',
  vt: 'verb (used with object)',
  vt_vi: 'verb (used with or without object)'
}

const POSTTEXT_MAPPING = {
  also: ' also',
  alsolocally: ' also locally',
  cp: ')',
  often: ' often',
  usually: ' usually'
}

const PRETEXT_MAPPING = {
  NowOften: 'Now Often',
  NowUsually: 'Now Usually',
  alsocalled: 'Also called',
  alsoesp: 'also, especially',
  alsofor: 'also, for',
  andusuallyfor: 'and usually for',
  def: 'def.',
  defs: 'defs.',
  esp: 'especially',
  espcollectivelyfor: 'especially collectively for',
  espfor: 'especially for',
  espfordef: 'especially for def.',
  espfordefs: 'especially for defs.',
  fordef: 'for def.',
  fordefs: 'for defs.',
  indef: 'in def.',
  indefs: 'in defs.',
  opchiefly: '(chiefly',
  opin: '(in',
  or: 'or,',
  oresp: 'or, especially',
  orespfor: 'or, especially for',
  orespincourtfor: 'or, especially in court for',
  orfor: 'or, for',
  orforptformof: 'or, for past tense form of',
  oroccasfor: 'or, occasionally for',
  pron_or: 'or'
}

const PUNCTUATION_MAPPING = {
  colon: ':',
  comma: ',',
  none: '',
  semi: ';',
  semicolon: ';',
  stop: '.'
}

/**
 * The mapping might return an empty string, which is valid,
 * so check for existence of key rather than value.
 */
export const mapChunkValue = (mapping, key) => {
  return Object.hasOwn(mapping, key) ? mapping[key] : key
}

/**
 * Utility functions for converting semantic chunks from Lexigraph into HTML
 * https://dictionaryorg.atlassian.net/wiki/spaces/BE/pages/2218360875/Semantic+Chunks
 */
const processChunk = chunk => {
  if (!chunk) return

  let processedChunk = { ...chunk }

  const {
    children = [],
    posttext = '',
    pretext = '',
    punc = '',
    text = '',
    type = ''
  } = processedChunk

  if (children && type === 'cross_reference') {
    const processedChildren = children.map(childChunk => {
      const { text: childText, type: childType } = childChunk

      if (childText && childType === 'label_set') {
        return {
          ...processedChunk,
          text: mapChunkValue(CROSS_REFERENCE_GROUP_LABEL_MAPPING, childText)
        }
      }

      return childChunk
    })

    processedChunk = {
      ...processedChunk,
      children: processedChildren
    }
  }

  if (text && type === 'part_of_speech') {
    processedChunk = {
      ...processedChunk,
      text: mapChunkValue(PART_OF_SPEECH_MAPPING, text)
    }
  }

  if (text && type === 'label_set') {
    processedChunk = {
      ...processedChunk,
      text: mapChunkValue(LABEL_ATTRIBUTE_MAPPING, text)
    }
  }

  if (type === 'grammar') {
    processedChunk = {
      ...processedChunk,
      text: mapChunkValue(LABEL_ATTRIBUTE_MAPPING, text)
    }
  }

  if (type === 'part_of_speech') {
    processedChunk = {
      ...processedChunk,
      text: mapChunkValue(PART_OF_SPEECH_MAPPING, text)
    }
  }

  if (posttext) {
    processedChunk = {
      ...processedChunk,
      posttext: mapChunkValue(POSTTEXT_MAPPING, posttext)
    }
  }

  if (pretext) {
    processedChunk = {
      ...processedChunk,
      pretext: mapChunkValue(PRETEXT_MAPPING, pretext)
    }
  }

  if (punc) {
    processedChunk = {
      ...processedChunk,
      punc: mapChunkValue(PUNCTUATION_MAPPING, punc)
    }
  }

  return processedChunk
}

const convertChunkToHtml = (
  chunk,
  index,
  chunks,
  options = {
    isInflection: false,
    isOnlyChunk: false,
    isPronunciationSet: false,
    omitSpacing: false
  }
) => {
  if (!chunk) return

  const { isInflection, isOnlyChunk, isPronunciationSet, omitSpacing } = options

  const processedChunk = processChunk(chunk)

  const {
    children = [],
    ipaChunks = [],
    posttext = '',
    pretext = '',
    pronunciationGroupChunks = [],
    punc = '',
    slug = '',
    spellChunks = [],
    text = '',
    type = ''
  } = processedChunk

  const childrenChunks = children.map(convertChunkToHtml)
  const isLastChunk = index === chunks.length - 1
  const key = `chunk-${index + 1}`
  const pretextDisplay = pretext ? `${pretext} ` : ''
  const spaceOrNoSpace = !omitSpacing && !isLastChunk ? ' ' : ''
  const formattedText = `${text}${punc}${spaceOrNoSpace}`

  switch (type) {
    case 'index':
      return
    case 'alternate': {
      const isNextChunkAlt = chunks[index + 1]?.type === 'alternate'
      return (
        <Fragment key={key}>
          {formattedText}
          {isNextChunkAlt && '/ '}
        </Fragment>
      )
    }
    case 'alternate_name':
    case 'emphasis':
    case 'italic':
    case 'label_set':
    case 'langc':
    case 'langn':
    case 'lb_grammar_desc':
      return (
        <em key={key}>
          {formattedText}
          {childrenChunks}
        </em>
      )

    case 'cross_reference':
      return (
        <Fragment key={key}>
          <a href={`/browse/${slug}`}>
            {text}
            {childrenChunks}
          </a>
          {punc}
          {spaceOrNoSpace}
        </Fragment>
      )
    case 'bold':
    case 'form':
    case 'idi':
    case 'name':
    case 'phv':
    case 'related_form':
      return (
        <strong key={key}>
          {text}
          {childrenChunks}
          {punc}
          {spaceOrNoSpace}
        </strong>
      )
    case 'compareGrp':
    case 'compare_group':
      return <Fragment key={key}>Compare {childrenChunks}</Fragment>
    case 'grammar':
      return (
        <em key={key}>
          {!isOnlyChunk && isInflection ? `${text}:${' '}` : `${text}${' '}`}
        </em>
      )
    case 'inflection':
      return (
        <strong key={key}>
          {isOnlyChunk
            ? formattedText
            : `${formattedText.replace(/[^\w\s]/gi, '')}`}
        </strong>
      )
    case 'reference': {
      const label = children.find(({ type }) => type === 'label')
      const labelText =
        label?.text || (label.children || []).map(({ text }) => text).join(' ')
      const keyword = children.find(({ type }) => type === 'keyword')
      const keywordText =
        keyword?.text ||
        (keyword.children || []).map(({ text }) => text).join(' ')
      return (
        <Fragment key={key}>
          {labelText}{' '}
          <a href={`/browse/${toSlugCase(keywordText)}`}>{keywordText}</a>
        </Fragment>
      )
    }
    case 'paragraph':
      return (
        <p key={key}>
          {pretextDisplay}
          {text}
          {childrenChunks}
          {posttext}
          {punc}
          {spaceOrNoSpace}
        </p>
      )
    case 'var':
    case 'variant_form':
      return (
        <Fragment key={key}>
          {pretextDisplay}
          {text}
          {isArrayWithLength(children) && ' '}
          {childrenChunks}
          {posttext}
          {punc}
          {spaceOrNoSpace}
        </Fragment>
      )
    case 'pronunciation':
      return (
        <span
          dangerouslySetInnerHTML={{
            __html: `[${text}]${isLastChunk ? '' : ', '}`
          }}
          key={key}
        />
      )
    case 'pronunciation_group': {
      const parsedIpaChunks = (
        <Fragment key={key}>
          {ipaChunks.map((chunk, chunkIndex) =>
            convertChunkToHtml(chunk, chunkIndex, ipaChunks, {
              omitSpacing: true
            })
          )}
          {punc}
          {punc && ' '}
        </Fragment>
      )
      const parsedSpellChunks = (
        <Fragment key={key}>
          {spellChunks.map((chunk, chunkIndex) =>
            convertChunkToHtml(chunk, chunkIndex, spellChunks, {
              omitSpacing: true
            })
          )}
          {punc}
          {punc && ' '}
        </Fragment>
      )
      return { parsedIpaChunks, parsedSpellChunks }
    }
    case 'pronunciation_set': {
      if (isPronunciationSet) {
        const parsedPronunciationChunks =
          pronunciationGroupChunks.map(convertChunkToHtml)
        return parsedPronunciationChunks.reduce(
          (accumulator, chunk) => {
            const { parsedIpaChunks, parsedSpellChunks } = chunk
            if (parsedIpaChunks || parsedSpellChunks) {
              accumulator.parsedIpaChunks.push(parsedIpaChunks)
              accumulator.parsedSpellChunks.push(parsedSpellChunks)
            } else {
              accumulator.parsedIpaChunks.push(chunk)
              accumulator.parsedSpellChunks.push(chunk)
            }
            return accumulator
          },
          { parsedIpaChunks: [], parsedSpellChunks: [] }
        )
      }
      const spellChunkText = pronunciationGroupChunks
        .flatMap(({ spellChunks = [] }) => spellChunks.map(({ text }) => text))
        .join(', ')
      return (
        <Fragment key={key}>
          [{spellChunkText}]{punc}
          {spaceOrNoSpace}
        </Fragment>
      )
    }
    case 'superscript': {
      const superScriptText = text || children.map(({ text }) => text).join('')
      return <sup key={key}>{superScriptText}</sup>
    }
    // NOTE this is a quick fix for https://ixl-learning.atlassian.net/browse/DICTENG-27
    // xrefSupnt in this context was outputting a plain text note with no link to cross-reference
    case 'xrefSupnt': {
      return '';
    }
    // NOTE this is a quick fix for https://ixl-learning.atlassian.net/browse/DICTENG-53
    // 60 British entries contain the first text node "NEW.FOR.DICT.COM" which is an ingestion bug
    case 'text': {
      if (text === 'NEW.FOR.DICT.COM') {
        return '';
      }
      // fall through
    }
    default:
      return (
        <Fragment key={key}>
          {formattedText}
          {childrenChunks}
          {isArrayWithLength(children) && spaceOrNoSpace}
        </Fragment>
      )
  }
}

export const parseChunks = chunkText => {
  if (!chunkText) return null

  return JSON.parse(chunkText).map(convertChunkToHtml)
}

export const parseIdiomChunks = chunkText => {
  if (!chunkText) return null

  const chunks = JSON.parse(chunkText)
  const isListOfIdioms =
    chunks[0]?.text === 'In addition to the idioms beginning with'

  if (isListOfIdioms) {
    const listItems = chunks
      .filter(({ type }) => type === 'cross_reference')
      .map(({ text }) => <li key={text}>{text}</li>)

    return <ul>{listItems}</ul>
  }

  return chunks.map(convertChunkToHtml)
}

export const parseInflectionChunks = (chunkText, isOnlyChunk = false) => {
  if (!chunkText) return null

  const chunks = JSON.parse(chunkText).map((chunk, index, chunks) =>
    convertChunkToHtml(chunk, index, chunks, {
      isInflection: true,
      isOnlyChunk
    })
  )

  return <i>{chunks}</i>
}

export const parsePronunciationChunks = chunkText => {
  if (!chunkText) return null

  const chunks = [JSON.parse(chunkText)].map((chunk, index, chunks) =>
    convertChunkToHtml(chunk, index, chunks, {
      isPronunciationSet: true
    })
  )

  return chunks[0]
}
