import React from 'react'
import { array, string } from 'prop-types'
import withStyles from 'isomorphic-style-loader/withStyles'
import { SynonymComparisonCard } from '~composites'
import styles from './SynonymComparisonCardList.module.scss'

export const SynonymComparisonCardList = ({ matches, rootDisplayForm }) => {
  const comparisonMatchCard = matches.map(
    ({ definitions, headword, sharedSynonyms, sharedAntonyms }) => (
      <li className={styles['card-item']} key={headword.slug}>
        <SynonymComparisonCard
          definitions={definitions}
          matchHeadword={headword}
          rootDisplayForm={rootDisplayForm}
          sharedAntonyms={sharedAntonyms}
          sharedSynonyms={sharedSynonyms}
        />
      </li>
    )
  )

  return (
    <ul className={styles.root} data-type="comparison-card-list">
      {comparisonMatchCard}
    </ul>
  )
}

SynonymComparisonCardList.propTypes = {
  matches: array.isRequired,
  rootDisplayForm: string.isRequired
}

export default withStyles(styles)(SynonymComparisonCardList)
