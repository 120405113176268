import React, { useState } from 'react'
import { bool, string } from 'prop-types'
import cx from 'classnames'
import withStyles from 'isomorphic-style-loader/withStyles'
import { PROPTYPE_CHILDREN } from '~client/constants'
import { Button } from '~elements'
import styles from './ExpandableContent.module.scss'

const ExpandableContent = ({
  buttonClosedText,
  buttonOpenText,
  children,
  className,
  isOpenByDefault,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(isOpenByDefault)
  const classNames = cx(styles.root, className, {
    'expandable-is-open': isOpen
  })
  const buttonText = isOpen ? buttonOpenText : buttonClosedText
  const onClick = () => setIsOpen(!isOpen)

  return (
    <div className={classNames} data-type="expandable-content" {...props}>
      <section>{children}</section>
      <Button onClick={onClick}>{buttonText}</Button>
    </div>
  )
}

ExpandableContent.propTypes = {
  buttonClosedText: string,
  buttonOpenText: string,
  children: PROPTYPE_CHILDREN.isRequired,
  className: string,
  isOpenByDefault: bool
}

ExpandableContent.defaultProps = {
  buttonClosedText: 'See more',
  buttonOpenText: 'See less',
  className: null,
  isOpenByDefault: false
}

export default withStyles(styles)(ExpandableContent)
