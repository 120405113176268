// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cGKEjSwhu2meLe57XCW3,._zjXuAd5iDkajlGFe9vq{background:rgba(255,255,255,.1);border-radius:20px;height:268px;margin-bottom:24px;padding:24px 24px 32px;width:267px}.cGKEjSwhu2meLe57XCW3 h4,._zjXuAd5iDkajlGFe9vq h4{margin:0 0 12px}.cGKEjSwhu2meLe57XCW3 ul,._zjXuAd5iDkajlGFe9vq ul{line-height:24px;margin:0 0 12px;padding:0 16px}._zjXuAd5iDkajlGFe9vq{color:var(--teal)}._zjXuAd5iDkajlGFe9vq ul{color:#fff;display:grid;grid-auto-flow:column;grid-template-rows:repeat(5, 1fr);line-height:24px;list-style:none;padding:0}._zjXuAd5iDkajlGFe9vq ul li:not(:nth-child(5n)){border-bottom:1px solid rgba(255,255,255,.1);padding:5px 0}._zjXuAd5iDkajlGFe9vq ul li:nth-child(5){padding:5px 0}`, "",{"version":3,"sources":["webpack://./src/client/components/composites/WordPuzzleWordsFound/WordPuzzleWordsFound.module.scss"],"names":[],"mappings":"AAAA,4CAEE,+BAAA,CACA,kBAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,WAAA,CAEA,kDACE,eAAA,CAGF,kDACE,gBAAA,CACA,eAAA,CACA,cAAA,CAIJ,sBACE,iBAAA,CAEA,yBACE,UAAA,CACA,YAAA,CACA,qBAAA,CACA,iCAAA,CACA,gBAAA,CACA,eAAA,CACA,SAAA,CAGE,gDACE,4CAAA,CACA,aAAA,CAGF,yCACE,aAAA","sourcesContent":[".game-instructions,\n.words-found {\n  background: rgb(255 255 255 / 10%);\n  border-radius: 20px;\n  height: 268px;\n  margin-bottom: 24px;\n  padding: 24px 24px 32px;\n  width: 267px;\n\n  h4 {\n    margin: 0 0 12px;\n  }\n\n  ul {\n    line-height: 24px;\n    margin: 0 0 12px;\n    padding: 0 16px;\n  }\n}\n\n.words-found {\n  color: var(--teal);\n\n  ul {\n    color: #fff;\n    display: grid;\n    grid-auto-flow: column;\n    grid-template-rows: repeat(5, 1fr);\n    line-height: 24px;\n    list-style: none;\n    padding: 0;\n\n    li {\n      &:not(:nth-child(5n)) {\n        border-bottom: 1px solid rgb(255 255 255 / 10%);\n        padding: 5px 0;\n      }\n\n      &:nth-child(5) {\n        padding: 5px 0;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"game-instructions": `cGKEjSwhu2meLe57XCW3`,
	"words-found": `_zjXuAd5iDkajlGFe9vq`
};
module.exports = ___CSS_LOADER_EXPORT___;
