import { useEffect, useRef } from 'react'
import { string } from 'prop-types'
import { ANYCLIP_SCRIPT_URL } from '~client/constants'

const AnyClipVideo = ({ className, pubName, widgetName }) => {
  const ref = useRef(null)

  useEffect(() => {
    const div = ref.current
    const script = document.createElement('script')
    script.src = ANYCLIP_SCRIPT_URL
    script.async = true
    script.setAttribute('pubname', pubName)
    script.setAttribute('widgetname', widgetName)

    if (div) {
      let child = div.firstChild

      while (child) {
        div.removeChild(child)
        child = div.firstChild
      }

      div.appendChild(script)
    }
  }, [])

  return <div className={className} ref={ref} />
}

AnyClipVideo.propTypes = {
  className: string,
  pubName: string.isRequired,
  widgetName: string.isRequired
}

AnyClipVideo.defaultProps = {
  className: null
}

export default AnyClipVideo
