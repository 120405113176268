import React from 'react'
import { array, bool, string } from 'prop-types'
import cx from 'classnames'
import withStyles from 'isomorphic-style-loader/withStyles'
import { DefaultLink } from '~client/components/elements'
import useThesaurusUrl from '~hooks/useThesaurusUrl'
import isArrayWithLength from '~utils/isArrayWithLength'
import { parseChunks } from '~utils/semanticChunkParsers'
import styles from './DefinitionContentListRedesign.module.scss'

const DefinitionContentListRedesign = ({
  definitions,
  hideNumbers,
  parentKey
}) => {
  const { browseUrl: thesaurusBrowseUrl } = useThesaurusUrl()

  if (!isArrayWithLength(definitions)) return null

  const classNames = cx(styles.root, {
    [styles['show-numbers']]: !hideNumbers
  })

  const getDefinitionContent = ({ idiom, label, sense }) => {
    let definitionContent = ''

    if (idiom) {
      definitionContent += `<strong>${idiom}</strong> `
    }

    if (label) {
      definitionContent += `<em>${label}.</em> `
    }

    if (sense) {
      definitionContent += `<em>${sense}.</em> `
    }

    return definitionContent
  }

  const getExamplesContent = examples => {
    if (!isArrayWithLength(examples)) return null

    return (
      <div className={styles['definition-block']}>
        {examples.map(example => {
          /**
           * British examples are an array of objects;
           * all other examples are an array of strings
           */
          const exampleText = example?.text || example

          return (
            exampleText && (
              <p key={exampleText}>
                <i>{exampleText}</i>
              </p>
            )
          )
        })}
      </div>
    )
  }

  const synonymsOrAntonymsContent = (edges, type) => {
    if (!isArrayWithLength(edges)) return null

    const key = type.toLowerCase()

    return (
      <p className={styles['synonym-antonym']}>
        <strong>{`${type}: `}</strong>
        {edges.map(({ displayForm, slug }, index) => (
          <React.Fragment key={`${key}-${slug}`}>
            <DefaultLink
              data-analytics="aa-track"
              data-linklocation="header"
              data-linkmodule={`view-${key}-for:${slug}`}
              data-linkname={`view-${key}:${slug}`}
              data-linktype="cta"
              data-linkurl={`${thesaurusBrowseUrl}/${slug}`}
              href={`${thesaurusBrowseUrl}/${slug}`}
            >
              {displayForm}
            </DefaultLink>
            {index < edges.length - 1 && ', '}
          </React.Fragment>
        ))}
      </p>
    )
  }

  return (
    <ol className={classNames} data-type="definition-content-list">
      {definitions.map((definition, index) => {
        const {
          antonymsConnection,
          definitionSetChunks,
          descriptionChunks,
          examples = [],
          phraseChunks,
          subdefinitions = [],
          synonymsConnection
        } = definition

        const chunks = definitionSetChunks || descriptionChunks
        const definitionContent = getDefinitionContent(definition)
        const definitionKey = `${parentKey}-definition-${index + 1}`

        return (
          <li key={definitionKey}>
            <span className={styles.definitions}>
              {definitionContent && (
                <div
                  className={styles['definition-block']}
                  dangerouslySetInnerHTML={{
                    __html: definitionContent
                  }}
                />
              )}
              {(chunks || phraseChunks) && (
                <div className={styles['definition-block']}>
                  <div>
                    {parseChunks(phraseChunks)} {parseChunks(chunks)}
                  </div>
                </div>
              )}
              {getExamplesContent(examples)}
              {isArrayWithLength(subdefinitions) && (
                <ol className={styles.subdefinitions}>
                  {subdefinitions.map(
                    (
                      { definitionSetChunks, examples: subdefinitionExamples },
                      index
                    ) => (
                      <li key={`${definitionKey}-subdefinition-${index + 1}`}>
                        <div>
                          {parseChunks(definitionSetChunks)}
                          {getExamplesContent(subdefinitionExamples)}
                        </div>
                      </li>
                    )
                  )}
                </ol>
              )}
              {synonymsOrAntonymsContent(synonymsConnection?.edges, 'Synonyms')}
              {synonymsOrAntonymsContent(antonymsConnection?.edges, 'Antonyms')}
            </span>
          </li>
        )
      })}
    </ol>
  )
}

DefinitionContentListRedesign.propTypes = {
  definitions: array,
  hideNumbers: bool,
  parentKey: string.isRequired
}

DefinitionContentListRedesign.defaultProps = {
  definitions: [],
  hideNumbers: false
}

export default withStyles(styles)(DefinitionContentListRedesign)
