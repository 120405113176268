import { createSlice } from '@reduxjs/toolkit'
import {
  fetchCompareWords,
  fetchExampleSentences,
  fetchLunaBrowse,
  fetchLunaWordComparisonList,
  fetchResultsData,
  fetchSpellSuggestions,
  fetchWordsNearby,
  fetchWordsRelated
} from './actions'
import initialState from './state.json'

export const BROWSE_RESULTS_PER_PAGE = 300
export const WORD_COMPARISON_PER_PAGE = 60

export const lunaSlice = createSlice({
  extraReducers(builder) {
    builder
      .addCase(fetchCompareWords.pending, state => {
        state.compareWordsStatus = 'loading'
      })
      .addCase(fetchCompareWords.fulfilled, (state, action) => {
        state.compareWordsStatus = 'succeeded'
        state.compareWords = action.payload?.data
      })
      .addCase(fetchCompareWords.rejected, (state, action) => {
        state.compareWordsStatus = 'failed'
        state.error = action.error.message
      })
      .addCase(fetchExampleSentences.pending, state => {
        state.exampleSentences.status = 'loading'
      })
      .addCase(fetchExampleSentences.fulfilled, (state, action) => {
        state.exampleSentences = {
          data: action.payload.data,
          status: 'succeeded'
        }
      })
      .addCase(fetchExampleSentences.rejected, (state, action) => {
        state.exampleSentences = {
          error: action.error.message,
          status: 'failed'
        }
      })
      .addCase(fetchLunaBrowse.pending, state => {
        state.dcomBrowseStatus = 'loading'
      })
      .addCase(fetchLunaBrowse.fulfilled, (state, action) => {
        state.dcomBrowseStatus = 'succeeded'
        state.dcomBrowseList = action.payload?.data
        state.dcomTotalResults = action?.payload?.meta?.totalResults
      })
      .addCase(fetchLunaBrowse.rejected, (state, action) => {
        state.dcomBrowseStatus = 'failed'
        state.dcomBrowseError = action.error.message
      })
      .addCase(fetchLunaWordComparisonList.pending, state => {
        state.comparisonStatus = 'loading'
      })
      .addCase(fetchLunaWordComparisonList.fulfilled, (state, action) => {
        state.comparisonStatus = 'succeeded'
        state.comparisonList = action.payload?.data
        state.comparisonListTotalResults = action.payload.count
      })
      .addCase(fetchLunaWordComparisonList.rejected, (state, action) => {
        state.comparisonStatus = 'failed'
        state.comparisonError = action.error.message
      })
      .addCase(fetchResultsData.pending, state => {
        state.resultsData.status = 'loading'
      })
      .addCase(fetchResultsData.fulfilled, (state, action) => {
        state.resultsData = {
          data: action.payload.data,
          status: 'succeeded'
        }
      })
      .addCase(fetchResultsData.rejected, (state, action) => {
        state.resultsData = {
          error: action.error.message,
          status: 'failed'
        }
      })
      .addCase(fetchSpellSuggestions.pending, state => {
        state.spellSuggestions.status = 'loading'
      })
      .addCase(fetchSpellSuggestions.fulfilled, (state, action) => {
        state.spellSuggestions = {
          data: action.payload.data,
          status: 'succeeded'
        }
      })
      .addCase(fetchSpellSuggestions.rejected, (state, action) => {
        state.spellSuggestions = {
          error: action.error.message,
          status: 'failed'
        }
      })
      .addCase(fetchWordsNearby.pending, state => {
        state.wordsNearby.status = 'loading'
      })
      .addCase(fetchWordsNearby.fulfilled, (state, action) => {
        state.wordsNearby = {
          data: action.payload.data,
          status: 'succeeded'
        }
      })
      .addCase(fetchWordsNearby.rejected, (state, action) => {
        state.wordsNearby = {
          error: action.error.message,
          status: 'failed'
        }
      })
      .addCase(fetchWordsRelated.pending, state => {
        state.wordsRelated.status = 'loading'
      })
      .addCase(fetchWordsRelated.fulfilled, (state, action) => {
        state.wordsRelated = {
          data: action.payload.data,
          status: 'succeeded'
        }
      })
      .addCase(fetchWordsRelated.rejected, (state, action) => {
        state.wordsRelated = {
          error: action.error.message,
          status: 'failed'
        }
      })
  },
  initialState,
  name: 'luna',
  reducers: {
    resetLunaSlice: () => initialState
  }
})

export const actions = lunaSlice.actions

export default lunaSlice.reducer
