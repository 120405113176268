// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IthgrQEC5RhnU2Oz8JG1{justify-content:center}.leSwbG1cLVQPUiMckrSR{background-color:var(--thesaurusorange);border:rgba(0,0,0,0);border-radius:100px;color:#fff;cursor:pointer;display:inline-block;font-size:16px;font-weight:bold;line-height:1;padding:16px 40px;transition:all .25s ease-in-out 0s;width:inherit}.GzwrhLafrrOas8gQy9c3{background-color:var(--mildgray);border-color:rgba(0,0,0,0);color:var(--lightgraytext);cursor:not-allowed}`, "",{"version":3,"sources":["webpack://./src/client/components/composites/CompareMoreModalFooter/CompareMoreModalFooter.module.scss"],"names":[],"mappings":"AAAA,sBACE,sBAAA,CAGF,sBACE,uCAAA,CACA,oBAAA,CACA,mBAAA,CACA,UAAA,CACA,cAAA,CACA,oBAAA,CACA,cAAA,CACA,gBAAA,CACA,aAAA,CACA,iBAAA,CACA,kCAAA,CACA,aAAA,CAGF,sBACE,gCAAA,CACA,0BAAA,CACA,0BAAA,CACA,kBAAA","sourcesContent":[".root {\n  justify-content: center;\n}\n\n.compare-button {\n  background-color: var(--thesaurusorange);\n  border: transparent;\n  border-radius: 100px;\n  color: #fff;\n  cursor: pointer;\n  display: inline-block;\n  font-size: 16px;\n  font-weight: bold;\n  line-height: 1;\n  padding: 16px 40px;\n  transition: all 0.25s ease-in-out 0s;\n  width: inherit;\n}\n\n.disabled {\n  background-color: var(--mildgray);\n  border-color: transparent;\n  color: var(--lightgraytext);\n  cursor: not-allowed;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `IthgrQEC5RhnU2Oz8JG1`,
	"compare-button": `leSwbG1cLVQPUiMckrSR`,
	"disabled": `GzwrhLafrrOas8gQy9c3`
};
module.exports = ___CSS_LOADER_EXPORT___;
