import React from 'react'
import { array, number, object, string } from 'prop-types'
import withStyles from 'isomorphic-style-loader/withStyles'
import { useNavigate } from 'react-router-dom'
import { QuizSummary, RetakeButton, SpellingQuizSummary } from '~composites'
import { Button } from '~elements'
import useDictionaryUrl from '~hooks/useDictionaryUrl'
import styles from './QuizResults.module.scss'

const resultDescription = {
  average: '🤔 We think there’s a 100% in your future. Try again!',
  good: '🤓 Nice! You’re a bona fide wordie!',
  poor: '😬 Try again, you’ve got this!'
}

const QuizResults = ({
  answers,
  quiz,
  quizType,
  score,
  selectedAnswers,
  totalNumberOfQuestions
}) => {
  const { name, questions } = quiz
  const { learnQuizzesUrl } = useDictionaryUrl()
  const navigate = useNavigate()
  const isLearnMultipleChoiceQuiz = quizType === 'learn-multiple-choice'
  const isLearnSpellingQuiz = quizType === 'learn-spelling'
  const isMultipleChoiceQuiz = quizType === 'multiple-choice'
  const hasMultipleChoices = isLearnMultipleChoiceQuiz || isMultipleChoiceQuiz
  const wordListUrl = window.location.href

  const handleRetake = () => {
    navigate(0)
  }

  const handleTakeAnother = () => {
    window.location.href = learnQuizzesUrl
  }

  const handleRedirectToWordList = () => {
    if (isLearnSpellingQuiz) {
      window.location.href = wordListUrl.replace('/spelling', '')
    } else {
      window.location.href = wordListUrl.replace('/quiz', '')
    }
  }

  const grade = score / totalNumberOfQuestions
  let gradeDescription = ''

  if (grade >= 0.8) {
    gradeDescription = resultDescription.good
  } else if (grade >= 0.3) {
    gradeDescription = resultDescription.average
  } else {
    gradeDescription = resultDescription.poor
  }

  const quizResultScore = `${score} / ${totalNumberOfQuestions}`

  return (
    <div className={styles.root} data-type={`${quizType}-quiz-results`}>
      {hasMultipleChoices && <header>{name}</header>}
      {isLearnSpellingQuiz && (
        <p className={styles['results-header']}>Your Results</p>
      )}
      <div className={styles.results}>
        <div className={styles.score}>{quizResultScore}</div>
        <div className={styles.grade}>
          {gradeDescription}
          <RetakeButton onClick={handleRetake} />
        </div>
      </div>

      {hasMultipleChoices && (
        <QuizSummary
          answers={answers}
          questions={questions}
          selectedAnswers={selectedAnswers}
        />
      )}

      {isLearnSpellingQuiz && (
        <SpellingQuizSummary
          answers={answers}
          selectedAnswers={selectedAnswers}
        />
      )}

      {isMultipleChoiceQuiz && (
        <Button className={styles['take-quiz']} onClick={handleTakeAnother}>
          Take another quiz
        </Button>
      )}

      {(isLearnSpellingQuiz || isLearnMultipleChoiceQuiz) && (
        <>
          <div>
            <Button className={styles['take-quiz']} onClick={handleTakeAnother}>
              Try Our Other Quizzes
            </Button>
          </div>
          <div>
            <Button
              className={styles['word-list-button']}
              onClick={handleRedirectToWordList}
            >
              Go To Word List
            </Button>
          </div>
        </>
      )}
    </div>
  )
}

QuizResults.propTypes = {
  answers: object.isRequired,
  quiz: object,
  quizType: string.isRequired,
  score: string.isRequired,
  selectedAnswers: array.isRequired,
  totalNumberOfQuestions: number.isRequired
}

QuizResults.defaultProps = {
  quiz: {}
}

export default withStyles(styles)(QuizResults)
