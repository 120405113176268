// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BshWfxcQoKlzf97OGUPA header{margin-bottom:20px;min-height:initial}`, "",{"version":3,"sources":["webpack://./src/client/components/composites/WordPuzzleModal/WordPuzzleModal.module.scss"],"names":[],"mappings":"AAAA,6BACE,kBAAA,CACA,kBAAA","sourcesContent":[".root header {\n  margin-bottom: 20px;\n  min-height: initial;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `BshWfxcQoKlzf97OGUPA`
};
module.exports = ___CSS_LOADER_EXPORT___;
