// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dQuywplr3LeTTBAntMxw ul{list-style:none;margin:16px 0 0;padding:0}div.lFpWtTg5UBIWgdVgP0xt>button::before{position:relative}div.lFpWtTg5UBIWgdVgP0xt>section{height:0;padding:0}`, "",{"version":3,"sources":["webpack://./src/client/components/composites/ExampleSentences/ExampleSentences.module.scss"],"names":[],"mappings":"AACE,yBACE,eAAA,CACA,eAAA,CACA,SAAA,CAKF,wCACE,iBAAA,CAGF,iCACE,QAAA,CACA,SAAA","sourcesContent":[".root {\n  ul {\n    list-style: none;\n    margin: 16px 0 0;\n    padding: 0;\n  }\n}\n\ndiv.see-more-example-usage {\n  & > button::before {\n    position: relative;\n  }\n\n  & > section {\n    height: 0;\n    padding: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `dQuywplr3LeTTBAntMxw`,
	"see-more-example-usage": `lFpWtTg5UBIWgdVgP0xt`
};
module.exports = ___CSS_LOADER_EXPORT___;
