import React from 'react'
import { array, object } from 'prop-types'
import cx from 'classnames'
import withStyles from 'isomorphic-style-loader/withStyles'
import { Accordion, CheckIcon, CloseIcon } from '~elements'
import styles from './SpellingQuizSummary.module.scss'

const SpellingQuizSummary = ({ answers, selectedAnswers }) => {
  const questionList = answers.map((answer, index) => {
    const selectedAnswer = selectedAnswers[index]
    const isCorrect = answer.toLowerCase() === selectedAnswer.toLowerCase()

    const classNames = cx(styles['answer-border'], {
      [styles.correct]: isCorrect,
      [styles.wrong]: !isCorrect
    })

    const AnswerIcon = isCorrect ? CheckIcon : CloseIcon

    return (
      <div className={styles['summary-container']} key={answer}>
        <div className={styles['summary-details']}>
          <div className={styles.header}>{answer}</div>
          <div className={classNames}>
            <AnswerIcon />
          </div>
        </div>
        <div className={styles.answer}>
          <p>
            <strong>Your Answer: </strong>
            {selectedAnswer}
          </p>
        </div>
      </div>
    )
  })

  return (
    <div className={styles.root} data-type="spelling-quiz-summary">
      <Accordion defaultIsOpen={false} headline="Show Summary">
        {questionList}
      </Accordion>
    </div>
  )
}

SpellingQuizSummary.propTypes = {
  answers: object.isRequired,
  selectedAnswers: array.isRequired
}

export default withStyles(styles)(SpellingQuizSummary)
