// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BYHIXdj8Bf9CJAeu3iRG{align-items:center;border:2px solid #c20034;-webkit-box-align:center;color:#1a1a1a;display:flex;font-size:14px;margin-bottom:16px;padding:16px}.BYHIXdj8Bf9CJAeu3iRG::before{color:var(--error);content:var(--icon-warning);display:inline-block;font-family:var(--font-icon);font-size:24px;height:24px;margin-right:4px;transition:transform .3s ease 0s;vertical-align:middle;width:24px}`, "",{"version":3,"sources":["webpack://./src/client/components/composites/ErrorCard/ErrorCard.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,wBAAA,CACA,wBAAA,CACA,aAAA,CACA,YAAA,CACA,cAAA,CACA,kBAAA,CACA,YAAA,CAGF,8BACE,kBAAA,CACA,2BAAA,CACA,oBAAA,CACA,4BAAA,CACA,cAAA,CACA,WAAA,CACA,gBAAA,CACA,gCAAA,CACA,qBAAA,CACA,UAAA","sourcesContent":[".root {\n  align-items: center;\n  border: 2px solid rgb(194 0 52);\n  -webkit-box-align: center;\n  color: rgb(26 26 26);\n  display: flex;\n  font-size: 14px;\n  margin-bottom: 16px;\n  padding: 16px;\n}\n\n.root::before {\n  color: var(--error);\n  content: var(--icon-warning);\n  display: inline-block;\n  font-family: var(--font-icon);\n  font-size: 24px;\n  height: 24px;\n  margin-right: 4px;\n  transition: transform 0.3s ease 0s;\n  vertical-align: middle;\n  width: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `BYHIXdj8Bf9CJAeu3iRG`
};
module.exports = ___CSS_LOADER_EXPORT___;
