import React from 'react'
import withStyles from 'isomorphic-style-loader/withStyles'
import { AnyClipPubNames, AnyClipWidgetNames } from '~client/constants'
import { AnyClipVideo } from '~composites'
import styles from './DictionaryResultsAnyClipVideo.module.scss'

const DictionaryResultsAnyClipVideo = () => (
  <div className={styles.root}>
    <hr />
    <AnyClipVideo
      pubName={AnyClipPubNames.DICTIONARY}
      widgetName={AnyClipWidgetNames.DICTIONARY.RESULTS}
    />
    <hr />
  </div>
)

export default withStyles(styles)(DictionaryResultsAnyClipVideo)
