const EMPTY_OBJECT = {}
const EMPTY_ARRAY = []

export const selectWordOfTheDay = state =>
  state.wordpress?.wordoftheday || EMPTY_OBJECT
export const selectSynonymOfTheDay = state =>
  state.wordpress?.synonymoftheday || EMPTY_OBJECT
export const selectQuiz = state => state.wordpress?.quiz?.data?.quiz
export const selectFeaturedQuiz = state =>
  state.wordpress?.featuredQuiz?.data?.quiz || EMPTY_OBJECT
export const selectQuizAnswers = state =>
  state.wordpress?.quizAnswers?.data?.quiz?.answers
export const selectQuizList = state =>
  state.wordpress?.quizList?.items || EMPTY_ARRAY
export const selectEditorialArticle = state =>
  state.wordpress?.article?.data?.[0] || EMPTY_OBJECT
export const selectEditorialNotes = state =>
  state.wordpress?.editorialNotes?.data?.editorsNotes || EMPTY_ARRAY
export const selectCompareWordsContent = state =>
  state.wordpress?.compareWordsContent?.data || EMPTY_OBJECT
export const selectWordPuzzle = state =>
  state.wordpress?.wordPuzzle?.data || EMPTY_ARRAY
export const selectWordPuzzleStatus = state =>
  state.wordpress?.wordPuzzle?.status || EMPTY_OBJECT
