import React from 'react'
import { object, string } from 'prop-types'
import withStyles from 'isomorphic-style-loader/withStyles'
import { A, ResultsCard } from '~elements'
import styles from './WordsNearbyCard.module.scss'

const BASE_URL = '/browse/'

const WordsNearbyCard = ({ content, word }) => {
  const before = content.before
  const after = content.after

  const wordsNearbyContent = nearbyContent => {
    const wordList = nearbyContent.map((words, index) => {
      const { entry, slug } = words
      return (
        <li key={entry}>
          <A data-linkid="lno2pu" href={`${BASE_URL}${slug}`}>
            {entry}
          </A>
        </li>
      )
    })
    return wordList
  }

  const beforeContent = wordsNearbyContent(before)
  const afterContent = wordsNearbyContent(after)

  return (
    <ResultsCard className={styles.root} data-type="words-nearby-card">
      <header>
        <h2>Words Nearby {word}</h2>
      </header>
      <ul>
        {beforeContent}
        <li className={styles['entry-word']}>{word}</li>
        {afterContent}
      </ul>
    </ResultsCard>
  )
}

WordsNearbyCard.propTypes = {
  content: object.isRequired,
  word: string.isRequired
}

export default withStyles(styles)(WordsNearbyCard)
