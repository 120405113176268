import React from 'react'
import withStyles from 'isomorphic-style-loader/withStyles'
import { A } from '~elements'
import useGamesUrl from '~hooks/useGamesUrl'
import styles from './GamesContentError.module.scss'

const GamesContentError = () => {
  const { allGamesUrl } = useGamesUrl()

  return (
    <p className={styles.cta} data-type="games-content-error">
      This is not a Game on dictionary.com.{' '}
      <A href={allGamesUrl}>See our entire games collection</A>.
    </p>
  )
}

export default withStyles(styles)(GamesContentError)
