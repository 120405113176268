import React, { useState } from 'react'
import { object } from 'prop-types'
import { useSelector } from 'react-redux'
import { CompareMoreModalContent, CompareMoreModalFooter } from '~composites'
import { Modal } from '~elements'
import {
  selectSynonymAntonymDefinitions,
  selectThesaurusEntries
} from '~store/lexigraph/selectors'
import { selectLocationQuery } from '~store/location/selectors'
import isArrayWithLength from '~utils/isArrayWithLength'

const CompareMoreModal = ({ modalState }) => {
  const {
    ordinal: urlOrdinal,
    pos: urlPos,
    root: urlRoot
  } = useSelector(selectLocationQuery) || {}
  const thesaurusEntry = useSelector(selectThesaurusEntries)
  const synonymAntonymDefinitions = useSelector(selectSynonymAntonymDefinitions)
  const [selectedSynonymsList, setSelectedSynonymsList] = useState([])

  if (
    !isArrayWithLength(synonymAntonymDefinitions) ||
    !isArrayWithLength(thesaurusEntry)
  ) {
    return null
  }

  const parsedUrlOrdinal = parseInt(urlOrdinal || '1', 10)

  const partOfSpeechData = synonymAntonymDefinitions.find(
    ({ ordinal, partOfSpeech }) =>
      partOfSpeech === urlPos && ordinal === parsedUrlOrdinal
  )

  if (!partOfSpeechData) return null

  const { shortDef: definition, synonyms } = partOfSpeechData

  const { displayForm: rootDisplayForm } = thesaurusEntry[0]?.headword || {}

  const selectWord = word => {
    setSelectedSynonymsList(prev => {
      if (prev.includes(word)) {
        return prev.filter(synonym => synonym !== word)
      }

      if (prev.length === 3) return prev

      return [...prev, word]
    })
  }

  return (
    <Modal
      data-type="compare-more-modal"
      heading="Select up to 3 synonyms to compare"
      modalState={modalState}
    >
      <CompareMoreModalContent
        definition={definition}
        pos={urlPos}
        rootDisplayForm={rootDisplayForm}
        selectWord={selectWord}
        selectedSynonymsList={selectedSynonymsList}
        synonyms={synonyms}
      />
      <CompareMoreModalFooter
        pos={urlPos}
        selectedSynonymsList={selectedSynonymsList}
        urlOrdinal={urlOrdinal}
        urlRoot={urlRoot}
      />
    </Modal>
  )
}

CompareMoreModal.propTypes = {
  modalState: object.isRequired
}

export default CompareMoreModal
