// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.T3yAovkeOEFH6KD5NOMe{display:inline-block}.T3yAovkeOEFH6KD5NOMe button{background-color:rgba(0,0,0,0);border:0;color:var(--dictionaryblue);cursor:pointer;font-size:14px;font-weight:bold;height:14px;line-height:14px;margin-right:8px;outline:none;padding:0;text-transform:uppercase}.Hc_uvFWGZrw7dAprV7k9{color:var(--lightgray);margin-right:8px}@media(min-width: 768px){.Hc_uvFWGZrw7dAprV7k9{font-size:20px}}`, "",{"version":3,"sources":["webpack://./src/client/components/composites/PronunciationToggle/PronunciationToggle.module.scss"],"names":[],"mappings":"AAAA,sBACE,oBAAA,CAEA,6BACE,8BAAA,CACA,QAAA,CACA,2BAAA,CACA,cAAA,CACA,cAAA,CACA,gBAAA,CACA,WAAA,CACA,gBAAA,CACA,gBAAA,CACA,YAAA,CACA,SAAA,CACA,wBAAA,CAIJ,sBACE,sBAAA,CACA,gBAAA,CAGF,yBACE,sBACE,cAAA,CAAA","sourcesContent":[".root {\n  display: inline-block;\n\n  button {\n    background-color: transparent;\n    border: 0;\n    color: var(--dictionaryblue);\n    cursor: pointer;\n    font-size: 14px;\n    font-weight: bold;\n    height: 14px;\n    line-height: 14px;\n    margin-right: 8px;\n    outline: none;\n    padding: 0;\n    text-transform: uppercase;\n  }\n}\n\n.content {\n  color: var(--lightgray);\n  margin-right: 8px;\n}\n\n@media (min-width: 768px) {\n  .content {\n    font-size: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `T3yAovkeOEFH6KD5NOMe`,
	"content": `Hc_uvFWGZrw7dAprV7k9`
};
module.exports = ___CSS_LOADER_EXPORT___;
