// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.G2Qvde_MTcLCBtRJchlu{display:flex;flex-direction:column;font-size:16px}.G2Qvde_MTcLCBtRJchlu h2{color:var(--darkgraytext);font-size:16px;font-weight:bold;margin:0 0 14px;text-transform:uppercase}.X7Xu9r_znq6Jd3Z4ybtF{display:flex;flex-direction:column;gap:12px;margin-bottom:16px}a.M5elFHBKgP4m_3CYwJWM{background-color:#fff;border:2px solid var(--dictionaryblue);color:var(--dictionaryblue);font-weight:bold;height:40px;line-height:1;width:fit-content}.fw35Jq99hwdzq3d2V34L{color:var(--darkgraytext);font-weight:400;line-height:1.5}@media(min-width: 768px){.X7Xu9r_znq6Jd3Z4ybtF{flex-direction:row}}`, "",{"version":3,"sources":["webpack://./src/client/components/composites/CompareMeaningsCard/CompareMeaningsCard.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,cAAA,CAEA,yBACE,yBAAA,CACA,cAAA,CACA,gBAAA,CACA,eAAA,CACA,wBAAA,CAIJ,sBACE,YAAA,CACA,qBAAA,CACA,QAAA,CACA,kBAAA,CAGF,uBACE,qBAAA,CACA,sCAAA,CACA,2BAAA,CACA,gBAAA,CACA,WAAA,CACA,aAAA,CACA,iBAAA,CAGF,sBACE,yBAAA,CACA,eAAA,CACA,eAAA,CAGF,yBACE,sBACE,kBAAA,CAAA","sourcesContent":[".root {\n  display: flex;\n  flex-direction: column;\n  font-size: 16px;\n\n  h2 {\n    color: var(--darkgraytext);\n    font-size: 16px;\n    font-weight: bold;\n    margin: 0 0 14px;\n    text-transform: uppercase;\n  }\n}\n\n.compare-buttons {\n  display: flex;\n  flex-direction: column;\n  gap: 12px;\n  margin-bottom: 16px;\n}\n\na.pill {\n  background-color: #fff;\n  border: 2px solid var(--dictionaryblue);\n  color: var(--dictionaryblue);\n  font-weight: bold;\n  height: 40px;\n  line-height: 1;\n  width: fit-content;\n}\n\n.text {\n  color: var(--darkgraytext);\n  font-weight: 400;\n  line-height: 1.5;\n}\n\n@media (min-width: 768px) {\n  .compare-buttons {\n    flex-direction: row;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `G2Qvde_MTcLCBtRJchlu`,
	"compare-buttons": `X7Xu9r_znq6Jd3Z4ybtF`,
	"pill": `M5elFHBKgP4m_3CYwJWM`,
	"text": `fw35Jq99hwdzq3d2V34L`
};
module.exports = ___CSS_LOADER_EXPORT___;
