import React from 'react'
import { func } from 'prop-types'
import cx from 'classnames'
import withStyles from 'isomorphic-style-loader/withStyles'
import { PROPTYPE_CHILDREN } from '~client/constants'
import { Button, Icon } from '~elements'
import compareSynonymIcon from '~public/images/icons/compare-synonym-icon.svg'
import styles from './CompareMoreButton.module.scss'

const CompareMoreButton = ({ children, onClick, ...props }) => {
  const classNames = cx(styles.root, styles.tcom)

  return (
    <Button className={classNames} onClick={onClick} {...props}>
      <Icon className={styles.icon} src={compareSynonymIcon} />
      {children}
    </Button>
  )
}

CompareMoreButton.propTypes = {
  children: PROPTYPE_CHILDREN.isRequired,
  onClick: func.isRequired
}

export default withStyles(styles)(CompareMoreButton)
