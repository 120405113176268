// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.a1F0YOig9EliiqP248E8{align-items:center;background-color:rgba(0,0,0,0);cursor:pointer;display:flex;font-size:14px;font-weight:bolder;margin-bottom:16px;text-decoration:none}.a1F0YOig9EliiqP248E8:link,.a1F0YOig9EliiqP248E8:visited,.a1F0YOig9EliiqP248E8:hover,.a1F0YOig9EliiqP248E8:active{color:var(--darkgraytext);text-decoration:none}.a1F0YOig9EliiqP248E8::before{content:var(--icon-leftArrow);font-family:var(--font-icon);font-size:24px;margin:0}.a1F0YOig9EliiqP248E8 em{margin-left:4px}`, "",{"version":3,"sources":["webpack://./src/client/components/composites/BackToWordLink/BackToWordLink.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,8BAAA,CACA,cAAA,CACA,YAAA,CACA,cAAA,CACA,kBAAA,CACA,kBAAA,CACA,oBAAA,CAEA,kHAIE,yBAAA,CACA,oBAAA,CAGF,8BACE,6BAAA,CACA,4BAAA,CACA,cAAA,CACA,QAAA,CAGF,yBACE,eAAA","sourcesContent":[".root {\n  align-items: center;\n  background-color: transparent;\n  cursor: pointer;\n  display: flex;\n  font-size: 14px;\n  font-weight: bolder;\n  margin-bottom: 16px;\n  text-decoration: none;\n\n  &:link,\n  &:visited,\n  &:hover,\n  &:active {\n    color: var(--darkgraytext);\n    text-decoration: none;\n  }\n\n  &::before {\n    content: var(--icon-leftArrow);\n    font-family: var(--font-icon);\n    font-size: 24px;\n    margin: 0;\n  }\n\n  em {\n    margin-left: 4px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `a1F0YOig9EliiqP248E8`
};
module.exports = ___CSS_LOADER_EXPORT___;
