/*
  The script, carambolaScript, below was provided directly to us from Carambola (one of our ad partners).
  It manipulates the <img> and <script> tags in the React component in order to retrieve and render an
  advertisement in its place.

  The script has been modified to work for both DCOM and TCOM carambola ads.
*/
export const carambolaAdScript = (
  i,
  d,
  s,
  o,
  m,
  r,
  c,
  l,
  w,
  q,
  y,
  h,
  g,
  site
) => {
  const e = d.getElementById(r)
  if (e === null) {
    const t = d.createElement(o)
    t.src = g + Math.floor(Math.random() * 1000)
    t.id = r
    t.setAttribute(m, s)
    t.async = 1
    const n = d.getElementsByTagName(o)[0]
    n.parentNode.insertBefore(t, n)
    const dt = new Date().getTime()
    try {
      i[l][w + y](h, `${i[l][q + y](h)}&${dt}`)
    } catch (er) {
      i[h] = dt
    }
  } else if (typeof i[c] !== 'undefined') {
    i[c] += 1
  } else {
    i[c] = 1
  }
}
