// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SZjJlj7dd7R6mDTODwIT{align-items:center;display:inline-flex;flex-direction:column;justify-content:center;width:100%}.ZBaOeEicp1FaXQoUFZBy{display:block;font-family:Arial,Helvetica,sans-serif;font-size:12px;line-height:1.4;margin:12px 0;text-align:center}`, "",{"version":3,"sources":["webpack://./src/client/components/composites/Ad/Ad.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,mBAAA,CACA,qBAAA,CACA,sBAAA,CACA,UAAA,CAGF,sBACE,aAAA,CACA,sCAAA,CACA,cAAA,CACA,eAAA,CACA,aAAA,CACA,iBAAA","sourcesContent":[".root {\n  align-items: center;\n  display: inline-flex;\n  flex-direction: column;\n  justify-content: center;\n  width: 100%;\n}\n\n.header {\n  display: block;\n  font-family: Arial, Helvetica, sans-serif;\n  font-size: 12px;\n  line-height: 1.4;\n  margin: 12px 0;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `SZjJlj7dd7R6mDTODwIT`,
	"header": `ZBaOeEicp1FaXQoUFZBy`
};
module.exports = ___CSS_LOADER_EXPORT___;
